import React from 'react'
import '../inputs.scss'
const { forwardRef, useRef, useImperativeHandle } = React;

const Textarea = forwardRef(({
    id,
    type,
    className,
    autoComplete,
    name,
    placeholder,
    placeholderTop,
    value,
    onChange,
    errorMessage,
    fieldDescription,
    fieldIcon,
    placeholderStyle,
    clear,
    hiddenMaxLength,
    handleClearInput,
    hint,
    maxLength,
    dataCy,
    disabled,
    size,
    onClick,
    onKeyDown,
    onKeyUp,
    style,
    ...rest }, ref) => {
    const inputRef = useRef();

    const setFocus = () => {
        inputRef.current.focus();
    };

    // The component instance will be extended
    // with whatever you return from the callback passed
    // as the second argument
    useImperativeHandle(ref, () => ({
        setFocus,
        inputRef
    }));
    return (
        <div className={`input-form-group ${className ? className : ''} ${errorMessage && errorMessage.error ? `is-invalid ${errorMessage.color ? errorMessage.color : ''}` : ''} ${disabled ? 'input-disabled' : ''}`}>
            <div className={`input-form ${fieldIcon ? `has-icon ${fieldIcon.position}` : ''}`}>
                <textarea
                    {...rest}
                    rows={4}
                    style={style ? style : { height: '70px' }}
                    data-cy={dataCy ? dataCy : null}
                    className={size ? size : ''}
                    maxLength={maxLength ? maxLength : null}
                    ref={inputRef}
                    type={type ? type : 'text'}
                    id={id}
                    autoComplete={autoComplete === 'off' ? "new-password" : ''}
                    name={name}
                    placeholder=" "
                    value={value}
                    onChange={e => onChange ? onChange(e) : {}}
                    onKeyUp={e => onKeyUp ? onKeyUp(e) : {}}
                    onKeyDown={e => onKeyDown ? onKeyDown(e) : {}}
                    onClick={e => onClick ? onClick(e) : {}}
                    disabled={disabled} />
                {fieldIcon && <div className={`input-field-icon ${fieldIcon.position}`}>{fieldIcon.icon}</div>}
                <label className={`${placeholderStyle === 'hide' ? 'hide' : 'animated '} ${placeholderTop ? 'placeholder-top' : ''} truncate`} data-cy={id} htmlFor={id}>{placeholder}</label>
                {hint && <span className="input-form-hint">
                    <div className="input-hint-icon">i</div>
                    <div className="input-hint-text">{hint}</div>
                </span>}
            </div>
            {/*(maxLength && maxLength > 0) || errorMessage || fieldDescription &&*/
                <div className="fields-legends">
                    <div className="fields-legends-descriptions">
                        {errorMessage && errorMessage.error ? <small className={errorMessage.color}>{errorMessage.error}</small> : fieldDescription ? <small>{fieldDescription}</small> : null}
                    </div>
                    {!hiddenMaxLength && maxLength && maxLength > 0 &&
                        <div className="fields-legends-counter">
                            {value.length}/{maxLength}
                        </div>
                    }
                </div>
            }
            {clear && value && value.trim().length > 0 && <div className={`input-form-clear ${hint ? 'has-hint' : ''}`} onClick={handleClearInput}>+</div>}
        </div>
    )
})

Textarea.displayName = 'Textarea'

export default Textarea
