import { client, axiosInterceptor } from '../../utils/axios-utils'
axiosInterceptor()
const api =  {
    accountsLogar: (email) => {
        return new Promise((resolve, reject) => {
            return  client()
            .post(`${process.env.REACT_APP_FISCOLFRONT_API_URL}/${process.env.REACT_APP_FISCOLFRONT_API_URI}/Account/Logar`, email)
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    },
    buscarAcoesSistemaUsuario: (tokenImpersonate) => {
        return new Promise((resolve, reject) => {
            return  client(tokenImpersonate)
            .get(`${process.env.REACT_APP_FISCOLFRONT_API_URL}/${process.env.REACT_APP_FISCOLFRONT_API_URI}/Account/BuscarAcoesSistemaUsuario`)
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    },
    buscarUsuariosImpersonate: (tokenImpersonate, params) => {
        return new Promise((resolve, reject) => {
            return  client(tokenImpersonate)
            .get(`${process.env.REACT_APP_FISCOLFRONT_API_URL}/${process.env.REACT_APP_FISCOLFRONT_API_URI}/Account/BuscarUsuariosImpersonate`, {params})
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    },
    gerarTokenUsuarioImpersonate: (tokenImpersonate, params) => {
        return new Promise((resolve, reject) => {
            return  client(tokenImpersonate)
            .post(`${process.env.REACT_APP_FISCOLFRONT_API_URL}/${process.env.REACT_APP_FISCOLFRONT_API_URI}/Account/GerarTokenUsuarioImpersonate`, {...params})
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    },
    solicitarRecuperacaoSenha: (params) => {
        return new Promise((resolve, reject) => {
            return  client()
            .post(`${process.env.REACT_APP_FISCOLFRONT_API_URL}/${process.env.REACT_APP_FISCOLFRONT_API_URI}/Account/SolicitarRecuperacaoSenha`, params)
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    },
    validarTokenRecuperarSenha: (params) => {
        return new Promise((resolve, reject) => {
            return  client()
            .post(`${process.env.REACT_APP_FISCOLFRONT_API_URL}/${process.env.REACT_APP_FISCOLFRONT_API_URI}/Account/ValidarTokenRecuperarSenha`, params)
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    },
    recuperarSenha: (params) => {
        return new Promise((resolve, reject) => {
            return  client()
            .post(`${process.env.REACT_APP_FISCOLFRONT_API_URL}/${process.env.REACT_APP_FISCOLFRONT_API_URI}/Account/RecuperarSenha`, params)
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    }
}

export default  api;    