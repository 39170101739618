import React from 'react'
import './label-list.scss'

const LabelList = ({classname, list, width}) => {
    return (
        <div className={`label-list ${classname}`} style={{ maxWidth: `${width}px` }}>
            {`${list?.map(item => ` ${item}`)}`}
        </div>
    )
}
export default LabelList