import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import "../../../assets/scss/components/master-menu.scss";
import { history } from "../../../history";
import { useSelector } from "react-redux";
import packageJson from "../../../../package.json";
import moment from "moment";

import { EmptyState, Card, Button, ModalWindow } from "../../../components";
import MenuItemsHooks from "../../MenuItemsHooks";

const items = MenuItemsHooks();

const checkIfHasPermission = (menu, acoesSistema, user) => {
  const menuPermission = acoesSistema[menu.attributes.path];
  if (menu.attributes.path === "branches") {
    return user.tokenUsuarioImpersonate;
  }
  if (menu.attributes.path === "users") {
    return user.tokenUsuarioImpersonate;
  }
  if (
    menu.attributes.certificateRequired &&
    !user.certificadoTreinamento &&
    !user.tokenUsuarioImpersonate &&
    !user.usuarioInlog
  ) {
    return false;
  }
  return !!menuPermission;
};
function MasterMenu({ menuIsShowing, show, toggleShowMenu, searchTerm }) {
  const user =
    useSelector((state) => state.auth.user) ||
    JSON.parse(localStorage.getItem("userInfo"));
  const [showMenu, setShowMenu] = useState(false);
  const [showMenuItems, setShowMenuItems] = useState(false);
  const [alert, setAlert] = useState({
    show: false,
    message: "",
  });

  const [search, setSearch] = useState("");

  const intl = useIntl();

  useEffect(() => {
    if (show) {
      setShowMenu(show);
      setTimeout(() => {
        setShowMenuItems(show);
        menuIsShowing(show);
      }, 70);
      setSearch("");
    } else {
      setShowMenuItems(show);
      setTimeout(() => {
        setShowMenu(show);
        menuIsShowing(show);
      }, 70);
    }
  }, [show]);

  useEffect(() => {
    if (searchTerm && searchTerm.trim().length > 0) {
      console.log(searchTerm);
      setSearch(searchTerm);
    } else {
      console.log("nop");
      setSearch("");
    }
    //
  }, [searchTerm]);

  function handleMessage(path) {
    switch (path) {
      case "shapes":
        return intl.formatMessage({
          id: "Por favor solicite à Inlog o treinamento para utilizar esta funcionalidade ou a prestação de serviço para importação das suas formas!",
        });
      case "task-scheduling-import":
        return intl.formatMessage({
          id: "Por favor solicite à Inlog o treinamento para utilizar esta funcionalidade ou a prestação de serviço para importação do seu agendamento de tarefas!",
        });
      case "demands-import":
        return intl.formatMessage({
          id: "Por favor solicite à Inlog o treinamento para utilizar esta funcionalidade ou a prestação de serviço para importação de demandas!",
        });
    }
  }

  function handleActiveCard(item) {
    if (!item.attributes.path) return;
    if (
      item.attributes.certificateRequired &&
      !user.certificadoTreinamento &&
      !user.tokenUsuarioImpersonate &&
      !user.usuarioInlog
    ) {
      setAlert({
        show: true,
        message: handleMessage(item.attributes.path),
      });
      return;
    }
    const location = {
      pathname: `/${item.attributes.path}`,
    };
    history.push(location);
    toggleShowMenu();
  }

  const filtered = items
    .map((element) => {
      return {
        ...element,
        items: element?.items
          .filter((item) =>
            checkIfHasPermission(item, user?.acoesSistema, user)
          )
          .filter((data) => {
            if (search == "") return data;
            else if (
              data.attributes.title
                .toLowerCase()
                .includes(search.toLowerCase()) ||
              data.attributes.description
                .toLowerCase()
                .includes(search.toLowerCase())
            ) {
              return data;
            }
          }),
      };
    })
    .filter((f) => f.items.length > 0);

  return (
    <div className={`app-master-menu ${showMenu ? "master-menu-is-open" : ""}`}>
      <div
        className={`app-master-menu-search-items ${
          showMenuItems ? "menu-items-visible" : ""
        }`}
      >
        {filtered && filtered.length > 0 ? (
          filtered.map((group) => {
            return (
              <div
                key={`menu-group-${group.group}`}
                className="master-menu-group"
              >
                <div className="container">
                  <h2>{group.group}</h2>
                  <Card
                    onClick={handleActiveCard}
                    className="cursor-pointer"
                    items={group.items}
                  />
                </div>
              </div>
            );
          })
        ) : (
          <div className="flex-grow-1">
            <EmptyState
              loading={false}
              title={intl.formatMessage({
                id: "Não encontramos itens para a sua busca",
              })}
              description={intl.formatMessage({
                id: "Tente efetuar uma busca utilizando um termo diferente.",
              })}
              type="find"
            />
          </div>
        )}
        <small className="release-date">
          Build Date:{" "}
          {moment(packageJson.buildDate).format("DD/MM/YYYY HH:mm:ss")} -
          Version: {packageJson.version}
        </small>
      </div>
      <ModalWindow
        show={alert.show}
        title={intl.formatMessage({ id: "Acesso Restrito" })}
        position="center"
        toggle={() => setAlert({ show: false, message: "" })}
        onPressEnter={() => {}}
        color="warning"
        footer={
          <div className="d-flex justify-content-between">
            <div></div>
            <Button.Warning
              dark
              onClick={() => setAlert({ show: false, message: "" })}
            >
              {intl.formatMessage({ id: "Entendi" })}
            </Button.Warning>
          </div>
        }
      >
        <p>{alert.message}</p>
      </ModalWindow>
    </div>
  );
}

export default MasterMenu;
