import React from "react"
import {
  Alert,
  Form,
} from "reactstrap"
import "../../../assets/scss/pages/authentication.scss"
import usuario from '../../../services/Usuario'
import { FormattedMessage, injectIntl } from "react-intl"
import { connect } from "react-redux"
import { PasswordStrength } from '../../../components'

import {
  Button,
  TextInput,
  ModalWindow
} from '../../../components'

//import { errorHandle } from '../../../utils/error-handle'

class ChangePassword extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      value: '',
      alertMessage: null,
      recoverySuccess: false,
      request: false,
      oldpass: '',
      newpass: '',
      confirm: '',
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }


  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
      alertMessage: null
    });
    if (name === 'newpass') {
      this.strength(value)
    }
  }

  strength = (value) => {

    const sequential = /(abc|bcd|cde|def|efg|fgh|ghi|hij|ijk|jkl|klm|lmn|mno|nop|opq|pqr|qrs|rst|stu|tuv|uvw|vwx|wxy|xyz|012|123|234|345|456|567|678|789)+/ig
    let points = 100
    if (value.search(/[A-Z]/) < 0) {
      console.log("Your password needs an upper case letter")
      this.setState({ uppercase: false })
      points = points - 20
    } else {
      this.setState({ uppercase: true })
    }
    if (value.search(/[0-9]/) < 0) {
      console.log("Your password needs a number")
      this.setState({ number: false })
      points = points - 20
    } else {
      this.setState({ number: true })
    }
    if (value.length < 8) {
      this.setState({ characters: false })
      points = points - 20
      console.log("Your password needs a minimum of 8 characters")
    } else {
      this.setState({ characters: true })
    }
    if (value.search(/[a-z]/) < 0) {
      console.log("Your password needs a lower case letter")
      points = points - 20
    }
    if (value.search(sequential) > 0) {
      console.log("have sequential")
      points = points - 20
    }
    this.setState({
      strength: points,
      color: points <= 20 ? 'bg-danger' : points <= 60 ? 'bg-warning' : points > 60 ? 'bg-success' : 'bg-default'
    })
  }

  saveChange = () => {
    console.log(JSON.parse(localStorage.getItem('userInfo')))
  }

  changePassword = () => {
    const { uppercase, number, characters, newpass, confirm, oldpass } = this.state
    const { intl } = this.props
    let erros = ''
    if (!uppercase) {
      erros = erros + `<li>${intl.formatMessage({ id: "Pelo menos 1 letra maiúscula" })}</li>`
    }
    if (!number) {
      erros = erros + `<li>${intl.formatMessage({ id: "Pelo menos 1 número" })}</li>`
    }
    if (!characters) {
      erros = erros + `<li>${intl.formatMessage({ id: "Pelo menos 8 caracteres" })}</li>`
    }
    if (newpass !== confirm) {
      erros = erros + `<li>${intl.formatMessage({ id: "A senha e a confirmação estão diferentes" })}</li>`
    }
    if (!newpass) {
      erros = erros + `<li>${intl.formatMessage({ id: "Informe uma nova senha" })}</li>`
    }

    if (!oldpass) {
      erros = erros + `<li>${intl.formatMessage({ id: "Informe a sua senha atual" })}</li>`
    }
    if (erros) {
      this.setState({
        alertColor: 'danger',
        alertMessage: erros,
        request: false
      })
    } else {
      this.setState({ request: true })
      usuario.alterarSenha({ email: this.props.user.user.email, senhaAtual: oldpass, novaSenha: newpass })
        .then(res => {
          this.setState({ recoverySuccess: res.data, request: false })
          // toast.add({ message: res.message, color: 'success', autoClose: 7000 })
          this.props.togglePasswordModal()
        })
        .catch(() => {
          // toast.add({ message: err.response.data.message, color: 'danger', autoClose: 7000 })
        })
    }
  }

  passwordIsValid = (isValid) => {
    console.log(`Password is valid: ${JSON.stringify(isValid)}`)
  }

  render() {
    const { open, intl } = this.props
    return (
      <ModalWindow
        show={open}
        title={<FormattedMessage id="Altere a sua senha" />}
        position="center"
        toggle={() => this.props.togglePasswordModal()}
        footer={<div className="d-flex justify-content-between">
          <Button.Cancel dark onClick={() => this.props.togglePasswordModal()} />
          <Button.Save loading={this.state.request} dark onClick={e => {
            e.preventDefault()
            !this.state.request && this.changePassword()
          }} />
        </div>}>
        {this.state.alertMessage &&
          <Alert color={this.state.alertColor} className="mb-2">
            <small dangerouslySetInnerHTML={{
              __html: this.state.alertMessage
            }} />

          </Alert>
        }
        <div className="autentication-modal">
          <Form>
            <TextInput
              type="password"
              id="oldpass"
              //className={`mb-1 ${username.trim().length === 0 && errors.find(f => f.field === 'login') ? 'is-invalid' : ''}`}
              autoComplete="off"
              name="oldpass"
              className="py-50"
              placeholder={intl.formatMessage({ id: 'Senha atual' })}
              value={this.state.oldpass}
              maxLength={16}
              hiddenMaxLength
              onChange={this.handleInputChange} />
            <TextInput
              type="password"
              id="newpass"
              //className={`mb-1 ${username.trim().length === 0 && errors.find(f => f.field === 'login') ? 'is-invalid' : ''}`}
              autoComplete="off"
              name="newpass"
              className="py-50"
              placeholder={intl.formatMessage({ id: 'Nova senha' })}
              value={this.state.newpass}
              maxLength={16}
              hiddenMaxLength
              onChange={this.handleInputChange} />
            <PasswordStrength value={this.state.newpass} getIsValid={this.passwordIsValid} />
            <TextInput
              type="password"
              id="confirm"
              //className={`mb-1 ${username.trim().length === 0 && errors.find(f => f.field === 'login') ? 'is-invalid' : ''}`}
              autoComplete="off"
              name="confirm"
              placeholder={intl.formatMessage({ id: 'Confirme a nova senha' })}
              value={this.state.confirm}
              maxLength={16}
              hiddenMaxLength
              onChange={this.handleInputChange} />

          </Form>
        </div>
      </ModalWindow>

    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth
  }
}

export default injectIntl(connect(mapStateToProps, {})(ChangePassword))
