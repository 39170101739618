import moment from "moment";
import { connect } from "react-redux";

import release from "../../configs/releaseDate.json";

const checkPermissions = (user, route) => {
  const checkFeature = release.find((f) => f.name === route);
  if (
    checkFeature &&
    (moment(checkFeature.releaseDate).diff(moment(), "minutes") > 0 ||
      !checkFeature.releaseDate) &&
    user.email.toLowerCase().startsWith("master@")
  ) {
    return false;
  } else {
    return true;
  }
};

const ReleaseDate = ({ route, user, children, renderNoAccess }) => {
  const permitted = checkPermissions(user, route);

  if (permitted) {
    return children;
  }
  return renderNoAccess();
};

ReleaseDate.defaultProps = {
  allowedPermissions: [],
  permissions: [],
  renderNoAccess: () => null,
};

export default connect((state) => ({
  userPermissions: state.auth.userData,
}))(ReleaseDate);
