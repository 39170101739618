import React, { useEffect, useState } from "react";
import "./password-strength.scss";

function PasswordStrength({ value, getIsValid }) {
  const [color, setColor] = useState(null);
  const [characters, setCharacters] = useState(null);
  const [uppercase, setUppercase] = useState(null);
  const [lowercase, setLowercase] = useState(null);
  const [number, setNumber] = useState(null);
  const [strength, setStrength] = useState(null);
  const [specialCharacters, setSpecialCharacters] = useState(null);

  useEffect(() => {
    getIsValid(strengthString(value));
  }, [value]);

  function strengthString() {
    let points = 100;
    if (value.search(/[A-Z]/) < 0) {
      // console.log("Your password needs an upper case letter")
      setUppercase(false);
      points = points - 100 / 5;
    } else {
      setUppercase(true);
    }
    if (value.search(/[0-9]/) < 0) {
      // console.log("Your password needs a number")
      setNumber(false);
      points = points - 100 / 5;
    } else {
      setNumber(true);
    }
    if (value.length < 8) {
      setCharacters(false);
      points = points - 100 / 5;
      // console.log("Your password needs a minimum of 8 characters")
    } else {
      setCharacters(true);
    }
    if (value.search(/[a-z]/) < 0) {
      // console.log("Your password needs a lower case letter")
      setLowercase(false);
      points = points - 100 / 5;
    } else {
      setLowercase(true);
    }

    if (!["#", "@", "$", "&"].some((v) => value.includes(v))) {
      setSpecialCharacters(false);
      // console.log("no contains special characters")
      points = points - 100 / 5;
    } else {
      setSpecialCharacters(true);
      // console.log("contains special characters")
    }

    if (value) setStrength(points);
    setColor(
      points <= 20
        ? "bg-danger"
        : points <= 60
        ? "bg-warning"
        : points > 60
        ? "bg-success"
        : "bg-default"
    );
    return {
      strength: points,
      color:
        points <= 20
          ? "danger"
          : points <= 60
          ? "warning"
          : points > 60
          ? "success"
          : "default",
      isValid: points === 100,
    };
  }

  return (
    <div className="password-strength">
      <div className="header">
        <h5>Força da senha</h5>
        <div className="strength">
          <span
            className={`point ${
              value && value.length > 0 && strength >= 20 && color
            }`}
          />
          <span
            className={`point ${
              value && value.length > 0 && strength >= 40 && color
            }`}
          />
          <span
            className={`point ${
              value && value.length > 0 && strength >= 60 && color
            }`}
          />
          <span
            className={`point ${
              value && value.length > 0 && strength >= 80 && color
            }`}
          />
          <span
            className={`point ${
              value && value.length > 0 && strength > 80 && color
            }`}
          />
        </div>
      </div>
      <ul>
        <li
          className={
            !value
              ? ""
              : value && value.length > 0 && !characters
              ? "danger"
              : "success"
          }
        >
          Pelo menos 8 caractere
        </li>
        <li
          className={
            !value
              ? ""
              : value && value.length > 0 && !uppercase
              ? "danger"
              : "success"
          }
        >
          Pelo menos 1 letra maiúscula
        </li>
        <li
          className={
            !value
              ? ""
              : value && value.length > 0 && !number
              ? "danger"
              : "success"
          }
        >
          Pelo menos 1 número
        </li>
        <li
          className={
            !value
              ? ""
              : value && value.length > 0 && !specialCharacters
              ? "danger"
              : "success"
          }
        >
          Pelo menos 1 caracter especial ({"# @ $ &"})
        </li>
        <li
          className={
            !value
              ? ""
              : value && value.length > 0 && !lowercase
              ? "danger"
              : "success"
          }
        >
          Pelo menos 1 letra minúscula
        </li>
      </ul>
    </div>
  );
}
export default PasswordStrength;
