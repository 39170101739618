import React, { useCallback } from "react";
import Checkbox from "../../checkbox/Checkbox";

const Head = (props) => {
  const {
    administrator,
    visibility,
    rows,
    selectable,
    hideAllSelect,
    select,
    selectableDisabled,
    handleRowAction,
    columns,
    loading,
    changeColumnOrdering,
    ordering,
    customHeader,
    intl,
    features,
    changeArchived,
    archived,
    setToggleArchive,
    toggleArchive,
    ToggleArchived,
    FilterIcon,
    data,
  } = props;

  const handleAllSelect = useCallback(() => {
    handleRowAction("all-select", rows);
  }, [handleRowAction, rows]);

  const renderSelectCheckbox = useCallback(
    () => (
      <th width="1">
        {!hideAllSelect && (
          <Checkbox
            id="checkbox-main"
            name="checkbox-main"
            checked={
              data?.rows?.filter((f) => f.selected).length === data.rows.length
            }
            value={select.length === data.rows.length}
            disabled={selectableDisabled}
            onChange={handleAllSelect}
          />
        )}
      </th>
    ),
    [hideAllSelect, data, select, selectableDisabled, handleAllSelect]
  );

  const renderColumn = useCallback(
    (column) => {
      const handleColumnOrdering = () => {
        if (column.ordering && !loading) {
          changeColumnOrdering({
            column: column.ordering,
            order:
              ordering &&
              ordering.column === column.ordering &&
              ordering.order === "desc"
                ? "asc"
                : "desc",
          });
        }
      };

      return (
        <th
          width={column.width}
          key={column.name}
          className={column.ordering ? "ordering" : ""}
          style={
            column.width
              ? {
                  maxWidth: column.width,
                  minWidth: column.width,
                }
              : {}
          }
          onClick={handleColumnOrdering}
        >
          <div className="table-head">
            {customHeader &&
            customHeader.map((item) => item.name).includes(column.name) ? (
              customHeader.find((item) => item.name === column.name).label
            ) : (
              <div
                key={`table_header_`}
                className={`truncate-table w-100 ${
                  column.ordering ? "mr-1" : ""
                }`}
              >
                {column.label && intl.formatMessage({ id: column.label })}
              </div>
            )}
            {column.ordering && (
              <div
                className={`table-ordering ${
                  ordering && ordering.column === column.ordering
                    ? "active"
                    : ""
                }`}
              >
                {ordering &&
                ordering.column === column.ordering &&
                ordering &&
                ordering.order === "asc" ? (
                  <span>&#9660;</span>
                ) : (
                  <span>&#9650;</span>
                )}
              </div>
            )}
          </div>
        </th>
      );
    },
    [loading, changeColumnOrdering, ordering, customHeader, intl]
  );

  const renderAdminColumn = useCallback(
    () => (
      <th width="10" onClick={changeArchived}>
        {!features ||
          (features && features.toogleArchive && (
            <div className="table-archive">
              <div
                className={`table-filter-icon ${
                  archived ? "archived-active" : ""
                }`}
                onClick={() => setToggleArchive(!toggleArchive)}
              >
                <FilterIcon width={14} />
              </div>
              <div
                className={`table-archive-toggle ${
                  toggleArchive ? "show" : ""
                }`}
              >
                <ToggleArchived archived={archived} />
              </div>
            </div>
          ))}
      </th>
    ),
    [features, archived, setToggleArchive, toggleArchive, FilterIcon]
  );

  return (
    <thead
      className={`${administrator ? "administrator" : ""} ${
        visibility ? "visible" : ""
      }`}
    >
      <tr>
        {!!rows.length && selectable && renderSelectCheckbox()}

        {columns.filter((f) => !f.hidden).map((column) => renderColumn(column))}

        {administrator && renderAdminColumn()}
      </tr>
    </thead>
  );
};

export default Head;
