import {
  USER_SIGNED_OUT,
  STORE_USER_ERROR,
  USER_EXPIRED,
  STORE_USER,
  STORE_USER_INFO,
  LOADING_USER,
  EXPIRING_TOKEN,
  EXPIRED_TOKEN,
  USER_STATUS,
  STORE_BRANCH,
} from "../../actions/authentication/types";

const initialState = {
  user: null,
  isLoadingUser: false,
  userData: null,
  selectedBranch: null,
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case STORE_USER:
      return {
        ...state,
        isLoadingUser: false,
        user: action.payload,
      };
    case STORE_USER_INFO:
      return {
        ...state,
        userData: action.payload,
      };
    case LOADING_USER:
      return {
        ...state,
        isLoadingUser: true,
      };
    case USER_EXPIRED:
    case EXPIRING_TOKEN:
    case EXPIRED_TOKEN:
    case USER_SIGNED_OUT:
    case STORE_USER_ERROR:
      return {
        ...state,
        user: null,
        isLoadingUser: false,
      };
    case USER_STATUS: {
      const userStatus = { ...state.userData, pause: action.pause };
      return { ...state, userData: userStatus };
    }
    case STORE_BRANCH:
      return { ...state, selectedBranch: action.payload };
    default:
      return state;
  }
}
