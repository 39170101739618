import React, { useEffect, useState } from 'react'
import "./priority.scss"

function Priority({ options, title, onClick, className, id, errorMessage }) {
    const [color, setColor] = useState('')

    useEffect(() => {
        const selected = options?.find(item => item.selected)

        switch (selected?.value) {
            case 0:
                setColor('primary')
                return
            case 1:
                setColor('warning')
                return
            case 2:
                setColor('danger')
                return
            default:
                setColor('')
        }
    }, [options])

    return (
        <div id={id} className={`ui-priority ui-priority-${color} ${className ? className : ''} ${errorMessage && errorMessage.error ? errorMessage.color : ''}`}>
            <div className="ui-priority-label">
                {title}
            </div>
            <ul>
                {options && options.map(option =>
                    <li
                        key={`${id}-${option.key}`}
                        className={`${option.selected ? 'selected' : ''} ${option.disabled ? 'disabled' : ''}`}
                        onClick={() => option.disabled ? {} : onClick && onClick(option)}>
                        <span>{option.label}</span>
                    </li>
                )}
            </ul>
            <div className="fields-legends">
                <div className="fields-legends-descriptions">
                    {errorMessage && errorMessage.error && <small className={errorMessage.color}>{errorMessage.error}</small>}
                </div>
            </div>
        </div>
    )
}
export default Priority
