import { storeUserError, storeUser, storeBranch } from '../redux/actions/authentication/authActions'

export async function loadUserFromStorage(store) {
  try {
    let user = await JSON.parse(localStorage.getItem('userInfo'))
    if (!user) { return store.dispatch(storeUserError()) }
      store.dispatch(storeUser(user))

    let branch = await JSON.parse(localStorage.getItem('branch'))
    if (branch) { return store.dispatch(storeBranch(branch)) }

  } catch (e) {
    store.dispatch(storeUserError())
  }

  // Add this line to make sure the reducer returns the previous state when it receives an unknown action
  return store.getState()
}
