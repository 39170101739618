import React, { useState, useEffect, useRef } from "react";
import { XCircle, AlertCircle, CheckCircle, X } from "react-feather";
import { useIntl } from "react-intl";
import "./BodyCard.scss";

const icons = {
  success: <CheckCircle />,
  warning: <AlertCircle />,
  danger: <XCircle />,
};

function BodyCard({ title, children, footer, message, onPressEnter }) {
  const intl = useIntl();
  const bodyRef = useRef();
  const headerRef = useRef();
  const footerRef = useRef();

  const handleUserKeyPress = (event) => {
    const { keyCode } = event;

    if (keyCode === 13) {
      onPressEnter?.();
    }
  };

  useEffect(() => {
    window?.addEventListener("keydown", handleUserKeyPress);
    return () => {
      window?.removeEventListener("keydown", handleUserKeyPress);
    };
  });

  const [bodyMessage, setBodyMessage] = useState(null);
  useEffect(() => {
    if (message) {
      let timer1, timer2, timer3;
      setBodyMessage({ ...message, show: false });
      timer1 = setTimeout(() => {
        setBodyMessage({ ...message, show: true });
      }, 140);

      timer2 = setTimeout(() => {
        setBodyMessage({ ...message, show: false });
        timer3 = setTimeout(() => {
          setBodyMessage(null);
        }, 500);
      }, 5000);
      return () => {
        clearTimeout(timer1);
        clearTimeout(timer2);
        clearTimeout(timer3);
      };
    }
  }, [message]);

  return (
    <div
      className={`body-card-container ${
        bodyMessage?.show ? bodyMessage.type : ""
      }`}
    >
      {bodyMessage?.message && (
        <div
          className={`body-card-message ${
            bodyMessage?.show ? "show" : ""
          } ${bodyMessage.type ? bodyMessage.type : "default"}`}
        >
          <div className="d-flex w-100">
            <div className="flex-grow-1">
              {bodyMessage.type && icons[bodyMessage.type]}{" "}
              {intl.formatMessage({ id: bodyMessage.message })}
            </div>
            <div
              className="body-card-close-button"
              onClick={() => setBodyMessage(false)}
            >
              <X sixe={12} />
            </div>
          </div>
        </div>
      )}
      <div className="body-card-header text-center" ref={headerRef}>
        <h1>{title}</h1>
      </div>
      <div className={`body-card-content`} ref={bodyRef}>
        {children}
      </div>
      {footer && (
        <div className="body-card-footer" ref={footerRef}>
          {footer}
        </div>
      )}
    </div>
  );
}

export default BodyCard;
