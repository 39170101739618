import React, { useState, useEffect } from "react";
import mapa from "../../services/Mapa";
import MapArea from "../mapArea/MapArea";
import Button from "../button/Button";
import SearchBar from "../searchBar/SearchBar";
import Overlay from "../overlay/Overlay";
import Tools from "./components/Tools";
import useToast from "../Toast/useToast";
import { ReactComponent as Close } from "./assets/images/close.svg";
import { ReactComponent as Minimize } from "./assets/images/minimize.svg";
import { ReactComponent as ZoomIn } from "./assets/images/zoom-in.svg";
import { ReactComponent as ZoomOut } from "./assets/images/zoom-out.svg";
import { useIntl } from "react-intl";
import { uuidv4 } from "../../utils/uuidGenerator";
import "./MapDrawTool.scss";
import { useMemo } from "react";
import * as mapDrawHooks from "./hooks/mapDrawHooks";
import * as mapHooks from "../../hooks/mapHooks/MapHooks";
import { validateAndCleanShape } from "../../utils/arrayUtility";

function MapDrawTool({
  show,
  toggle,
  title,
  form,
  loading,
  action,
  showTools,
  googleKey,
  getMapData,
  initialData,
  // isUpdate,
}) {
  const toast = useToast();
  const enableColor = "#009ACA";
  const disableColor = "#000000";
  const intl = useIntl();
  const defaultZoom = 25;
  const [map, setMap] = useState(null);
  const [minimized, setMinimized] = useState(false);
  const [expanded, setExpanded] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [activeTool, setActiveTool] = useState("navigation");
  const [uuidEditing, setUuidEditing] = useState("");
  const [historic, setHistoric] = useState([]);

  useMemo(() => {
    if (show && !map) {
      mapHooks.LoadMap("window-map", googleKey).then((currentMap) => {
        console.log("currentMap", currentMap);
        setMap(currentMap);
      });
    }
  }, [show]);

  function onKeyUp(e) {
    const { key, keyCode } = e;
    if (key === "Enter" || keyCode === 13) {
      handleSearchCoordinates();
    } else if (key === "Escape" || keyCode === 27) {
      setSearchTerm("");
    }
  }

  function toggleExpand() {
    setExpanded((prevExpanded) => !prevExpanded);
  }

  function toggleMinimize() {
    setMinimized((prevMinimized) => !prevMinimized);
  }

  function handleToolAction(action) {
    switch (action) {
      case "undo":
        undoShape();
        break;
      case "delete":
        deleteShape();
        break;
      default:
    }
  }

  function changeActiveTool(tool) {
    switch (tool) {
      case "navigation":
        setActiveTool("navigation");
        handleCreateShape("navigation");
        mapDrawHooks.ResetShapeColor(map, disableColor);
        break;
      case "polyline":
        setActiveTool("polyline");
        handleCreateShape("polyline");
        mapDrawHooks.ResetShapeColor(map, disableColor);
        break;
      case "polygon":
        setActiveTool("polygon");
        handleCreateShape("polygon");
        mapDrawHooks.ResetShapeColor(map, disableColor);
        break;
      case "circleMarker":
        setActiveTool("circleMarker");
        handleCreateShape("circleMarker");
        mapDrawHooks.ResetShapeColor(map, disableColor);
        break;
      default:
    }
  }

  const handleSearchCoordinates = () => {
    if (searchTerm.trim().length > 0) {
      const params = {
        endereco: searchTerm,
      };

      mapa
        .coordenadas(params)
        .then((res) => {
          setSearchTerm("");
          map.setZoom(defaultZoom);
          setTimeout(() => {
            map.setCenter([res.latitude, res.longitude]);
          }, 100);
          const params = {
            MapDrawTool: {
              searchMap: {
                latitude: res.latitude,
                longitude: res.longitude,
                zoom: defaultZoom,
              },
            },
          };
          setSearchTerm("");
          const prevSearches = JSON.parse(localStorage.getItem("searches"));
          localStorage.setItem(
            "searches",
            JSON.stringify({ ...prevSearches, ...params })
          );
        })
        .catch(() => {
          toast.add({
            message: "Endenreço não encontrado!",
            color: "warning",
            autoClose: 7000,
          });
        });
    }
  };

  const allUuids =
    historic.length > 1 ? Object.keys(historic[historic.length - 2]) : [];

  const selectShape = (uuid, type) => {
    setUuidEditing(uuid);
    mapDrawHooks.ResetShapeColor(map, disableColor);
    switch (type) {
      case "polyline":
        mapDrawHooks.alterPolylineColor(map, uuid, enableColor);
        break;
      case "polygon":
        mapDrawHooks.alterPolygonColor(map, uuid, enableColor);
        break;
      case "circleMarker":
        mapDrawHooks.alterCircleMarker(map, uuid, enableColor);
        break;
    }
  };

  const undoShape = () => {
    if (initialData !== undefined && historic.length <= 1) {
      return;
    } else {
      mapDrawHooks.RemoveAllPolylines(map);
      mapDrawHooks.RemoveAllPolygons(map);
      mapDrawHooks.RemoveAllCircles(map);
      allUuids.map((uuid) => {
        switch (historic[historic.length - 2][uuid].type) {
          case "polyline":
            mapDrawHooks.RenderPolyline(
              map,
              uuid,
              historic[historic.length - 2][uuid].latlng,
              () => {
                setActiveTool("polyline");
                setUuidEditing(uuid);
                handleUpdateShape(uuid, "polyline");
              }
            );
            mapDrawHooks.AddEventPolyline(map, uuid, () => {
              handleSaveHistoric(uuid, "polyline");
              handleUpdateShape(uuid, "polyline");
            });
            break;
          case "polygon":
            mapDrawHooks.RenderPolygon(
              map,
              uuid,
              historic[historic.length - 2][uuid].latlng,
              () => {
                setActiveTool("polygon");
                setUuidEditing(uuid);
                handleUpdateShape(uuid, "polygon");
              }
            );
            mapDrawHooks.AddEventPolygon(map, uuid, () => {
              handleSaveHistoric(uuid, "polygon");
              handleUpdateShape(uuid, "polygon");
            });
            break;
          case "circleMarker":
            mapDrawHooks.RenderCircleMarker(
              map,
              uuid,
              historic[historic.length - 2][uuid].latlng,
              () => {
                setActiveTool("circleMarker");
                setUuidEditing(uuid);
                handleUpdateShape(uuid, "circleMarker");
              }
            );
            mapDrawHooks.AddEventCircleMarker(map, uuid, () => {
              handleSaveHistoric(uuid, "circleMarker");
              handleUpdateShape(uuid, "circleMarker");
            });
            break;
          default:
            break;
        }
      });

      if (allUuids.includes(uuidEditing)) {
        selectShape(uuidEditing, activeTool);
      } else {
        mapDrawHooks.RemoveMapEventClick(map);
        setUuidEditing("");
        setActiveTool("navigation");
      }

      setHistoric((prevHistoric) => {
        const removeIndex = historic.length - 1 <= 0 ? 0 : historic.length - 1;
        return prevHistoric.filter((_, index) => index !== removeIndex);
      });
    }
  };

  const deleteShape = () => {
    if (uuidEditing !== "") {
      setHistoric((prevHistoric) => {
        let deletedShape = {};
        const prevAllUuids = Object.keys(prevHistoric[prevHistoric.length - 1]);

        prevAllUuids
          .filter((uuid) => uuid !== uuidEditing)
          .map((uuid) => {
            deletedShape = {
              ...deletedShape,
              [uuid]: prevHistoric[prevHistoric.length - 1][uuid],
            };
          });

        mapDrawHooks.RemoveAllPolylines(map);
        mapDrawHooks.RemoveAllPolygons(map);
        mapDrawHooks.RemoveAllCircles(map);
        mapDrawHooks.RemoveMapEventClick(map);
        setActiveTool("navigation");
        Object.keys(deletedShape).map((uuid) => {
          switch (deletedShape[uuid].type) {
            case "polyline":
              mapDrawHooks.RenderPolyline(
                map,
                uuid,
                deletedShape[uuid].latlng,
                () => {
                  setActiveTool("polyline");
                  setUuidEditing(uuid);
                  handleUpdateShape(uuid, "polyline");
                }
              );
              mapDrawHooks.AddEventPolyline(map, uuid, () => {
                handleSaveHistoric(uuid, "polyline");
                handleUpdateShape(uuid, "polyline");
              });
              break;
            case "polygon":
              mapDrawHooks.RenderPolygon(
                map,
                uuid,
                deletedShape[uuid].latlng,
                () => {
                  setActiveTool("polygon");
                  setUuidEditing(uuid);
                  handleUpdateShape(uuid, "polygon");
                }
              );
              mapDrawHooks.AddEventPolygon(map, uuid, () => {
                handleSaveHistoric(uuid, "polygon");
                handleUpdateShape(uuid, "polygon");
              });
              break;
            case "circleMarker":
              mapDrawHooks.RenderCircleMarker(
                map,
                uuid,
                deletedShape[uuid].latlng,
                () => {
                  setActiveTool("circleMarker");
                  setUuidEditing(uuid);
                  handleUpdateShape(uuid, "circleMarker");
                }
              );
              mapDrawHooks.AddEventCircleMarker(map, uuid, () => {
                handleSaveHistoric(uuid, "circleMarker");
                handleUpdateShape(uuid, "circleMarker");
              });
              break;
            default:
              break;
          }
        });

        return [...prevHistoric, deletedShape];
      });
    }
  };

  const handleSaveHistoric = (uuid, type, clickLatlng = null) => {
    setHistoric((prevHistoric) => {
      switch (type) {
        case "polyline": {
          const polylinePath = map?.getPolylinePath(
            "polyline",
            (object) => object.uuid === uuid
          );
          return [
            ...prevHistoric,
            {
              ...prevHistoric[prevHistoric.length - 1],
              [uuid]: {
                type: type,
                latlng: polylinePath,
              },
            },
          ];
        }

        case "polygon": {
          if (googleKey) {
            const polygonPath = map?.polygonsList?.polygon?.find(
              (item) => item.object.uuid === uuid
            )?.object?.latlng;

            const previousLatlngs =
              prevHistoric?.[prevHistoric.length - 1]?.[uuid]?.latlng || [];
            let newLatlngs;

            if (previousLatlngs.length === 0) {
              // Se não houver histórico, adicionar polygonPath
              newLatlngs = [polygonPath, clickLatlng];
            } else {
              // Se houver histórico, adicionar apenas clickLatlng
              newLatlngs = [...previousLatlngs, clickLatlng];
            }

            return [
              ...prevHistoric,
              {
                ...prevHistoric[prevHistoric.length - 1],
                [uuid]: {
                  type: type,
                  latlng: newLatlngs.filter(Boolean),
                },
              },
            ];
          }

          const polygonPath = map?.polygonsList?.polygon
            ?.find((item) => item.object.uuid === uuid)
            ?._latlngs.map((latlng) => {
              if (Array.isArray(latlng)) {
                return latlng.map((llm) => {
                  return [llm.lat, llm.lng];
                });
              } else {
                return [latlng.lat, latlng.lng];
              }
            });
          return [
            ...prevHistoric,
            {
              ...prevHistoric[prevHistoric.length - 1],
              [uuid]: {
                type: type,
                latlng: polygonPath,
              },
            },
          ];
        }

        case "circleMarker": {
          if (googleKey) {
            const circlePath = map.markersList.marker.find(
              (item) => item.object.uuid === uuid
            ).object._latlngs;
            return [
              ...prevHistoric,
              {
                ...prevHistoric[prevHistoric.length - 1],
                [uuid]: {
                  type: type,
                  latlng: [circlePath.lat, circlePath.lng],
                },
              },
            ];
          }
          const circlePath = map.markersList.marker.find(
            (item) => item.object.uuid === uuid
          )._latlng;
          return [
            ...prevHistoric,
            {
              ...prevHistoric[prevHistoric.length - 1],
              [uuid]: {
                type: type,
                latlng: [circlePath.lat, circlePath.lng],
              },
            },
          ];
        }

        default:
          break;
      }
    });
  };

  const handleUpdateShape = (uuid, type) => {
    mapDrawHooks.RemoveMapEventClick(map);
    switch (type) {
      case "polyline":
        mapDrawHooks.AddMapEventClick(map, (updateEvent) => {
          mapDrawHooks.UpdatePolyline(map, uuid, updateEvent.latlng);
          handleSaveHistoric(uuid, "polyline");
        });
        mapDrawHooks.AddEventPolyline(map, uuid, () => {
          handleSaveHistoric(uuid, "polyline");
          handleUpdateShape(uuid, "polyline");
        });
        break;
      case "polygon":
        mapDrawHooks.AddMapEventClick(map, (updateEvent) => {
          mapDrawHooks.UpdatePolygon(map, uuid, updateEvent.latlng);
          handleSaveHistoric(uuid, "polygon", updateEvent.latlng);
        });
        mapDrawHooks.AddEventPolygon(map, uuid, () => {
          handleSaveHistoric(uuid, "polygon");
          handleUpdateShape(uuid, "polygon");
        });
        break;
      case "circleMarker":
        mapDrawHooks.AddEventCircleMarker(map, uuid, () => {
          handleSaveHistoric(uuid, "circleMarker");
          handleUpdateShape(uuid, "circleMarker");
        });
        break;
      default:
        break;
    }
    selectShape(uuid, type);
  };

  const handleCreateShape = (shapeType) => {
    const uuid = uuidv4();

    mapDrawHooks.RemoveMapEventClick(map);
    mapDrawHooks.AddMapEventClick(map, (createEvent) => {
      switch (shapeType) {
        case "navigation":
          mapDrawHooks.RemoveMapEventClick(map);
          break;
        case "polyline":
          mapDrawHooks.CreatePolyline(map, uuid, createEvent.latlng, () => {
            setActiveTool("polyline");
            setUuidEditing(uuid);
            handleUpdateShape(uuid, "polyline");
          });
          handleSaveHistoric(uuid, "polyline");
          handleUpdateShape(uuid, "polyline");
          break;
        case "polygon":
          mapDrawHooks.CreatePolygon(map, uuid, createEvent.latlng, () => {
            setActiveTool("polygon");
            setUuidEditing(uuid);
            handleUpdateShape(uuid, "polygon");
          });
          handleSaveHistoric(uuid, "polygon");
          handleUpdateShape(uuid, "polygon");

          break;
        case "circleMarker": {
          const uuidCircle = uuidv4();
          mapDrawHooks.ResetShapeColor(map, disableColor);
          mapDrawHooks.CreateCircleMarker(
            map,
            uuidCircle,
            createEvent.latlng,
            () => {
              setActiveTool("circleMarker");
              setUuidEditing(uuidCircle);
              handleUpdateShape(uuidCircle, "circleMarker");
            }
          );
          setUuidEditing(uuidCircle);
          handleSaveHistoric(uuidCircle, "circleMarker");
          break;
        }

        default:
          break;
      }
    });
  };

  const getMapShapes = (action) => {
    const lastUuids =
      historic.length >= 1 ? Object.keys(historic[historic.length - 1]) : [];
    const lastHistoric = historic[historic.length - 1];

    const mapData = {
      polylines: lastUuids
        .filter((uuid) => lastHistoric[uuid].type === "polyline")
        .map((uuid) => {
          return {
            latlngs: lastHistoric[uuid].latlng,
          };
        }),
      circleMarkers: lastUuids
        .filter((uuid) => lastHistoric[uuid].type === "circleMarker")
        .map((uuid) => {
          return {
            latlngs: lastHistoric[uuid].latlng,
          };
        }),
      polygons: lastUuids
        .filter((uuid) => lastHistoric[uuid].type === "polygon")
        .map((uuid) => {
          return {
            latlngs: lastHistoric[uuid].latlng,
          };
        }),
      formasAlteradas: historic.length > 1,
    };

    getMapData(action, mapData);
  };

  const getInitialMapShape = (initialData) => {
    let initialHistoric = {};

    initialData.polylines &&
      initialData.polylines.map((polyline) => {
        const uuid = uuidv4();
        mapDrawHooks.RenderPolyline(map, uuid, polyline.latlngs, () => {
          setActiveTool("polyline");
          setUuidEditing(uuid);
          handleUpdateShape(uuid, "polyline");
        });
        mapDrawHooks.AddEventPolyline(map, uuid, () => {
          handleSaveHistoric(uuid, "polyline");
          handleUpdateShape(uuid, "polyline");
        });
        initialHistoric = {
          ...initialHistoric,
          [uuid]: {
            type: "polyline",
            latlng: polyline.latlngs,
          },
        };
      });
    initialData.polygons &&
      initialData.polygons.map((polygon) => {
        const itemCleaned = validateAndCleanShape(polygon.latlngs);
        if (itemCleaned) {
          const uuid = uuidv4();
          mapDrawHooks.RenderPolygon(map, uuid, polygon.latlngs, () => {
            setActiveTool("polygon");
            setUuidEditing(uuid);
            handleUpdateShape(uuid, "polygon");
          });
          mapDrawHooks.AddEventPolygon(map, uuid, () => {
            handleSaveHistoric(uuid, "polygon");
            handleUpdateShape(uuid, "polygon");
          });
          initialHistoric = {
            ...initialHistoric,
            [uuid]: {
              type: "polygon",
              latlng: Array.isArray(polygon.latlngs[0][0])
                ? polygon.latlngs
                : [polygon.latlngs],
            },
          };
        }
      });
    initialData.circleMarkers &&
      initialData.circleMarkers.map((circleMarker) => {
        const uuid = uuidv4();
        mapDrawHooks.RenderCircleMarker(
          map,
          uuid,
          circleMarker.latlngs[0],
          () => {
            setActiveTool("circleMarker");
            setUuidEditing(uuid);
            handleUpdateShape(uuid, "circleMarker");
          }
        );
        mapDrawHooks.AddEventCircleMarker(map, uuid, () => {
          handleSaveHistoric(uuid, "circleMarker");
          handleUpdateShape(uuid, "circleMarker");
        });
        initialHistoric = {
          ...initialHistoric,
          [uuid]: {
            type: "circleMarker",
            latlng: circleMarker.latlngs[0],
          },
        };
      });

    const circleMarkersLength = initialData.circleMarkers?.length ?? 0;
    const polygonsLength = initialData.polygons?.length ?? 0;
    const polylinesLength = initialData.polylines?.length ?? 0;

    if (
      polylinesLength >= 1 &&
      polylinesLength > polygonsLength &&
      polylinesLength > circleMarkersLength
    ) {
      map.fitBoundsPolylines("polyline");
    } else if (
      polygonsLength >= 1 &&
      polygonsLength > polylinesLength &&
      polygonsLength > circleMarkersLength
    ) {
      map.fitBoundsPolygons("polygon");
    } else if (
      circleMarkersLength >= 1 &&
      circleMarkersLength > polygonsLength &&
      circleMarkersLength > polylinesLength
    ) {
      map.fitBoundsMarkers("circleMarker");
    }

    setHistoric([initialHistoric]);
  };

  useMemo(() => {
    if (!show && map) {
      mapDrawHooks.RemoveAllPolylines(map);
      mapDrawHooks.RemoveAllPolygons(map);
      mapDrawHooks.RemoveAllCircles(map);
      mapDrawHooks.RemoveMapEventClick(map);
      setHistoric([]);
      setActiveTool("navigation");
      setUuidEditing("");
    }
  }, [show]);

  useMemo(() => {
    if (initialData && map) {
      getInitialMapShape(initialData);
    }
  }, [initialData, map]);

  useEffect(() => {
    console.log("1234 historic", historic);
  }, [historic]);

  useEffect(() => {
    console.log("1234 map", map);
  }, [map]);

  return (
    <div
      className={`rc-window-ui  ${minimized ? "minimized" : ""} ${
        show ? "show" : ""
      }`}
    >
      <Overlay show={show && !minimized} zIndex={11} onClick={toggle} />
      <div
        className={`rc-window-ui__container ${show ? "show" : ""} ${
          expanded ? "expanded" : ""
        }`}
        onClick={() => (minimized ? toggleMinimize() : {})}
      >
        <div className="rc-window-ui__header">
          <ul>
            <li className="tool-close" onClick={toggle}>
              <Close />
            </li>
            <li className="toogle-tool-minimize" onClick={toggleMinimize}>
              <Minimize />
            </li>
            <li className="toogle-tool-expand" onClick={toggleExpand}>
              {expanded ? <ZoomOut /> : <ZoomIn />}
            </li>
          </ul>
          <h3>{intl.formatMessage({ id: title })}</h3>
          <div className="rc-window-ui__header__save"></div>
        </div>
        <div className="rc-window-ui__form">{form}</div>
        <div className="rc-window-ui__body">
          <div className="rc-map-search">
            <div className="rc-search-container">
              <SearchBar
                id="location"
                value={searchTerm}
                className="mr-50"
                placeholder={
                  "Digite um endereço, cidade ou qualquer localização"
                }
                handleTermFilter={(e) => setSearchTerm(e)}
                dynamic={false}
                onKeyUp={onKeyUp}
              />
              <Button.Search
                disabled={searchTerm.trim().length === 0}
                dataCy="search-location"
                dark
                onClick={() => handleSearchCoordinates()}
              />
            </div>
          </div>
          <MapArea
            latlng={null}
            id="window-map"
            loadingMap={!map}
            loadingShapes={false}
            minHeight={250}
            height={`100%`}
          />
          {map && (
            <Tools
              showActionButtons={{
                undo: true, // TODO: APÒS RESOLVER BUG DE POLYGONLIST - REMOVER ISSO E DEIXAR TRUE
                erase: true,
              }}
              showTools={showTools}
              activeTool={activeTool}
              changeActiveTool={(param) => changeActiveTool(param)}
              handleToolAction={(param) => handleToolAction(param)}
              showEditTools={true}
            />
          )}
        </div>
        <div className="rc-window-ui__footer">
          <Button.Cancel disabled={loading} dark onClick={() => toggle()} />
          {action === "add" ? (
            <Button.Add
              disabled={!map}
              dark
              loading={loading}
              onClick={() => getMapShapes(action)}
            />
          ) : (
            <Button.Save
              disabled={!map}
              dark
              loading={loading}
              onClick={() => getMapShapes(action)}
            />
          )}
        </div>
      </div>
    </div>
  );
}
export default MapDrawTool;
