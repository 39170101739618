import React, { useEffect, useState } from "react";

const TableCell = ({ column, row, customBody }) => {
  const [highlight, setHighlight] = useState(false);

  useEffect(() => {
    if (row.showHightLight === column.name) {
      setHighlight(true);
      const timer = setTimeout(() => {
        setHighlight(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [row.showHightLight, column.name]);

  function getNestedProperty(obj, path) {
    return path.split(".").reduce((acc, part) => acc && acc[part], obj);
  }

  const cellContent = (() => {
    const propertyPath = column.name; // Isso pode ser algo como 'contrato.nome'

    // Verificar se a propriedade customizada deve ser usada
    if (customBody && customBody.columns.includes(propertyPath)) {
      return customBody.custom(row, propertyPath);
    }

    // Tentar acessar a propriedade, mesmo que aninhada
    const value = getNestedProperty(row, propertyPath);

    if (value) {
      if (typeof value === "string") {
        return `${value.substring(0, 25)}${value.length > 25 ? "..." : ""}`;
      } else {
        return value;
      }
    } else {
      return "---";
    }
  })();

  const style = {
    backgroundColor: highlight ? "yellow" : null,
    transition: "background-color 2s",
  };

  return (
    <td
      style={style}
      className={column.center ? "center" : "right"}
      title={
        typeof row[column.name] === "string" &&
        String(row[column.name]).length >= 25
          ? String(row[column.name])
          : null
      }
    >
      {cellContent}
    </td>
  );
};

export default TableCell;
