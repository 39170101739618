import React from 'react'
import "./Toggle.scss"

function Toggle({ label, value, onChange, name, id, className }) {

    function changeToggle() {
        onChange({
            target: {
                name: name,
                value: !value
            }
        })
    }
    return (
        <div id={id} className={`rc-toggle-ui ${className ? className : ''}`} onClick={changeToggle}>
            <div className={`rc-toggle-ui-button ${value ? 'on' : 'off'}`}>
                <div className='rc-toggle-ui-bullet'></div>
            </div>
            {label && <span>{label}</span>}
        </div>
    )
}
export default Toggle
