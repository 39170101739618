import React, { useState, useEffect, useCallback } from "react";
import classnames from "classnames";
import MasterMenu from "./components/masterMenu/MasterMenu";
import CustomHeader from "./components/customHeader/customHeader";
import themeConfig from "../configs/themeConfig";
import { connect } from "react-redux"

const HorizontalLayout = ({ children, location }) => {
  const [state, setState] = useState({
    width: window.innerWidth,
    sidebarState: false,
    collapsedContent: false,
    sidebarHidden: false,
    currentLang: "en",
    appOverlay: false,
    customizer: false,
    currRoute: location.pathname,
    menuOpen: themeConfig.menuOpen,
    showMenu: false,
    handleMenuIsShowing: false,
  });

  useEffect(() => {
    const updateWidth = () => {
      setState((prev) => ({ ...prev, width: window.innerWidth }));
    };

    const updateScroll = () => {
      setState((prev) => ({ ...prev, scroll: window.pageYOffset }));
    };

    window.addEventListener("resize", updateWidth);
    window.addEventListener("scroll", updateScroll);

    if (location.pathname === "/pages/profile") {
      setState((prev) => ({
        ...prev,
        sidebarState: true,
        collapsedContent: true,
      }));
    }

    return () => {
      window.removeEventListener("resize", updateWidth);
      window.removeEventListener("scroll", updateScroll);
    };
  }, [location.pathname]);

  const handleRouteChange = () => {
    if (window.location.pathname === "/pages/profile") {
      setState((prev) => ({
        ...prev,
        collapsedContent: true,
        currRoute: window.location.pathname,
      }));
    } else {
      setState((prev) => ({
        ...prev,
        currRoute: window.location.pathname,
        sidebarState: false,
        collapsedContent: false,
      }));
    }
  };

  useCallback(() => {
    if (state.currRoute !== window.location.pathname) {
      handleRouteChange();
      setState((prev) => ({ ...prev, currRoute: window.location.pathname }));
    }
  }, [window.location]);

  const handleSidebarVisibility = () => {
    setState((prev) => ({
      ...prev,
      sidebarHidden: !prev.sidebarHidden,
    }));
  };

  const handleAppOverlayClick = () => {
    setState((prev) => ({
      ...prev,
      appOverlay: false,
    }));
  };

  const toggleShowMenu = () => {
    setState((prev) => ({
      ...prev,
      showMenu: !prev.showMenu,
    }));
  };

  const handleMenuIsShowing = (param) => {
    setState((prev) => ({
      ...prev,
      handleMenuIsShowing: param,
    }));
  };

  return (
    <div
      className={classnames(
        `wrapper horizontal-layout theme-primary navbar-floating`,
        {
          "menu-collapsed": state.collapsedContent && state.width > 1200,
        }
      )}
    >
      <div
        className={classnames("scale-animation app-content content", {
          "show-overlay": state.appOverlay,
          "animation-active": state.showMenu,
        })}
        onClick={handleAppOverlayClick}
      >
        <CustomHeader />
        {children}
      </div>
      <MasterMenu
        menuIsShowing={handleMenuIsShowing}
        show={state.showMenu}
        toggleShowMenu={toggleShowMenu}
      />
      <div className="sidenav-overlay" onClick={handleSidebarVisibility} />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    app: state.customizer
  }
}
export default connect(mapStateToProps, {
})(HorizontalLayout)
