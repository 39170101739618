import React from 'react';
import './Checkbox.scss'

const Checkbox = ({ label, checked, onChange, name, id, className, disabled }) => {
    return (
        <div className={`rc-checkbox-ui ${className ? className : ''}`} >
            <input type="checkbox"
                onChange={e => onChange(e)}
                checked={checked}
                name={name}
                id={id}
                className="checkbox-input-field"
                disabled={disabled} />
            {label && <label htmlFor={id}>{label}</label>}
        </div>
    )
}
export default Checkbox
