import React, { forwardRef } from "react";
import Row from "./Row";

// eslint-disable-next-line react/display-name
const Body = forwardRef(
  (
    {
      rows,
      loading,
      emptyState,
      highlightRow,
      handleRowClick,
      selectable,
      handleRowAction,
      selectableDisabled,
      select,
      columns,
      customBody,
      administrator,
      editable,
      controls,
      setControls,
      features,
      visibility,
      ...props
    },
    ref
  ) => (
    <>
      {rows.length > 0 ? (
        <tbody
          className={`${administrator ? "administrator" : ""} ${
            visibility ? "visible" : "visible"
          }`}
          {...props}
        >
          {rows.map((row, index) => (
            <Row
              key={index}
              row={row}
              index={index}
              highlightRow={highlightRow}
              highlightRowRef={ref}
              handleRowClick={handleRowClick}
              selectable={selectable}
              handleRowAction={handleRowAction}
              selectableDisabled={selectableDisabled}
              select={select}
              columns={columns}
              customBody={customBody}
              administrator={administrator}
              editable={editable}
              controls={controls}
              setControls={setControls}
              features={features}
            />
          ))}
        </tbody>
      ) : (
        <tbody>
          <tr className="border-none">
            <td
              style={{ display: "table-cell" }}
              className="bg-white"
              colSpan={columns.filter((f) => !f.hidden).length + 1}
            >
              <div className="d-flex justify-content-center h-100 align-items-center w-100 py-5">
                {!loading && emptyState ? (
                  emptyState
                ) : (
                  <p className="d-block fs-24 color-red text-center">
                    Nenhum resultado encontrado!
                  </p>
                )}
              </div>
            </td>
          </tr>
        </tbody>
      )}
    </>
  )
);

export default Body;
