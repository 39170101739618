import React from 'react'
import "./Tag.scss"

function Tag({ label, className, color, size }) {
return (
      <span className={`ui-tag ${color ? color : ''} ${className ? className : ''} ${size ? size : ''}`}>
          <span>{label}</span>
      </span>
  )
}
export default Tag
