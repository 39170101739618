import React from "react";
import * as Icon from "react-feather";
import { Spinner } from "reactstrap";
import { Tooltip } from "../../components";
import { Button } from "@inlog/react-components/dist/components/ui/button";
import "./Button.scss";

const button = ({ children }) => {
  return <Button className="button-ui">{children}</Button>;
};

const Add = (props) => (
  <button
    ref={props.ref}
    onClick={props.onClick}
    id={props.id}
    data-cy={props.dataCy ? props.dataCy : "none"}
    className={`button-ui add ${props.dark ? "dark-button" : ""} ${
      props.size ? props.size : ""
    } ${props.className ? props.className : ""}`}
    disabled={props.loading || props.disabled}
  >
    {props.tooltip && (
      <Tooltip
        position={props.legendPosition}
        label={props.legend}
        color={props.legendColor}
      />
    )}
    <Icon.PlusCircle size={16} className="button-icon" />
    {props.loading && <Spinner size="sm" />}
    <span
      className={`default-button-ui-text ${props.loading ? "processing" : ""}`}
    >
      {props.title ? props.title : props.icon ? "" : "Adicionar"}
    </span>
  </button>
);
button.Add = Add;

const Save = (props) => (
  <button
    ref={props.ref}
    onClick={props.onClick}
    id={props.id}
    data-cy={props.dataCy ? props.dataCy : "none"}
    className={`button-ui save ${props.dark ? "dark-button" : ""} ${
      props.size ? props.size : ""
    } ${props.className ? props.className : ""}`}
    disabled={props.loading || props.disabled}
  >
    {props.tooltip && (
      <Tooltip
        position={props.legendPosition}
        label={props.legend}
        color={props.legendColor}
      />
    )}
    <Icon.Save size={16} className="button-icon" />
    <>
      {props.loading && <Spinner size="sm" />}
      <span
        className={`default-button-ui-text ${
          props.loading ? "processing" : ""
        }`}
      >
        Salvar
      </span>
    </>
  </button>
);
button.Save = Save;

const Confirm = (props) => (
  <button
    ref={props.ref}
    onClick={props.onClick}
    id={props.id}
    data-cy={props.dataCy ? props.dataCy : "none"}
    className={`button-ui confirm ${props.dark ? "dark-button" : ""} ${
      props.size ? props.size : ""
    } ${props.className ? props.className : ""}`}
    disabled={props.loading || props.disabled}
  >
    {props.tooltip && (
      <Tooltip
        position={props.legendPosition}
        label={props.legend}
        color={props.legendColor}
      />
    )}
    <Icon.CheckCircle size={16} className="button-icon" />
    <>
      {props.loading && <Spinner size="sm" />}
      <span
        className={`default-button-ui-text ${
          props.loading ? "processing" : ""
        }`}
      >
        Confirmar
      </span>
    </>
  </button>
);
button.Confirm = Confirm;

const Archive = (props) => (
  <button
    ref={props.ref}
    onClick={props.onClick}
    id={props.id}
    data-cy={props.dataCy ? props.dataCy : "none"}
    className={`button-ui confirm ${props.dark ? "dark-button" : ""} ${
      props.size ? props.size : ""
    } ${props.className ? props.className : ""}`}
    disabled={props.loading || props.disabled}
  >
    {props.tooltip && (
      <Tooltip
        position={props.legendPosition}
        label={props.legend}
        color={props.legendColor}
      />
    )}
    <Icon.Archive size={16} className="button-icon" />
    <>
      {props.loading && <Spinner size="sm" />}
      <span
        className={`default-button-ui-text ${
          props.loading ? "processing" : ""
        }`}
      >
        Arquivar
      </span>
    </>
  </button>
);
button.Archive = Archive;

const Unarchive = (props) => (
  <button
    ref={props.ref}
    onClick={props.onClick}
    id={props.id}
    data-cy={props.dataCy ? props.dataCy : "none"}
    className={`button-ui undo ${props.dark ? "dark-button" : ""} ${
      props.size ? props.size : ""
    } ${props.className ? props.className : ""}`}
    disabled={props.loading || props.disabled}
  >
    {props.tooltip && (
      <Tooltip
        position={props.legendPosition}
        label={props.legend}
        color={props.legendColor}
      />
    )}
    <Icon.Archive size={16} className="button-icon" />
    <>
      {props.loading && <Spinner size="sm" />}
      <span
        className={`default-button-ui-text ${
          props.loading ? "processing" : ""
        }`}
      >
        Desarquivar
      </span>
    </>
  </button>
);
button.Unarchive = Unarchive;

const Undoarchive = (props) => (
  <button
    ref={props.ref}
    onClick={props.onClick}
    id={props.id}
    data-cy={props.dataCy ? props.dataCy : "none"}
    className={`button-ui undo ${props.dark ? "dark-button" : ""} ${
      props.size ? props.size : ""
    } ${props.className ? props.className : ""}`}
    disabled={props.loading || props.disabled}
  >
    {props.tooltip && (
      <Tooltip
        position={props.legendPosition}
        label={props.legend}
        color={props.legendColor}
      />
    )}
    <Icon.RotateCcw size={16} className="button-icon" />
    <>
      {props.loading && <Spinner size="sm" />}
      <span
        className={`default-button-ui-text ${
          props.loading ? "processing" : ""
        }`}
      >
        Desfazer
      </span>
    </>
  </button>
);
button.Undoarchive = Undoarchive;

const Folder = (props) => (
  <button
    ref={props.ref}
    onClick={props.onClick}
    id={props.id}
    data-cy={props.dataCy ? props.dataCy : "none"}
    className={`button-ui confirm ${props.dark ? "dark-button" : ""} ${
      props.size ? props.size : ""
    } ${props.className ? props.className : ""}`}
    disabled={props.loading || props.disabled}
  >
    {props.tooltip && (
      <Tooltip
        position={props.legendPosition}
        label={props.legend}
        color={props.legendColor}
      />
    )}
    <Icon.Archive size={16} />
    <>{props.loading && <Spinner size="sm" />}</>
  </button>
);
button.Folder = Folder;

const Copy = (props) => (
  <button
    ref={props.ref}
    onClick={props.onClick}
    id={props.id}
    data-cy={props.dataCy ? props.dataCy : "none"}
    className={`button-ui info ${props.dark ? "dark-button" : ""} ${
      props.size ? props.size : ""
    } ${props.className ? props.className : ""}`}
    disabled={props.loading || props.disabled}
  >
    {props.tooltip && (
      <Tooltip
        position={props.legendPosition}
        label={props.legend}
        color={props.legendColor}
      />
    )}
    <Icon.Copy size={16} />
    <>{props.loading && <Spinner size="sm" />}</>
  </button>
);
button.Copy = Copy;

const Details = (props) => (
  <button
    ref={props.ref}
    onClick={props.onClick}
    id={props.id}
    data-cy={props.dataCy ? props.dataCy : "none"}
    className={`button-ui info ${props.dark ? "dark-button" : ""} ${
      props.size ? props.size : ""
    } ${props.className ? props.className : ""}`}
    disabled={props.loading || props.disabled}
  >
    {props.tooltip && (
      <Tooltip
        position={props.legendPosition}
        label={props.legend}
        color={props.legendColor}
      />
    )}
    <Icon.MoreVertical size={16} />
    <>{props.loading && <Spinner size="sm" />}</>
  </button>
);
button.Details = Details;

const Delete = (props) => (
  <button
    ref={props.ref}
    onClick={props.onClick}
    id={props.id}
    data-cy={props.dataCy ? props.dataCy : "none"}
    className={`button-ui delete ${props.dark ? "dark-button" : ""} ${
      props.size ? props.size : ""
    } ${props.className ? props.className : ""}`}
    disabled={props.loading || props.disabled}
  >
    {props.tooltip && (
      <Tooltip
        position={props.legendPosition}
        label={props.legend}
        color={props.legendColor}
      />
    )}
    <Icon.XCircle size={16} className="button-icon" />
    <>
      {props.loading && <Spinner size="sm" />}
      <span
        className={`default-button-ui-text ${
          props.loading ? "processing" : ""
        }`}
      >
        Apagar
      </span>
    </>
  </button>
);
button.Delete = Delete;

const Logout = (props) => (
  <button
    ref={props.ref}
    onClick={props.onClick}
    id={props.id}
    data-cy={props.dataCy ? props.dataCy : "none"}
    className={`button-ui delete ${props.dark ? "dark-button" : ""} ${
      props.size ? props.size : ""
    } ${props.className ? props.className : ""}`}
    disabled={props.loading || props.disabled}
  >
    {props.tooltip && (
      <Tooltip
        position={props.legendPosition}
        label={props.legend}
        color={props.legendColor}
      />
    )}
    <Icon.LogOut size={16} className="button-icon" />
    <>
      {props.loading && <Spinner size="sm" />}
      <span
        className={`default-button-ui-text ${
          props.loading ? "processing" : ""
        }`}
      >
        Sair
      </span>
    </>
  </button>
);
button.Logout = Logout;

const Cancel = (props) => (
  <button
    ref={props.ref}
    onClick={props.onClick}
    id={props.id}
    data-cy={props.dataCy ? props.dataCy : "none"}
    className={`button-ui cancel ${props.dark ? "dark-button" : ""} ${
      props.size ? props.size : ""
    } ${props.className ? props.className : ""}`}
    disabled={props.loading || props.disabled}
  >
    {props.tooltip && (
      <Tooltip
        position={props.legendPosition}
        label={props.legend}
        color={props.legendColor}
      />
    )}
    <Icon.SkipBack size={16} className="button-icon" />
    <>
      {props.loading && <Spinner size="sm" />}
      <span
        className={`default-button-ui-text ${
          props.loading ? "processing" : ""
        }`}
      >
        Cancelar
      </span>
    </>
  </button>
);
button.Cancel = Cancel;

const Undo = (props) => (
  <button
    ref={props.ref}
    onClick={props.onClick}
    id={props.id}
    data-cy={props.dataCy ? props.dataCy : "none"}
    className={`button-ui undo ${props.dark ? "dark-button" : ""} ${
      props.size ? props.size : ""
    } ${props.className ? props.className : ""}`}
    disabled={props.loading || props.disabled}
  >
    {props.tooltip && (
      <Tooltip
        position={props.legendPosition}
        label={props.legend}
        color={props.legendColor}
      />
    )}
    <Icon.RotateCcw size={16} />
    {props.loading && <Spinner size="sm" />}
  </button>
);
button.Undo = Undo;

const Edit = (props) => (
  <button
    ref={props.ref}
    onClick={props.onClick}
    id={props.id}
    data-cy={props.dataCy ? props.dataCy : "none"}
    className={`button-ui edit ${props.dark ? "dark-button" : ""} ${
      props.size ? props.size : ""
    } ${props.className ? props.className : ""}`}
    disabled={props.loading || props.disabled}
  >
    {props.tooltip && (
      <Tooltip
        position={props.legendPosition}
        label={props.legend}
        color={props.legendColor}
      />
    )}
    <Icon.Edit2 size={16} />
    <>{props.loading && <Spinner size="sm" />}</>
  </button>
);
button.Edit = Edit;

const Schedule = (props) => (
  <button
    ref={props.ref}
    onClick={props.onClick}
    id={props.id}
    data-cy={props.dataCy ? props.dataCy : "none"}
    className={`button-ui schedule ${props.dark ? "dark-button" : ""} ${
      props.size ? props.size : ""
    } ${props.className ? props.className : ""}`}
    disabled={props.loading || props.disabled}
  >
    {props.tooltip && (
      <Tooltip
        position={props.legendPosition}
        label={props.legend}
        color={props.legendColor}
      />
    )}
    <Icon.Calendar size={16} />
    <>{props.loading && <Spinner size="sm" />}</>
  </button>
);
button.Schedule = Schedule;

const X = (props) => (
  <button
    ref={props.ref}
    onClick={props.onClick}
    id={props.id}
    data-cy={props.dataCy ? props.dataCy : "none"}
    className={`button-ui exclude ${props.dark ? "dark-button" : ""} ${
      props.size ? props.size : ""
    } ${props.className ? props.className : ""}`}
    disabled={props.loading || props.disabled}
  >
    {props.tooltip && (
      <Tooltip
        position={props.legendPosition}
        label={props.legend}
        color={props.legendColor}
      />
    )}
    <Icon.X size={16} />
    <>{props.loading && <Spinner size="sm" />}</>
  </button>
);
button.X = X;

const Agree = (props) => (
  <button
    ref={props.ref}
    onClick={props.onClick}
    id={props.id}
    data-cy={props.dataCy ? props.dataCy : "none"}
    className={`button-ui button-success ${props.dark ? "dark-button" : ""} ${
      props.block ? "button-block" : ""
    } ${props.size ? props.size : ""} ${
      props.className ? props.className : ""
    }`}
    disabled={props.loading || props.disabled}
  >
    {props.tooltip && (
      <Tooltip
        position={props.legendPosition}
        label={props.legend}
        color={props.legendColor}
      />
    )}
    <Icon.Check size={16} />
    <>{props.loading && <Spinner size="sm" />}</>
  </button>
);
button.Agree = Agree;

const Primary = (props) => (
  <button
    ref={props.ref}
    onClick={props.onClick}
    id={props.id}
    data-cy={props.dataCy ? props.dataCy : "none"}
    className={`button-ui button-primary ${props.dark ? "dark-button" : ""} ${
      props.size ? props.size : ""
    } ${props.className ? props.className : ""}`}
    disabled={props.loading || props.disabled}
  >
    {props.tooltip && (
      <Tooltip
        position={props.legendPosition}
        label={props.legend}
        color={props.legendColor}
      />
    )}
    <>
      {props.loading && <Spinner size="sm" />}
      <span
        className={`default-button-ui-text ${
          props.loading ? "processing" : ""
        }`}
      >
        {props.children}
      </span>
    </>
  </button>
);
button.Primary = Primary;

const Secondary = (props) => (
  <button
    ref={props.ref}
    onClick={props.onClick}
    id={props.id}
    data-cy={props.dataCy ? props.dataCy : "none"}
    className={`button-ui button-secondary ${props.dark ? "dark-button" : ""} ${
      props.size ? props.size : ""
    } ${props.className ? props.className : ""}`}
    disabled={props.loading || props.disabled}
  >
    {props.tooltip && (
      <Tooltip
        position={props.legendPosition}
        label={props.legend}
        color={props.legendColor}
      />
    )}
    <>
      {props.loading && <Spinner size="sm" />}
      <span
        className={`default-button-ui-text ${
          props.loading ? "processing" : ""
        }`}
      >
        {props.children}
      </span>
    </>
  </button>
);
button.Secondary = Secondary;

const Default = (props) => (
  <button
    ref={props.ref}
    onClick={props.onClick}
    id={props.id}
    data-cy={props.dataCy ? props.dataCy : "none"}
    className={`button-ui button-default ${props.dark ? "dark-button" : ""} ${
      props.size ? props.size : ""
    } ${props.className ? props.className : ""}`}
    disabled={props.loading || props.disabled}
  >
    {props.tooltip && (
      <Tooltip
        position={props.legendPosition}
        label={props.legend}
        color={props.legendColor}
      />
    )}
    <>
      {props.loading && <Spinner size="sm" />}
      <span
        className={`default-button-ui-text ${
          props.loading ? "processing" : ""
        }`}
      >
        {props.children}
      </span>
    </>
  </button>
);
button.Default = Default;

const Success = (props) => (
  <button
    ref={props.ref}
    onClick={props.onClick}
    id={props.id}
    data-cy={props.dataCy ? props.dataCy : "none"}
    className={`button-ui button-success ${props.dark ? "dark-button" : ""} ${
      props.size ? props.size : ""
    } ${props.className ? props.className : ""}`}
    disabled={props.loading || props.disabled}
  >
    {props.tooltip && (
      <Tooltip
        position={props.legendPosition}
        label={props.legend}
        color={props.legendColor}
      />
    )}
    <>
      {props.loading && <Spinner size="sm" />}
      <span
        className={`default-button-ui-text ${
          props.loading ? "processing" : ""
        }`}
      >
        {props.children}
      </span>
    </>
  </button>
);
button.Success = Success;

const System = (props) => (
  <button
    ref={props.ref}
    onClick={props.onClick}
    id={props.id}
    data-cy={props.dataCy ? props.dataCy : "none"}
    className={`button-ui button-system ${props.dark ? "dark-button" : ""} ${
      props.size ? props.size : ""
    } ${props.className ? props.className : ""}`}
    disabled={props.loading || props.disabled}
  >
    {props.tooltip && (
      <Tooltip
        position={props.legendPosition}
        label={props.legend}
        color={props.legendColor}
      />
    )}
    <>
      {props.loading && <Spinner size="sm" />}
      <span
        className={`default-button-ui-text ${
          props.loading ? "processing" : ""
        }`}
      >
        {props.children}
      </span>
    </>
  </button>
);
button.System = System;

const Info = (props) => (
  <button
    ref={props.ref}
    onClick={props.onClick}
    id={props.id}
    data-cy={props.dataCy ? props.dataCy : "none"}
    className={`button-ui button-info ${props.dark ? "dark-button" : ""} ${
      props.size ? props.size : ""
    } ${props.className ? props.className : ""}`}
    disabled={props.loading || props.disabled}
  >
    {props.tooltip && (
      <Tooltip
        position={props.legendPosition}
        label={props.legend}
        color={props.legendColor}
      />
    )}
    <>
      {props.loading && <Spinner size="sm" />}
      <span
        className={`default-button-ui-text ${
          props.loading ? "processing" : ""
        }`}
      >
        {props.children}
      </span>
    </>
  </button>
);
button.Info = Info;

const Warning = (props) => (
  <button
    ref={props.ref}
    onClick={props.onClick}
    id={props.id}
    data-cy={props.dataCy ? props.dataCy : "none"}
    className={`button-ui button-warning ${props.dark ? "dark-button" : ""} ${
      props.size ? props.size : ""
    } ${props.className ? props.className : ""}`}
    disabled={props.loading || props.disabled}
  >
    {props.tooltip && (
      <Tooltip
        position={props.legendPosition}
        label={props.legend}
        color={props.legendColor}
      />
    )}
    <>
      {props.loading && <Spinner size="sm" />}
      <span
        className={`default-button-ui-text ${
          props.loading ? "processing" : ""
        }`}
      >
        {props.children}
      </span>
    </>
  </button>
);
button.Warning = Warning;

const Danger = (props) => (
  <button
    ref={props.ref}
    onClick={props.onClick}
    id={props.id}
    data-cy={props.dataCy ? props.dataCy : "none"}
    className={`button-ui button-danger ${props.dark ? "dark-button" : ""} ${
      props.size ? props.size : ""
    } ${props.className ? props.className : ""}`}
    disabled={props.loading || props.disabled}
  >
    {props.tooltip && (
      <Tooltip
        position={props.legendPosition}
        label={props.legend}
        color={props.legendColor}
      />
    )}
    <>
      {props.loading && <Spinner size="sm" />}
      <span
        className={`default-button-ui-text ${
          props.loading ? "processing" : ""
        }`}
      >
        {props.children}
      </span>
    </>
  </button>
);
button.Danger = Danger;

const SpanishGray = (props) => (
  <button
    ref={props.ref}
    onClick={props.onClick}
    id={props.id}
    data-cy={props.dataCy ? props.dataCy : "none"}
    className={`button-ui button-spanish-gray ${
      props.dark ? "dark-button" : ""
    } ${props.size ? props.size : ""} ${
      props.className ? props.className : ""
    }`}
    disabled={props.loading || props.disabled}
  >
    {props.tooltip && (
      <Tooltip
        position={props.legendPosition}
        label={props.legend}
        color={props.legendColor}
      />
    )}
    <>
      {props.loading && <Spinner size="sm" />}
      <span
        className={`default-button-ui-text ${
          props.loading ? "processing" : ""
        }`}
      >
        {props.children}
      </span>
    </>
  </button>
);
button.SpanishGray = SpanishGray;

const Search = (props) => (
  <button
    ref={props.ref}
    onClick={props.onClick}
    id={props.id}
    data-cy={props.dataCy ? props.dataCy : "none"}
    className={`button-ui button-primary ${props.dark ? "dark-button" : ""} ${
      props.size ? props.size : ""
    } ${props.className ? props.className : ""}`}
    disabled={props.loading || props.disabled}
  >
    {props.tooltip && (
      <Tooltip
        position={props.legendPosition}
        label={props.legend}
        color={props.legendColor}
      />
    )}
    {props.loading && <Spinner size="sm" />}
    <Icon.Search
      size={16}
      className="button-icon m-0"
      style={{ opacity: props.loading ? 0 : 1 }}
    />
  </button>
);
button.Search = Search;

export default button;
