import TaskIcon from "../assets/img/cards-icons/tarefas.svg";
import AtivosIcon from "../assets/img/cards-icons/ativos.svg";
import EmpresasIcon from "../assets/img/cards-icons/empresas.svg";
import RegiaoIcon from "../assets/img/cards-icons/regiao.svg";
import SetorIcon from "../assets/img/cards-icons/setor.svg";
import LocalServicoIcon from "../assets/img/cards-icons/local-servico.svg";
import TipoServicoIcon from "../assets/img/cards-icons/tipo-servico.svg";
import ServicoIcon from "../assets/img/cards-icons/servico.svg";
import TipoUsuarioIcon from "../assets/img/cards-icons/tipo-usuario.svg";
import UsuarioIcon from "../assets/img/cards-icons/usuario.svg";
import EquipeIcon from "../assets/img/cards-icons/equipe.svg";
import DemandaIcon from "../assets/img/cards-icons/demanda.svg";
import AgendamentoTarefasIcon from "../assets/img/cards-icons/agendamento-tarefas.svg";
import ShiftsIcon from "../assets/img/cards-icons/shifts.svg";
import UnidadeMedidaIcon from "../assets/img/cards-icons/unidade-medida.svg";
import ImportarAgendamentoTarefasIcon from "../assets/img/cards-icons/importar-agendamento-tarefas.svg";
import PoligonoETrechoIcon from "../assets/img/cards-icons/poligono-e-trecho.svg";

const MenuItemsHooks = () => {
  const items = [
    {
      group: "Sinóticos",
      items: [
        {
          id: 2,
          settingName: "task-overview",
          attributes: {
            title: "Tarefas e demandas",
            description:
              "Permite pesquisar, fiscalizar, aprovar, contestar, reprovar, alterar ou excluir tarefas disponíveis para execução ou que estão sendo executadas.",
            required: null,
            badge: 10,
            totalTasks: 5,
            completeTasks: 3,
            icon: TaskIcon,
            path: "task-overview",
            pageTitle: "Sinóticos de tarefas e demandas",
          },
        },
        {
          id: 20,
          settingName: "actives",
          attributes: {
            title: "Ativos",
            description: "Permite gerenciar os ativos cadastrados via APP.",
            required: null,
            badge: 10,
            totalTasks: 5,
            completeTasks: 3,
            icon: AtivosIcon,
            path: "actives",
            pageTitle: "Ativos",
          },
        },
      ],
    },
    {
      group: "Cadastros",
      items: [
        {
          id: 13,
          settingName: "settings",
          attributes: {
            title: "Contrato",
            description:
              "Disponibiliza funcionalidades para pesquisar, incluir, alterar ou excluir contratos que adquiriram a aplicação Fiscol.",
            required: true,
            badge: 10,
            totalTasks: 1,
            completeTasks: 0,
            icon: EmpresasIcon,
            path: "branches",
            pageTitle: "Cadastro de Contratos",
          },
        },
        {
          id: 4,
          settingName: "output-channels",
          attributes: {
            title: "Regiões",
            description:
              "Permite realizar ações de pesquisar, incluir, alterar ou excluir as regiões referenciadas nas demandas e tarefas.",
            required: true,
            badge: 10,
            totalTasks: 1,
            completeTasks: 0,
            icon: RegiaoIcon,
            path: "regions",
            pageTitle: "Cadastro de Regiões",
          },
        },
        {
          id: 5,
          settingName: "sector",
          attributes: {
            title: "Setores",
            description:
              "Disponibiliza funcionalidades para pesquisar, incluir, alterar ou excluir os setores que podem ser referenciados nas demandas e tarefas.",
            required: true,
            badge: 10,
            totalTasks: 1,
            completeTasks: 0,
            icon: SetorIcon,
            path: "sectors",
            pageTitle: "Cadastro de Setores",
          },
        },
        {
          id: 6,
          settingName: "service-location",
          attributes: {
            title: "Locais de Serviços",
            description:
              "Permite realizar ações de pesquisar, incluir, alterar ou excluir os locais de serviço referenciados nas tarefas.",
            required: true,
            badge: 10,
            totalTasks: 1,
            completeTasks: 0,
            icon: LocalServicoIcon,
            path: "service-location",
            pageTitle: "Cadastro de Locais de Serviços",
          },
        },
        {
          id: 7,
          settingName: "services",
          attributes: {
            title: "Tipos de Serviços",
            description:
              "Permite realizar ações de pesquisar, incluir, alterar ou excluir os tipos de serviço referenciados nas demandas e tarefas.",
            required: null,
            badge: 10,
            totalTasks: 5,
            completeTasks: 3,
            icon: TipoServicoIcon,
            path: "services-types",
            pageTitle: "Cadastro de Tipos de Serviços",
          },
        },
        {
          id: 8,
          settingName: "services",
          attributes: {
            title: "Serviços",
            description:
              "Permite realizar ações de pesquisar, incluir, alterar ou excluir os serviços referenciados nas demandas e tarefas.",
            required: null,
            badge: 10,
            totalTasks: 5,
            completeTasks: 3,
            icon: ServicoIcon,
            path: "services",
            pageTitle: "Cadastro de Serviços",
          },
        },
        {
          id: 9,
          settingName: "clients",
          attributes: {
            title: "Perfis de Acesso",
            description:
              "Permite realizar ações de pesquisar, incluir, alterar ou excluir perfis que parametrizam diferentes permissões de acesso.",
            required: null,
            badge: 10,
            totalTasks: 5,
            completeTasks: 3,
            icon: TipoUsuarioIcon,
            path: "access-rules",
            pageTitle: "Cadastro de Perfis de Acesso",
          },
        },
        {
          id: 10,
          settingName: "clients",
          attributes: {
            title: "Usuários",
            description:
              "Permite alterar configurações dos usuarios dentro da aplicação.",
            required: null,
            badge: 10,
            totalTasks: 5,
            completeTasks: 3,
            icon: UsuarioIcon,
            path: "users",
            pageTitle: "Cadastro de Usuários",
          },
        },
        {
          id: 11,
          settingName: "clients",
          attributes: {
            title: "Equipes",
            description:
              "Permite realizar ações de pesquisar, incluir, alterar ou excluir as equipes responsáveis por executar as demandas e tarefas.",
            required: null,
            badge: 10,
            totalTasks: 5,
            completeTasks: 3,
            icon: EquipeIcon,
            path: "team",
            pageTitle: "Cadastro de Equipes",
          },
        },
        // {
        //   id: 12,
        //   settingName: "hollidays",
        //   attributes: {
        //     title: "Demandas",
        //     description:
        //       "Disponibiliza funcionalidades para pesquisar, incluir, alterar ou excluir demandas que precisam ser executadas.",
        //     required: null,
        //     badge: 10,
        //     totalTasks: 5,
        //     completeTasks: 3,
        //     icon: DemandaIcon,
        //     path: "demands",
        //     pageTitle: "Cadastro de Demandas",
        //   },
        // },
        {
          id: 14,
          settingName: "hollidays",
          attributes: {
            title: "Agendamento de Tarefas",
            description:
              "Disponibiliza funcionalidades para pesquisar, incluir, alterar ou excluir agendamentos de tarefa por dia(s) da semana ou data(s) específica(s).",
            required: null,
            badge: 10,
            totalTasks: 5,
            completeTasks: 3,
            icon: AgendamentoTarefasIcon,
            path: "task-scheduling",
            pageTitle: "Agendamento de Tarefas",
          },
        },
        {
          id: 17,
          settingName: "hollidays",
          attributes: {
            title: "Turnos",
            description:
              "Disponibiliza funcionalidades para pesquisar, incluir, alterar ou excluir turnos que serão utiliizados como filtro de demandas e tarefas.",
            required: null,
            badge: 10,
            totalTasks: 5,
            completeTasks: 3,
            icon: ShiftsIcon,
            path: "shifts",
            pageTitle: "Cadastro de Turnos",
          },
        },
        {
          id: 17,
          settingName: "hollidays",
          attributes: {
            title: "Unidade de Medida",
            description:
              "Permite realizar ações para pesquisar, incluir, alterar ou excluir as unidades de medida utilizadas nas medições de demandas e tarefas.",
            required: null,
            badge: 10,
            totalTasks: 5,
            completeTasks: 3,
            icon: UnidadeMedidaIcon,
            path: "unit-measure",
            pageTitle: "Cadastro de Unidade de Medida",
          },
        },
      ],
    },
    {
      group: "Importações",
      items: [
        {
          id: 15,
          settingName: "hollidays",
          attributes: {
            title: "Agendamento de Tarefas",
            description:
              "Disponibiliza funcionalidade para importar o agendamento de tarefas a partir do processamento de um arquivo.",
            required: null,
            certificateRequired: true,
            badge: 10,
            totalTasks: 5,
            completeTasks: 3,
            icon: ImportarAgendamentoTarefasIcon,
            path: "task-scheduling-import",
            pageTitle: "Importar Agendamento de tarefas",
          },
        },
        {
          id: 16,
          settingName: "access-roles",
          attributes: {
            title: "Formas",
            description:
              "Permite importar formas dos setores a partir do processamento de arquivos shp.",
            required: null,
            certificateRequired: true,
            badge: 10,
            totalTasks: 5,
            completeTasks: 3,
            icon: PoligonoETrechoIcon,
            path: "shapes",
            pageTitle: "Importar Formas",
          },
        },
        {
          id: 17,
          settingName: "demands-import",
          attributes: {
            title: "Importador de demandas",
            description:
              "Permite importar Demandas a partir do processamento de arquivos",
            required: null,
            certificateRequired: true,
            badge: 10,
            totalTasks: 5,
            completeTasks: 3,
            icon: DemandaIcon,
            path: "demands-import",
            import: true,
            pageTitle: "Importar Demandas",
          },
        },
      ],
    },
  ];

  return items;
};

export default MenuItemsHooks;
