import React, { useState } from "react";
import { ReactComponent as Line } from "../assets/images/line.svg";
import { ReactComponent as Polygon } from "../assets/images/polygon.svg";
import { ReactComponent as Point } from "../assets/images/point.svg";
import { ReactComponent as Hand } from "../assets/images/hand.svg";
import { ReactComponent as Undo } from "../assets/images/undo.svg";
import { ReactComponent as Erase } from "../assets/images/erase.svg";
import Tooltip from "../../tooltip/Tooltip";
import "./Tools.scss";

const drawTools = [
  {
    icon: <Hand />,
    name: "navigation",
    label: "Navegar",
  },
  {
    icon: <Line />,
    name: "polyline",
    label: "Linha",
  },
  {
    icon: <Polygon />,
    name: "polygon",
    label: "Polígono",
  },
  {
    icon: <Point />,
    name: "circleMarker",
    label: "Ponto",
  },
];

const editionTools = [
  {
    icon: <Undo />,
    name: "undo",
    action: "undo",
    label: "Desfazer",
  },
  {
    icon: <Erase />,
    name: "erase",
    action: "delete",
    label: "Apagar",
  },
];

function Tools({
  activeTool,
  changeActiveTool,
  handleToolAction,
  showTools,
  showActionButtons,
}) {
  const [showTooltip, setShowTooltip] = useState(null);

  function handleShowTooltip(item) {
    setShowTooltip(item);
  }

  function handleHideTooltip() {
    setShowTooltip(null);
  }

  function toolAction(action) {
    handleToolAction(action);
  }
  return (
    <div className="rc-tools-ui">
      <ul>
        {drawTools.map((m) => {
          const disabled = !showActionButtons?.remove && m.name === "remove";
          const isToShow = showTools.indexOf(m.name) > -1;
          if (isToShow) {
            return (
              <li
                onMouseEnter={() => handleShowTooltip(m.name)}
                onMouseLeave={handleHideTooltip}
                onClick={() => (disabled ? {} : changeActiveTool(m.name))}
                className={`${m.name === activeTool ? "active" : ""} ${
                  disabled ? "disabled" : ""
                }`}
                key={`tool_${m.name}`}
              >
                {m.icon}
                <Tooltip
                  show={showTooltip === m.name ? true : false}
                  label={m.label}
                  color="primary"
                />
              </li>
            );
          } else {
            return null;
          }
        })}
      </ul>
      <ul>
        {editionTools.map((m) => {
          const disabled =
            (showActionButtons &&
              !showActionButtons.undo &&
              m.name === "undo") ||
            (showActionButtons &&
              !showActionButtons.redo &&
              m.name === "redo") ||
            (showActionButtons &&
              !showActionButtons.erase &&
              m.name === "erase");
          return (
            <li
              onMouseEnter={() => handleShowTooltip(m.name)}
              onMouseLeave={handleHideTooltip}
              onClick={() => (disabled ? {} : toolAction(m.action))}
              className={`${m.name === activeTool ? "active" : ""} ${
                disabled ? "disabled" : ""
              }`}
              key={`tool_${m.name}`}
            >
              {m.icon}
              <Tooltip
                show={showTooltip === m.name ? true : false}
                label={m.label}
                color="danger"
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
}
export default Tools;
