import React, { useEffect, useRef } from "react";
import SearchBar from "../searchBar/SearchBar";
import Button from "../button/Button";

import "./Topbar.scss";

function Topbar({
  handleFilterData,
  handleAddRow,
  className,
  searchPlaceholder,
  editable,
  hideSearch,
  custom,
  fullWidth,
  permission,
}) {
  const addButtonRef = useRef(null);

  useEffect(() => {
    const button = addButtonRef.current;
    const handleButtonClick = () => button.blur();

    if (button) {
      button.addEventListener("click", handleButtonClick);
    }

    return () => {
      if (button) {
        button.removeEventListener("click", handleButtonClick);
      }
    };
  }, []);

  const renderSearchBar = () => (
    <div className="d-flex w-100">
      <SearchBar
        id="top-bar"
        onKeyUp={(e) => console.log(e.target)}
        placeholder={searchPlaceholder}
        className="my-50"
        handleTermFilter={handleFilterData}
        dynamic={true}
      />
      {permission && (
        <Button.Add
          id="add-header-button"
          dark
          md
          tooltip={!editable}
          legend="Selecione uma conta!"
          legendColor="warning"
          legendPosition="down"
          dataCy="open-add-modal"
          className="ml-1 my-50"
          onClick={handleAddRow}
          disabled={!editable}
          ref={addButtonRef}
        />
      )}
    </div>
  );

  const renderCustom = () => <div>{custom}</div>;

  return (
    <div className={`topbar ${className}`}>
      <div className={`${fullWidth ? "fullWidth" : "container"}`}>
        <div
          className={`d-flex ${
            hideSearch ? "flex-row align-items-center" : "flex-column"
          } px-sm-0 px-1`}
        >
          {!hideSearch ? renderSearchBar() : renderCustom()}
        </div>
      </div>
    </div>
  );
}
export default Topbar;
