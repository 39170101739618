import React from "react";

const Footer = ({
  intl,
  pagination,
  paginationDisabled,
  viewsRef,
  setSelectViews,
  selectViews,
  views,
  setPagination,
  paginationRef,
  setSelectPagination,
  selectPagination,
}) => {
  const renderViewsDropdown = () => (
    <div
      className="select-views"
      ref={viewsRef}
      onClick={() => setSelectViews(!selectViews)}
    >
      <div className="select-views__label">
        {intl.formatMessage(
          { id: `Exibir {show}` },
          {
            show: pagination.page_size,
          }
        )}
      </div>
      <div
        className={
          selectViews
            ? "select-views__dropdown select-views__dropdown--active"
            : "select-views__dropdown"
        }
      >
        <ul>
          {views.map((show, index) => (
            <li
              className={`${show === pagination.page_size ? "selected" : ""}`}
              key={index}
              onClick={() => setPagination({ ...pagination, page_size: show })}
            >
              {show}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );

  const renderPagination = () => (
    <div className="table-footer-pagination">
      <button
        className="navigation-button"
        onClick={() =>
          setPagination({
            ...pagination,
            page: pagination.page <= 1 ? 1 : pagination.page - 1,
          })
        }
      >
        {/*<ChevronLeft height={20} width={20} />*/}&lsaquo;
      </button>
      <div
        className="select-views"
        ref={paginationRef}
        onClick={() => setSelectPagination(!selectPagination)}
      >
        <div className="select-views__label">
          {intl.formatMessage(
            { id: `{pageNumber} de {pageTotal}` },
            {
              pageNumber: pagination.page,
              pageTotal: pagination.totalPages,
            }
          )}
        </div>
        <div
          className={
            selectPagination
              ? "select-views__dropdown select-views__dropdown--active"
              : "select-views__dropdown"
          }
        >
          <ul>
            {Array(pagination.totalPages)
              .fill(0)
              .map((_, i) => (
                <li
                  className={`${i + 1 === pagination.page ? "selected" : ""}`}
                  key={`selected-nav-page-${i + 1}`}
                  onClick={() =>
                    setPagination({
                      ...pagination,
                      page: i + 1,
                    })
                  }
                >
                  {intl.formatMessage(
                    { id: `{pageNumber} de {pageTotal}` },
                    {
                      pageNumber: i + 1,
                      pageTotal: pagination.totalPages,
                    }
                  )}
                </li>
              ))}
          </ul>
        </div>
      </div>
      <button
        className="navigation-button"
        onClick={() =>
          setPagination({
            ...pagination,
            page:
              pagination.page >= pagination.totalPages
                ? pagination.totalPages
                : pagination.page + 1,
          })
        }
      >
        &rsaquo;
      </button>
    </div>
  );

  return (
    <div className="table-footer-wrapper">
      <div className="table-footer-pagination">
        <div className="pagination-counter">
          {intl.formatMessage(
            { id: `{toRow} a {fromRow} de {totalRows}` },
            {
              toRow: pagination.toRow,
              fromRow: paginationDisabled ? pagination.totalRows : pagination.fromRow > pagination.totalRows ? pagination.totalRows : pagination.fromRow,
              totalRows: pagination.totalRows,
            }
          )}
        </div>
        {!paginationDisabled && renderViewsDropdown()}
      </div>
      {pagination.totalPages > 1 && !paginationDisabled && renderPagination()}
    </div>
  );
};

export default Footer;
