import React from 'react'
import './divider.scss'

const Divider = ({ className, children }) => {
    return (
        <div className={`ui-division-title ${className ? className : ''} ${!children ? 'pure-line' : ''}`}>
            <span>{children}</span>
        </div>
    )
}

export default Divider
