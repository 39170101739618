import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import * as Icon from "react-feather";
import "./filter-header.scss";
import { useRef } from "react";
import Dropdown from "../dropdown/Dropdown";
import CloseButton from "../closeButton/CloseButton";
const FilterHeader = ({ id, title, listItems, selectedItem, onSelect }) => {
  const intl = useIntl();
  const [show, setShow] = useState(false);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  const handleSelect = (e) => {
    setShow(false);
    onSelect(e);
  };

  const handleClickPagination = (e) => {
    if (
      dropdownRef.current &&
      buttonRef.current &&
      !dropdownRef.current.contains(e.target) &&
      !buttonRef.current.contains(e.target)
    )
      setShow(false);
  };
  useEffect(() => {
    document.addEventListener("click", handleClickPagination);
    return () => {
      document.removeEventListener("click", handleClickPagination);
    };
  });

  return (
    <div className="filter-header">
      <div className="filter-header__title">
        <div className="label">
          {title && intl.formatMessage({ id: title })}
        </div>
        {selectedItem && (
          <CloseButton
            color="gray"
            size="sm"
            onClick={() => handleSelect(null)}
          />
        )}
        <Dropdown
          id={id}
          label={
            <>
              <div className="filter-header__icon">
                <Icon.Filter
                  size={14}
                  color={selectedItem ? "#009ACA" : "#848484"}
                />
              </div>
            </>
          }
          closeOnSelect
          mw={150}
          content={
            <div>
              {listItems.map((item, i) => (
                <div
                  key={`filter_header_${i}`}
                  className={`filter-header__item ${
                    item.value === selectedItem?.value ? "selected" : ""
                  }`}
                  onClick={() => handleSelect(item)}
                >
                  {item.label}
                </div>
              ))}
            </div>
          }
        />
      </div>

      <div
        id={`filter-header__dropdown-${id}`}
        ref={dropdownRef}
        className={`filter-header__dropdown ${show ? "show" : "hide"}`}
      >
        {listItems.map((item, i) => (
          <div
            key={`filter_header_${i}`}
            className={`filter-header__item ${
              item.value === selectedItem?.value ? "selected" : ""
            }`}
            onClick={() => handleSelect(item)}
          >
            {item.label}
          </div>
        ))}
      </div>
    </div>
  );
};
export default FilterHeader;
