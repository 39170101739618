import React, { useRef, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import "./TableFilter.scss";

import moment from "moment-timezone";
import * as Icon from "react-feather";
import CloseButton from "../../components/closeButton/CloseButton";
import {
  Button,
  Selectbox,
  SearchBar,
  Datetime,
  Checkbox,
} from "../../components";

const TableFilters = ({
  dateTimeValue,
  dateTimeChange,
  shifts,
  shiftsValue,
  shiftsChange,
  onSearch,
  disabledInputs,
  fullScreen,
  showColumns,
  data,
  buttonColumnsRef,
  onExportPDF,
  onExportCSV,
  loading,
  onChangeInitalDate,
  initialDate,
  onChangeFinalDate,
  finalDate,
  customActions,
  onChangeType,
  filterTypes = [],
  noData,
  noShowDownloads,
}) => {

  const intl = useIntl();
  const toggleShowDownloadsFileRef = useRef(null);
  const dropdownShowDownloadsFileRef = useRef(null);
  const today = moment().format("YYYY-MM-DD");

  const [showDownloadsFile, setShowDownloadsFile] = useState(false);

  const handleClickSetShowDownloadsFile = (e) => {
    if (
      toggleShowDownloadsFileRef.current &&
      dropdownShowDownloadsFileRef.current &&
      !toggleShowDownloadsFileRef.current.contains(e.target) &&
      !dropdownShowDownloadsFileRef.current.contains(e.target)
    )
      setShowDownloadsFile(false);
  };

  useEffect(() => {
    document.addEventListener("click", handleClickSetShowDownloadsFile);
    return () => {
      document.removeEventListener("click", handleClickSetShowDownloadsFile);
    };
  });

  const renderDateFilter = () => (
    <Datetime
      id="filterDate"
      autoComplete="off"
      className={`pr-50`}
      placeholder={intl.formatMessage({ id: "Data de referência" })}
      value={
        dateTimeValue && dateTimeValue.trim().length > 0
          ? moment(dateTimeValue).format("YYYY-MM-DD")
          : ""
      }
      name="date"
      closeOnSelect={true}
      onChange={(el) => dateTimeChange(el)}
      size="sm"
      disabled={disabledInputs}
    />
  );

  const renderDateRangeFilter = () => (
    <>
      <Datetime
        id="initialDate"
        autoComplete="off"
        className={`pr-50 pl-50`}
        placeholder={intl.formatMessage({ id: "Data Inicial" })}
        maxDate={today}
        closeOnSelect={true}
        value={
          initialDate && initialDate.trim().length > 0
            ? moment(initialDate).format("YYYY-MM-DD")
            : ""
        }
        onChange={(el) => onChangeInitalDate(el)}
        size="sm"
        disabled={disabledInputs}
      />
      <Datetime
        id="finalDate"
        autoComplete="off"
        className={`pr-50 mr-150`}
        placeholder={intl.formatMessage({ id: "Data Final" })}
        closeOnSelect={true}
        value={
          finalDate && finalDate.trim().length > 0
            ? moment(finalDate).format("YYYY-MM-DD")
            : ""
        }
        onChange={(el) => onChangeFinalDate(el)}
        size="sm"
        disabled={disabledInputs}
        minDate={initialDate || today}
        maxDate={today}
      />
    </>
  );

  const renderTypesFilter = () =>
    filterTypes.map((filterType) => (
      <div key={filterType.id} className="filterTypeItem">
        <Checkbox
          name={filterType.name}
          label={filterType.name}
          checked={filterType.checked}
          id={filterType.id}
          onChange={onChangeType}
        />
      </div>
    ));

  return (
    <div className="table-filters w-100" style={{ paddingTop: 10 }}>
      <div className="d-flex">
        {onChangeInitalDate && onChangeFinalDate
          ? renderDateRangeFilter()
          : noData
          ? null
          : renderDateFilter()}

        {
          shiftsValue ? (
            <Selectbox
              id="filterTime"
              className={`mr-50`}
              placeholder={intl.formatMessage({ id: "Turnos" })}
              name="shifts"
              value={shiftsValue}
              onChange={(e) => shiftsChange(e)}
              onSearch={() => {}}
              options={shifts}
              disabledClearInput={true}
              disabled={disabledInputs}
              size="sm"
            />
          ) : null
          // <div id="filterTime" className={`mr-50`}></div>
        }
        {onChangeType && renderTypesFilter()}
      </div>
      <div className="col-1 col-md-6 d-flex align-items-center pt-50 px-0 p-md-0">
        <SearchBar //col-12 col-md-6 d-flex align-items-center pt-50 px-0 p-md-0
          id="top-bar"
          placeholder="Pesquisar"
          handleTermFilter={onSearch}
          dynamic={true}
          className={`"mr-50 ${fullScreen ? "hidden" : ""}`}
        />
        {customActions && customActions}
        {!noShowDownloads ? (
          <div className="downloads-file">
            <div ref={toggleShowDownloadsFileRef}>
              <Button.SpanishGray
                tooltip
                legendPosition="left"
                legend="Downloads"
                legendColor="primary"
                dataCy="downloads-button"
                onClick={() => setShowDownloadsFile(!showDownloadsFile)}
                id="columns"
                size="sm"
                className={`ml-50 ${fullScreen ? "hidden" : ""}`}
              >
                <Icon.Download size={30} className="primary" />
              </Button.SpanishGray>
            </div>
            {showDownloadsFile ? (
              <div
                ref={dropdownShowDownloadsFileRef}
                className={`downloads-file__dropdown ${
                  showDownloadsFile ? "show" : "hide"
                }`}
              >
                <Button.Primary
                  id="export-pdf"
                  disabled={!data.rows.filter((item) => item.selected).length}
                  onClick={() => onExportPDF()}
                  size="sm"
                  className="mr-50"
                  dark
                  loading={loading.pdf}
                >
                  PDF
                </Button.Primary>
                <Button.Primary
                  id="export-excel"
                  disabled={!data.rows.length}
                  onClick={() => onExportCSV()}
                  className="mr-50"
                  size="sm"
                  dark
                  loading={loading.xlsx}
                >
                  Excel
                </Button.Primary>
                <CloseButton
                  size="sm"
                  onClick={() => setShowDownloadsFile(false)}
                />
              </div>
            ) : null}
          </div>
        ) : null}
        <div ref={buttonColumnsRef}>
          <Button.SpanishGray
            tooltip
            legendPosition="left"
            legend="Mostrar Colunas"
            legendColor="primary"
            dataCy="columns-button"
            onClick={() => showColumns(true)}
            id="columns"
            size="sm"
            className={`ml-50 ${fullScreen ? "hidden" : ""}`}
          >
            <Icon.Columns size={30} className="primary" />
          </Button.SpanishGray>
        </div>
      </div>
    </div>
  );
};

export default TableFilters;
