import React from "react";
import TableCell from "./TableCell";
import AdminActions from "./AdminActions";
import Checkbox from "../../checkbox/Checkbox";

const getRowClassName = (row, highlightRow) => {
  const isRedLight = row?.redLight;

  if (highlightRow.rowId === row.rowId) {
    return highlightRow.color ? highlightRow.color : "primary";
  }

  return isRedLight ? "red-highlight" : "";
};

const Row = ({
  row,
  index,
  highlightRow,
  highlightRowRef,
  handleRowClick,
  selectable,
  handleRowAction,
  selectableDisabled,
  select,
  columns,
  customBody,
  administrator,
  editable,
  controls,
  setControls,
  features,
}) => {
  return (
    <tr
      ref={highlightRow.rowId === row.rowId ? highlightRowRef : null}
      key={index}
      className={getRowClassName(row, highlightRow)}
      onClick={(e) => handleRowClick(e, row)}
    >
      {selectable ? (
        <td width="1">
          <Checkbox
            className={`checkbox-${row.color}`}
            id={`checkbox-${index}`}
            name={`checkbox-${index}`}
            checked={row.selected ? true : false}
            value={select.includes(row.rowId)}
            onChange={() => handleRowAction("single-select", row)}
            disabled={selectableDisabled || row.disabled}
          />
        </td>
      ) : null}

      {columns
        .filter((f) => !f.hidden)
        .map((column, columnIndex) => (
          <TableCell
            key={columnIndex}
            column={column}
            row={row}
            customBody={customBody}
          />
        ))}

      {administrator ? (
        <AdminActions
          width="10"
          row={row}
          editable={editable}
          controls={controls}
          setControls={setControls}
          features={features}
          handleRowAction={handleRowAction}
        />
      ) : null}
    </tr>
  );
};

export default Row;
