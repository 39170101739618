import React from 'react'
import "./Tab.scss"

function Tab({ options, color, title, onClick, className, id, errorMessage, disabled }) {
    return (
        <div id={id} className={`ui-tab ui-tab-${color} ${className ? className : ''} ${errorMessage && errorMessage.error ? errorMessage.color : '' }`}>
            <div className="ui-tab-label">
                {title}
            </div>
            <ul>
                {options && options.map(option =>
                    <li
                        key={`${id}-${option.key}`}
                        className={`${option.selected ? 'selected' : ''} ${option.disabled || disabled ? 'disabled' : ''}`}
                        onClick={() => option.disabled ? {} : onClick(option)}><span>{option.label}</span></li>
                )}
            </ul>
            <div className="fields-legends">
                <div className="fields-legends-descriptions">
                    {errorMessage && errorMessage.error && <small className={errorMessage.color}>{errorMessage.error}</small>}
                </div>
            </div>
        </div>
    )
}
export default Tab
