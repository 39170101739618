import React, { useEffect } from "react";
import { Spinner } from "reactstrap";
import { ToggleFullScreen, Contest } from "../../components";
import "./map-area.scss";
import useElementVisibility from "../../hooks/useElementVisibily";

const MapArea = ({
  id,
  loadingMap,
  loadingShapes,
  toggleFullScreen,
  fullScreen,
  toggleContest,
  contest,
  changeConstest,
  changeFullScreen,
  height,
  latlng,
  minHeight,
  loadMap,
  hide,
  className,
  onVisibilityChange,
}) => {
  const [elementRef, isVisible] = useElementVisibility();

  useEffect(() => {
    return () => {
      console.log("cleaned up");
    };
  }, [id]);

  useEffect(() => {
    if (onVisibilityChange) {
      onVisibilityChange(isVisible);
    }
    if (isVisible && loadMap) {
      loadMap();
    }
  }, [isVisible]);

  if (hide) return null;

  return (
    <div
      ref={elementRef}
      className="map-area"
      style={{
        height: height ? height : "auto",
        minHeight: minHeight ? minHeight : 0,
        backgroundColor: loadingMap ? "#f5f5f5" : "transparent",
      }}
    >
      <div id={id} className={`map ${className}`}></div>
      {toggleFullScreen && (
        <ToggleFullScreen
          fullScreen={fullScreen}
          changeFullScreen={changeFullScreen}
        />
      )}
      {toggleContest && (
        <Contest contest={contest} onClick={() => changeConstest()} />
      )}
      {latlng && latlng.lat && latlng.lng && (
        <div className="map-coordinates">
          {latlng.lat.toFixed(6)}, {latlng.lng.toFixed(6)}
        </div>
      )}
      {loadingShapes ||
        (loadingMap && (
          <div className="loading-shapes-spinner">
            <div>Carregando...</div>
            <Spinner size="sm" color="primary" />
          </div>
        ))}
    </div>
  );
};

export default MapArea;
