import axios from 'axios'
const client = (receivedToken) => {
    const USUARIO = JSON.parse(localStorage.getItem('userInfo')).nome
    const EMPRESA = JSON.parse(localStorage.getItem('branch')).nome
    console.log("USUARIO", USUARIO)
    const token = btoa(`@inlog.geocoding;${USUARIO};${EMPRESA}`)

    const defaultOptions = {
        headers: {
            Authorization: receivedToken ? `${receivedToken}` : token ? `${token}` : '',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
            'Access-Control-Allow-Headers': 'Origin, X-Requested-With, X-Auth-Token, Content-Type, Accept'
        }
    }
    return {
        get: (url, options = {}) => axios.get(url, { ...defaultOptions, ...options })
    }
}

const api = {
    coordenadas: (params) => {
        return new Promise((resolve, reject) => {
            return client()
                .get(`https://geocode.inlog.com.br/api/Map/Google/Geocode/${params.endereco}`)
                .then(res => resolve(res.data))
                .catch(error => reject(error))
        })
    },
    geocodeReverso: (params) => {
        return new Promise((resolve, reject) => {
            return client()
                .get(`https://geocode.inlog.com.br/api/Map/Google/GeocodeReverso/${params.latitude}, ${params.longitude}`)
                .then(res => resolve(res.data))
                .catch(error => reject(error))
        })
    }
}

export default api