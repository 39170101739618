import React, { useEffect } from "react";
import { HubConnectionBuilder, HttpTransportType } from "@microsoft/signalr";
import { uuidv4 } from "./utils/uuidGenerator";

function MainSocket({ children, event }) {
  useEffect(() => {
    const options = {
      transport: HttpTransportType.WebSockets,
      skipNegotiation: true,
      connectionId: uuidv4(),
    };

    const connection = new HubConnectionBuilder()
      .withUrl(
        `${process.env.REACT_APP_FISCOLFRONT_API_URL}/notificacao`,
        options
      )
      .build();
    connection
      .start()
      .then((res) => {
        console.log(res);
        connection.on("conclusao-importacao-formas", (message) => {
          event(JSON.parse(message));
        });
        connection.on("inicio-importacao-formas", (message) => {
          event(JSON.parse(message));
        });
      })
      .catch((err) => console.log(err));
  }, []);

  return <div>{children}</div>;
}

export default MainSocket;
