import React from "react"
import "./Calendar.scss"
import { injectIntl } from "react-intl";
import Button from "../../../button/Button";
import moment from 'moment'

const daysOfWeek = [
    { isoWeekDay: 1, name: 'Segunda-feira' },
    { isoWeekDay: 2, name: 'Terça-feira' },
    { isoWeekDay: 3, name: 'Quarta-feira' },
    { isoWeekDay: 4, name: 'Quinta-feira' },
    { isoWeekDay: 5, name: 'Sexta-feira' },
    { isoWeekDay: 6, name: 'Sábado' },
    { isoWeekDay: 7, name: 'Domingo' },
]

const months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']

class Calendar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            date: moment().format(),
            start: null,
            end: null,
            hour: moment().format('HH'),
            minute: moment().format('mm'),
            time: moment().format('YYYY-MM-DD HH:mm'),
            error: false,
            activeField: null
        }
    }

    componentDidUpdate = (prevProps) => {
        if ((this.props.isVisible !== prevProps.isVisible && this.props.isVisible) || this.props.defaultRange !== prevProps.defaultRange) {
            const { start, end } = this.props.defaultRange
            this.setState({
                start: start ? moment(start).format('YYYY-MM-DD') : '',
                end: end ? moment(end).format('YYYY-MM-DD') : '',
                date: start ? moment(start).endOf('month') : moment().endOf('month'),
                hour: this.props.time && start ? moment(start).format('HH') : moment().format('HH'),
                minute: this.props.time && start ? moment(start).format('mm') : moment().format('mm')
            })
        }
    }

    monthDays = () => {
        let days = []
        let n = 0
        while (n < 3) {
            days.push(n)
            n++;
        }
        return n
    }
    navigatePrevMonth = () => {
        this.setState(prevState => ({
            date: moment(prevState.date).add(-1, 'month').startOf('month')
        }))
    }

    navigateNextMonth = () => {
        this.setState(prevState => ({
            date: moment(prevState.date).add(1, 'month').endOf('month')
        }))
    }

    navigatePresentMonth = () => {
        this.setState({
            date: moment().format()
        })
    }

    handleSelectDate = (selected) => {

        const { start, end } = this.state
        const { range, time, closeOnSelect } = this.props
        const startDate = !start ? selected : start && end ? selected : start
        const endDate = start && end ? null : start ? selected : null
        const inverted = moment(endDate).diff(moment(startDate), 'days')
        if (range) {
            this.setState(() => ({
                start: inverted < 0 ? endDate : startDate,
                end: inverted < 0 ? startDate : endDate,
                error: null
            }), () => {
                if (this.state.start && this.state.end) {

                    if (closeOnSelect) this.props.selectedDate([moment(this.state.start).format('YYYY-MM-DD'), moment(this.state.end).format('YYYY-MM-DD')])
                }
            })
        } else if (time) {
            this.setState(() => ({
                start: selected,
                end: null,
                error: null
            }), () => {
                const hour = this.state.hour
                const minute = this.state.minute
                const datetime = `${(moment(this.state.start).format('YYYY-MM-DD'))} ${hour}:${minute}:00`
                if (closeOnSelect) this.props.selectedDate(moment(datetime).format('YYYY-MM-DD HH:mm:ss'))
            })
        } else {
            this.setState(() => ({
                start: selected,
                end: null,
                error: null
            }), () => {
                if (closeOnSelect) this.props.selectedDate(moment(this.state.start).format('YYYY-MM-DD'))
            })
        }
    }

    validate = () => {
        let err
        const { range } = this.props
        const { start, end } = this.state
        if (range && (!start || !end)) err = "Selecione a data inicial e final";
        else if (!range && !start) err = "Selecione uma data";

        if (err) {
            return err
        } else {
            return null
        }
    }

    handleCalendarAccept = () => {

        const errorValidate = this.validate()
        const { range, time, selectedDate, setShowDropdown } = this.props
        if (errorValidate) {
            this.setState({ error: errorValidate })
        } else {
            if (range) {
                selectedDate([moment(this.state.start).format('YYYY-MM-DD'), moment(this.state.end).format('YYYY-MM-DD')])
                setShowDropdown(null)
            } else if (time) {
                const hour = this.state.hour
                const minute = this.state.minute
                const datetime = `${(moment(this.state.start).format('YYYY-MM-DD'))} ${hour}:${minute}:00`
                selectedDate(moment(datetime).format('YYYY-MM-DD HH:mm:ss'))
                setShowDropdown(null)
            } else {
                selectedDate(moment(this.state.start).format('YYYY-MM-DD'))
                setShowDropdown(null)
            }
        }
    }


    renderCalendar = () => {
        const { date, start, end } = this.state
        const { minDate, maxDate } = this.props
        const fisrtDay = moment(date).startOf('month').startOf('isoWeek').format()
        const lastDay = moment(date).endOf('month').endOf('isoWeek').format()
        const disablePrev = minDate && moment(fisrtDay).diff(minDate, 'days') <= 0
        const disableNext = maxDate && moment(maxDate).diff(lastDay, 'days') <= 0
        const todayActive = moment().isBetween(minDate ? minDate : moment().add(-1, 'day'), maxDate ? maxDate : moment().add(1, 'day'));
        const monthDays = [...Array(Number(moment(lastDay).diff(fisrtDay, 'days') + 1)).keys()].map(d => {
            const day = moment(fisrtDay).add(d, 'days')
            return {
                day: day.format(),
                isoWeekDay: moment(day).isoWeekday()
            }
        })

        return (
            <div className="calendar">
                <div className="d-flex calendar-header justify-content-between align-items-center">
                    <div className="calendar-month">{months[Number(moment(date).month())]} {moment(date).year()}</div>
                    <div className="d-flex align-items-center justify-content-center">
                        <div className={`calendar-prev ${disablePrev ? 'disabled' : ''}`} onClick={disablePrev ? {} : this.navigatePrevMonth}><span className="nav-arrow">&lsaquo;</span></div>
                        {todayActive && <div className="calendar-today" onClick={this.navigatePresentMonth}>Hoje</div>}
                        <div className={`calendar-next ${disableNext ? 'disabled' : ''}`} onClick={disableNext ? {} : this.navigateNextMonth}><span className="nav-arrow">&rsaquo;</span></div>
                    </div>
                </div>
                <div className="d-flex justify-content-between flex-grow-1 h-100">
                    {daysOfWeek.map((m, i) => {
                        const calendarline = monthDays.filter(f => f.isoWeekDay === m.isoWeekDay)
                        const calendarAditionalLine = calendarline.length === 5 ? calendarline[4] : null
                        const calendarAditionalLineWeekend = calendarAditionalLine ? moment(calendarAditionalLine).isoWeekday() : null
                        const calendarAditionalLineDay = calendarAditionalLine ? moment(calendarAditionalLine.day).add(1, 'week').format() : null
                        const calendarAditionalLineInterval = calendarAditionalLine ? moment(calendarAditionalLineDay).diff(moment(start), 'days') > 0 && moment(calendarAditionalLineDay).diff(moment(end), 'days') < 0 : null
                        const calendarlineSelected = moment(start).format('YYYY-MM-DD') === moment(calendarAditionalLineDay).format('YYYY-MM-DD') || moment(end).format('YYYY-MM-DD') === moment(calendarAditionalLineDay).format('YYYY-MM-DD')

                        return (
                            <div key={`days_of_week_${i}`} className={`d-flex flex-column justify-content-between week-column ${m.isoWeekDay === 6 || m.isoWeekDay === 7 ? 'weekend' : ''} ${m.isoWeekDay === 7 ? 'sunday' : ''}`}>
                                <div className={`week-name`}>{m.name.substring(0, 2)}</div>
                                <div className="d-flex flex-column week-days">
                                    {calendarline.map((c, idx) => {
                                        const notSelectable = minDate && moment(c.day).diff(minDate, 'days') < 0 || maxDate && moment(maxDate).diff(c.day, 'days') < 0
                                        const interval = moment(c.day).diff(moment(start), 'days') > 0 && moment(c.day).diff(moment(end), 'days') < 0
                                        const sameMonth = moment(date).format('MM') === moment(c.day).format('MM')
                                        const today = moment().format('YYYY-MM-DD') === moment(c.day).format('YYYY-MM-DD')
                                        const selected = moment(start).format('YYYY-MM-DD') === moment(c.day).format('YYYY-MM-DD') || moment(end).format('YYYY-MM-DD') === moment(c.day).format('YYYY-MM-DD')
                                        return (
                                                <span
                                                    key={`calendar_line_${i}_${idx}`}
                                                    className={`week-day ${c.isoWeekDay === 6 || c.isoWeekDay === 7 ? 'weekend' : ''}
                                                                    ${c.isoWeekDay === 7 ? 'sunday' : ''} ${sameMonth ? 'active' : 'out-of-month'}
                                                                    ${today ? 'today' : ''} ${selected ? 'selected' : ''}
                                                                    ${interval ? 'interval' : ''} ${c.isoWeekDay === 7 ? 'no-right' : ''}
                                                                    ${c.isoWeekDay === 1 ? 'no-left' : ''}
                                                                    ${notSelectable ? 'disabled' : ''}`}
                                                    onClick={() => notSelectable ? {} : this.handleSelectDate(c.day)}>
                                                    {moment(c.day).format('DD')}
                                                    {today && <div className="today-marker"></div>}
                                                </span>

                                        )
                                    })}
                                    {calendarAditionalLine &&
                                        <span
                                            key={`calendar_line_`}
                                            className={`week-day out-of-month ${calendarAditionalLineWeekend === 6 || calendarAditionalLineWeekend === 7 ? 'weekend' : ''}
                                                ${calendarAditionalLineWeekend === 7 ? 'sunday' : ''}
                                                ${calendarAditionalLineInterval ? 'interval' : ''}
                                                ${calendarlineSelected ? 'selected' : ''}
                                                ${calendarAditionalLine.isoWeekDay === 7 ? 'no-right' : ''} `}
                                            onClick={() => this.handleSelectDate(moment(calendarAditionalLine.day).add(1, 'week').format())}>
                                            {moment(calendarAditionalLineDay).format('DD')}
                                        </span>
                                    }
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>

        )
    }
    formatHourTo2Digits = (i) => {
        return parseInt(i) < 10 ? `${0}${parseInt(i)}` : i
    }
    renderTimeSelector = () => {
        const { hour, minute, activeField } = this.state
        return <div className="time-selector">
            <div className="hour-selector">
                <div className={`time-list-container ${activeField === 'hour' ? 'show' : ''}`}>
                    <ul className="time-list next" style={{ marginBottom: - ((32 * (parseInt(hour) + 1)) + 132) }}>
                        {[...Array(24)].map((_, i) =>
                            <li
                                key={`hour_${(i + 1)}`}
                                className={`time-item ${i == hour ? 'hidden' : ''}`}
                                onClick={() => this.handleSelectHour(i)}>
                                {this.formatHourTo2Digits(i)}
                            </li>
                        ).reverse()}
                    </ul>
                </div>
                <input
                    name="hour"
                    className="hour-input"
                    type="number"
                    value={hour}
                    onFocus={this.handleOnFocus}
                    onBlur={this.handleOnBur}
                    onChange={this.onHourChange}
                />
            </div>
            <div className="time-divider"></div>
            <div className="minute-selector">
                <div className={`time-list-container ${activeField === 'minute' ? 'show' : ''}`}>
                    <ul className="time-list next" style={{ marginBottom: - ((32 * (parseInt(minute) + 1)) + 132) }}>
                        {[...Array(60)].map((_, i) =>
                            <li
                                key={`hour_${(i + 1)}`}
                                className={`time-item ${i == minute ? 'hidden' : ''}`}
                                onClick={() => this.handleSelectMinute(i)}>
                                {this.formatHourTo2Digits(i)}
                            </li>
                        ).reverse()}
                    </ul>
                </div>
                <input
                    name="minute"
                    className="minute-input"
                    type="number"
                    value={minute}
                    onFocus={this.handleOnFocus}
                    onBlur={this.handleOnBur}
                    onChange={this.onMinuteChange}
                />
            </div>
        </div>
    }
    onHourChange = e => {
        const { value } = e.target
        this.setState({
            hour: (value > 23 || value < 0) ? this.state.hour : value
        })
    }
    handleSelectHour = hour => {
        this.setState({
            hour: this.formatHourTo2Digits(hour)
        })
    }
    onMinuteChange = e => {
        const { value } = e.target
        this.setState({
            minute: (value > 59 || value < 0) ? this.state.minute : value
        })
    }
    handleSelectMinute = minute => {
        this.setState({
            minute: this.formatHourTo2Digits(minute)
        })
    }
    handleOnFocus = e => {
        this.setState({
            activeField: e.target.name
        })
    }

    handleOnBur = e => {
        const { name, value } = e.target
        this.setState({
            activeField: null,
            hour: name === 'hour' && (parseInt(value) < 0 || parseInt(value) > 23) ? '00' : this.formatHourTo2Digits(this.state.hour),
            minute: name === 'minute' && (parseInt(value) < 0 || parseInt(value) > 59) ? '00' : this.formatHourTo2Digits(this.state.minute)
        })
    }

    render() {
        const { error, start, end } = this.state
        const { range, time } = this.props

        return (
            <div className="datetime-container">
                {this.renderCalendar()}
                {range && time ?
                    <div className="text-danger text-center">Range date and time no <br />supported together</div> :
                    range ?
                        <div className="calendar-footer">
                            <Button.X size="sm" onClick={this.props.handleCalendarCancel} />
                            <div className="calendar-range-date d-flex flex-grow-1 align-items-center justify-content-center">
                                {`  ${start ? moment(start).format('DD/MM/YY') : ''}
                                ${start && end ? ' a ' : ''}
                                ${end ? moment(end).format('DD/MM/YY') : ''}
                                ${!start && !end ? 'Selecione as datas' : ''}
                            `}
                            </div>
                            <Button.Agree size="sm" disabled={!start || !end} className="calendar-buttons" onClick={this.handleCalendarAccept} />
                        </div> :
                        time &&
                        <div className="calendar-footer">
                            <Button.X size="sm" onClick={this.props.handleCalendarCancel} />
                            {this.renderTimeSelector()}
                            <Button.Agree size="sm" className="calendar-buttons" onClick={this.handleCalendarAccept} />
                        </div>

                }
                {error && <div className="calendar-error text-danger">{error}</div>}

            </div>
        )
    }
}

export default injectIntl(Calendar)
