import React, { useState, useRef, useEffect } from 'react'
import { useIntl } from "react-intl"
import {
    TextInput,
    ScrollBar,
    Checkbox,
    EmptyState
} from '..'

import './select-box.scss'

const Selectbox = ({
    id,
    placeholder,
    className,
    name,
    value,
    multiSelect,
    errorMessage,
    fieldDescription,
    fieldIcon,
    options,
    onChange,
    onSearch,
    onPagination,
    loading,
    disabledClearInput,
    disabled,
    size,
    style
}) => {
    const intl = useIntl()
    const searchRef = useRef(null)
    const dropdownRef = useRef(null)
    const selectRef = useRef(null)
    const selectedRef = useRef(null)

    const [search, setSearch] = useState(null)
    const [, setSearchFocused] = useState(false)
    const [showDropdown, setShowDropdown] = useState(false)
    const [positions, setPositions] = useState({
        width: null,
        height: null,
        x: null,
        y: null,
        top: 0
    })

    const onFocus = (e) => {
        const activeElement = e.target.id === `select-input-field-${id}`
        showDrop(e, activeElement)
        setSearchFocused(true)
    };

    const onFocusOut = () => {
        // const clickedElement = classListToArray(e.target.classList)
        // const activeElement = e.target.id === `select-input-field-${id}`
        // if(activeElement){
        //     setTimeout(() => {
        //         showDrop(e, !activeElement)
        //     }, 200)
        // }
    }

    const onClick = (e) => {
        const activeElement = document.activeElement.id === `select-input-field-${id}`
        if (activeElement && !showDropdown) {
            showDrop(e, activeElement)
        }
        //setSearchFocused(true)
    };



    const classListToArray = (classList) => {
        let classes = []
        for (let cssClass of classList) {
            classes.push(cssClass);
        }
        return classes
    }

    const clickOutDropdown = e => {
        const clickedElement = classListToArray(e.target.classList)
        const classNames = ['checkbox-input-field', 'rc-checkbox-ui', 'ui-select-box-dropdown__item', 'dropdown-option', 'ui-select-box-dropdown__collapse', 'ui-select-box-dropdown__action']
        const clkInside = typeof clickedElement.find(f => classNames.includes(f)) !== 'undefined'
        const activeElement = document.activeElement.id === `select-input-field-${id}`
        if (showDropdown && !clkInside && !activeElement) {
            setShowDropdown(false)
        }
    };

    useEffect(() => {
        document.addEventListener("click", clickOutDropdown);
        document.addEventListener("focusout", onFocusOut);
        return () => {
            document.removeEventListener("click", clickOutDropdown);
            document.removeEventListener("focusout", onFocusOut);
        };
    })

    const handleClearInput = () => {
        onChange({ name, option: null })
        setSearch('')
        searchRef.current.setFocus()
    }

    const handleOnKeyPress = (e) => {
        switch (e.keyCode) {
            case 40:
                !multiSelect && options.map((option, index) => {
                    if (!value && index === 0) {
                        onChange({ name, option })
                        return
                    } else if (index === options.findIndex(item => item.value === value?.value) + 1) {
                        onChange({ name, option })
                        return
                    } else if (options.findIndex(item => item.value === value?.value) === options.length - 1) {
                        onChange({ name, option: options[0] })
                        return
                    }
                })
                return
            case 38:
                !multiSelect && options.map((option, index) => {
                    if (!value && index === options.length - 1) {
                        onChange({ name, option })
                        return
                    } else if (index === options.findIndex(item => item.value === value?.value) - 1) {
                        onChange({ name, option })
                        return
                    } else if (index === 0) {
                        onChange({ name, option: options[options.length - 1] })
                        return
                    }
                })
                return
            case 13:
                setShowDropdown(false)
                return
            case 9:
                handleHideInactiveFieldDropDown(e)
                return
        }
    }

    useEffect(() => {
        selectedRef.current && selectedRef.current.scrollIntoView({ block: 'center', behavior: 'smooth' })
    }, [value])

    useEffect(() => {
        if (search !== null) {
            const delaySearch = setTimeout(() => {
                onSearch && onSearch(search)
            }, 500)
            return () => clearTimeout(delaySearch)
        }
    }, [search])

    useEffect(() => {
        document.addEventListener("scroll", handleScroll, true);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    function handleScroll(e) {
        const scrolllingOptons = e?.target?.classList?.contains('ui-scroll-bar')
        if (!scrolllingOptons) {
            setShowDropdown(false)
        }
    }

    function handleHideInactiveFieldDropDown() {
        const activeElement = document.activeElement
        if (id === showDropdown) {
            console.warn(`hide: ${id}`)
            setShowDropdown(false)
        } else {
            console.warn(`show: ${id}`)
            setShowDropdown(activeElement.id)
        }
    }

    function showDrop(e) {
        updateDropdownPosition(e)
        setShowDropdown(showDropdown ? false : id)
    }

    function updateDropdownPosition() {
        const windowHeight = window.innerHeight - 60
        // var getPositions = e.target.getBoundingClientRect();
        var getPositions = searchRef.current.inputRef.current.getBoundingClientRect();

        console.log(getPositions)

        // Correct, but will not work with touch.
        const { top, height } = dropdownRef.current.getBoundingClientRect()
        console.log(getPositions.top)
        console.log(getPositions.height)
        console.log(top)
        console.log(height)
        console.log(windowHeight)
        const orientation = getPositions.top + height > windowHeight + 10 ? 'top' : 'bottom'
        console.log(orientation)
        console.log(orientation === 'bottom' ? (getPositions.top - top) + getPositions.height + 10 : (getPositions.top - top) + getPositions.height - height - getPositions.height - 10)
        const maxHeight = getPositions.top + height - windowHeight
        console.log(maxHeight)
        setPositions({
            width: getPositions.width,
            height: getPositions.height,
            x: getPositions.x,
            y: getPositions.y,
            top: (getPositions.top - top) + getPositions.height + 10,
            maxHeight: maxHeight > 0 ? 248 - maxHeight + 5 : "auto"
            //top: orientation === 'bottom' ? (getPositions.top - top) + getPositions.height + 10 : (getPositions.top - top) + getPositions.height - height - getPositions.height - 10
        })

    }

    useEffect(() => {
        if (!showDropdown) {
            setPositions({
                width: null,
                height: null,
                x: null,
                y: null,
                top: 0
            })
        }
    }, [showDropdown])

    function handleChangeInupt(e) {
        console.log(e.target)
        onSearch && setSearch(e.target.value)
        // setTimeout(() => {
        //     console.log('set position')
        //     updateDropdownPosition()
        // }, 2000)
    }

    return (
        <div className={`ui-select-box ${className ? className : ''}`} style={style}>
            <div ref={selectRef} className="ui-select-box__container">
                <TextInput
                    id={`select-input-field-${id}`}
                    className="ui-select-box__input"
                    ref={searchRef}
                    value={search}
                    placeholderTop={value?.label}
                    placeholder={placeholder}
                    errorMessage={errorMessage}
                    fieldDescription={fieldDescription}
                    fieldIcon={fieldIcon}
                    disabled={disabled}
                    autoComplete="off"
                    clear
                    handleClearInput={() => handleClearInput()}

                    onChange={
                        handleChangeInupt
                    }
                    onFocus={(e) => onFocus(e)}
                    onClick={(e) => onClick(e)}
                    //onBlur={(e) => onBlur(e)}
                    onKeyDown={(e) => handleOnKeyPress(e)}
                    size={size}
                //onKeyUp={(e) => handleOnKeyPress(e)}
                />
                {value && !search &&
                    <div className={`ui-select-box__selected-item ${disabled ? 'disabled' : ''}`} id={`selected-item-${id}`} onClick={() => searchRef.current.setFocus()}>
                        <div className={`selected-item-info ${size}`}>
                            <div className={`selected-item-name truncate ${disabled ? 'disabled' : ''}`}>
                                {value && <div dangerouslySetInnerHTML={{ __html: value.label}}/>}
                            </div>
                        </div>
                        {!multiSelect && !disabledClearInput && (
                            <div className={`remove-selected ${size}`}>
                                <div className={`input-form-clear danger ${disabled ? 'disabled' : ''}`} onClick={() => !disabled && handleClearInput()}>+</div>
                            </div>
                        )}
                    </div>
                }
                <div
                    id={`input-field-chevron-${id}`}
                    className={`ui-select-box__chevron ${disabled ? 'disabled' : ''} ${size}`}
                    onClick={() => searchRef.current.setFocus()}>
                    &#8964;
                </div>
            </div>
            <div
                id={`dropdown-${id}`}
                ref={dropdownRef}
                className={`ui-select-box-dropdown ${showDropdown === id ? 'show' : ''}`}
                style={{
                    maxHeight: positions.maxHeight,
                    width: `${selectRef.current?.offsetWidth}px`,
                    top: positions.top
                }}>
                <ScrollBar
                    maxHeight="240px"
                    onBottomPercent={() => !loading && onPagination && onPagination()}
                    loading={loading}>
                    <div className="ui-select-box-dropdown__container">
                        {options.length > 0 ? options?.filter(item => !item.occult).filter(f => {
                            if (search && search.trim().length > 0) {
                                if (f?.options?.length) {
                                    return f.options.filter(fo => fo.label?.toLocaleLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
                                        .includes(search.toLocaleLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ''))).length > 0
                                } else {
                                    return f.label?.toLocaleLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
                                        .includes(search.toLocaleLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ''))
                                }
                            } else {
                                return f
                            }
                        }).map((option, index) => (
                            <div
                                key={index}
                                className={`ui-select-box-dropdown__item ${!Object.keys(option).includes('title') && !multiSelect && value?.value === option.value ? 'selected' : ''}`}
                                ref={value?.value === option.value ? selectedRef : null}>
                                <div>
                                    {Object.keys(option).includes('title') ?
                                        <div className='ui-select-box-dropdown__title'>{option.title}</div>
                                        :
                                        <div
                                            className="ui-select-box-dropdown__action"
                                            onClick={() => [!option.disabled && onChange({ name, option, collapse: multiSelect && option?.options }), !option.disabled && !multiSelect && setSearch(search === null ? null : ''), (multiSelect ? setShowDropdown(id) : setShowDropdown(null))]} >
                                            <div className="dropdown-option d-flex align-items-center">
                                                {multiSelect && (
                                                    <Checkbox
                                                        id={`checkbox-${index}`}
                                                        name={`checkbox-${index}`}
                                                        checked={option.selected ? true : false}
                                                        value={option.selected}
                                                        disabled={option.disabled}
                                                        onChange={() => [onChange({ name, option }), (multiSelect && setShowDropdown(id))]}
                                                    />
                                                )}
                                                <div dangerouslySetInnerHTML={{ __html: option.label}}/>
                                            </div>
                                            {option?.options && (
                                                <div className="ui-select-box-dropdown__collapse" onClick={() => onChange({ name, option, collapse: true })}>
                                                    {!option.collapse ? '+' : '-'}
                                                </div>
                                            )}
                                        </div>
                                    }

                                </div>
                                {option.collapse && option.options && (
                                    <div className="collapse-list">
                                        {option.options?.filter(f => {
                                            if (search && search.trim().length > 0) {
                                                return f.label?.toLocaleLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
                                                    .includes(search.toLocaleLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ''))
                                            } else {
                                                return f
                                            }
                                        }).map((option, index) => (
                                            <div
                                                key={index}
                                                ref={value?.value === option.value ? selectedRef : null}
                                                className={`ui-select-box-dropdown__item ${value?.value === option.value ? 'selected' : ''}`}>
                                                <div className="d-flex flex-column flex-grow-1">
                                                    <div
                                                        className="ui-select-box-dropdown__action"
                                                        onClick={() => [!multiSelect ? onChange({ name, option }) : onChange({ name, option }), (multiSelect && setShowDropdown(id))]} >
                                                        <div className="dropdown-option d-flex align-items-center">
                                                            {multiSelect && (
                                                                <Checkbox
                                                                    id={`checkbox-${index}`}
                                                                    name={`checkbox-${index}`}
                                                                    checked={option.selected ? true : false}
                                                                    value={option.selected}
                                                                    onChange={() => [onChange({ name, option }), (multiSelect && setShowDropdown(id))]}
                                                                />
                                                            )}
                                                            <div dangerouslySetInnerHTML={{ __html: option.label}}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        )) :
                            <EmptyState
                                illustration={<div></div>}
                                loading={loading}
                                title={intl.formatMessage({ id: "Nada encontrado" })}
                                description={intl.formatMessage({ id: "Não foram encontrados registros para essa pesquisa." })}
                            />
                        }
                    </div>
                </ScrollBar>
            </div>
        </div>
    )
}
export default Selectbox
