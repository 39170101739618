import React from "react";
import { Button } from "../..";
const AdminActions = ({
  width,
  row,
  editable,
  controls,
  setControls,
  features,
  handleRowAction,
  archived,
}) => {
  const handleClick = (actionType) => {
    if (controls.controlContent.includes(row)) {
      setControls({
        ...controls,
        controlContent: controls.controlContent.filter((line) => line !== row),
      });
    }
    handleRowAction(actionType, row);
  };

  const customActions = features?.customActions || []
  return (
    <td width={width}>
      <div className="controller">
        <div
          className="controller__three-points"
          onClick={() =>
            setControls({
              ...controls,
              controlContent: controls.controlContent.includes(row)
                ? controls.controlContent.filter((line) => line !== row)
                : [...controls.controlContent, row],
            })
          }
        >
          <span></span>
        </div>
        <div
          className={
            controls.controlContent.includes(row)
              ? "controller__content controller__content--opened"
              : "controller__content controller__content--closed"
          }
        >
          {row.actions?.details !== false &&
            features &&
            features.defaultActions &&
            features.defaultActions.details &&
            !features?.disabledButtons?.details && (
              <Button.Details
                tooltip
                legend={!editable ? "Selecione um Contrato!" : "Detalhes"}
                legendColor={!editable ? "warning" : "primary"}
                disabled={!editable || row.details}
                dataCy="details-table-item"
                className="mr-50"
                onClick={() => handleClick("details")}
              />
            )}
          {row.actions?.copy !== false &&
            features &&
            features.defaultActions &&
            features.defaultActions.copy &&
            !features?.disabledButtons?.copy && (
              <Button.Copy
                tooltip
                legend={!editable ? "Selecione um Contrato!" : "Copiar"}
                legendColor={!editable ? "warning" : "primary"}
                disabled={!editable || row.copy}
                dataCy="copy-table-item"
                className="mr-50"
                onClick={() => handleClick("copy")}
              />
            )}
          {row.actions?.restore !== false &&
            features &&
            features.defaultActions &&
            features.defaultActions.restore &&
            !features?.disabledButtons?.archive && (
              <Button.Undo
                tooltip
                legend={!editable ? "Selecione um Contrato!" : "Desarquivar"}
                legendColor={!editable ? "warning" : "primary"}
                disabled={!editable || row.restore}
                dataCy="restore-table-item"
                className="mr-50"
                onClick={() => handleClick("unarchive")}
              />
            )}
          {row.actions?.edit !== false &&
            features &&
            features.defaultActions &&
            features.defaultActions.edit &&
            !features?.disabledButtons?.edit && (
              <Button.Edit
                tooltip
                legend={!editable ? "Selecione um Contrato!" : "Editar"}
                legendColor={!editable ? "warning" : "primary"}
                disabled={!editable || row.update}
                dataCy="edit-table-item"
                className="mr-50"
                onClick={() => handleRowAction("edit", row)}
              />
            )}
          {row.actions?.delete !== false &&
            features &&
            features.defaultActions &&
            features.defaultActions.delete &&
            !features?.disabledButtons?.delete && (
              <Button.X
                tooltip
                legend={!editable ? "Selecione um Contrato!" : "Apagar"}
                legendPosition="up"
                legendColor={!editable ? "warning" : "danger"}
                disabled={!editable || row.delete}
                dataCy="delete-table-item"
                onClick={() => handleClick("delete")}
              />
            )}
          {row.actions?.archive !== false &&
            features &&
            features.defaultActions &&
            features.defaultActions.archive &&
            !features?.disabledButtons?.archive && (
              <Button.Folder
                tooltip
                legend={!editable ? "Selecione um Contrato!" : "Arquivar"}
                legendPosition="up"
                legendColor={!editable ? "warning" : "warning"}
                disabled={!editable || row.archive}
                dataCy="archive-table-item"
                className="mr-50"
                onClick={() => handleClick("archive")}
              />
            )}
          {customActions?.map((customAction) => {
            const { name, onClick, Component } = customAction;
            return <Component key={name} onClick={(e) => onClick(row, e)} />;
          })}
          {features.defaultActions.copy
            ? features?.disabledButtons?.copy &&
              features?.disabledButtons?.edit &&
              features?.disabledButtons?.archive &&
              !archived &&
              "---"
            : features?.disabledButtons?.edit &&
              features?.disabledButtons?.archive &&
              !archived &&
              "---"}
          {features?.disabledButtons?.delete &&
            features?.disabledButtons?.archive &&
            archived &&
            "---"}
        </div>
      </div>
    </td>
  );
};

export default AdminActions;
