import {
  CircleMarkerOptions,
  CircleMarkerStyle,
  MarkerAlterOptions,
  Map,
  MapType,
  MarkerEventType,
  MarkerIcon,
  MarkerOptions,
  OverlayOptions,
  PolylineOptions,
  PopupOptions,
} from "@inlog/inlog-maps/lib";

const leafletLibParams = {
  scriptsDependencies: [
    `${process.env.PUBLIC_URL}/leaflet/leaflet-editable/src/Leaflet.Editable.js`,
    `${process.env.PUBLIC_URL}/leaflet/leaflet.path.drag/src/Path.Drag.js`,
    `${process.env.PUBLIC_URL}/leaflet/leaflet-gesture-handling/dist/leaflet-gesture-handling.js`,
    `${process.env.PUBLIC_URL}/leaflet/leaflet.markercluster/dist/leaflet.markercluster.js`,
  ],
  cssDependencies: [
    `${process.env.PUBLIC_URL}/leaflet/leaflet-gesture-handling/dist/leaflet-gesture-handling.css`,
    `${process.env.PUBLIC_URL}/leaflet/leaflet.markercluster/dist/MarkerCluster.Default.css`,
  ],
  wikimedia: false,
};

export const LoadMap = async (id, apiKey) => {
  const currentMap = new Map();

  let mapType = MapType.Leaflet;
  let mapParams = leafletLibParams;

  const GoogleParams = {
    libraries: ["drawing", "places"],
    apiKey: apiKey,
    gestureHandling: false,
    showTraffic: false,
  };

  if (apiKey) {
    mapType = MapType.Google;
    mapParams = GoogleParams;
  }

  return await new Promise((resolve, reject) => {
    currentMap
      .initialize(mapType, mapParams, id)
      .then(() => {
        resolve(currentMap);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const pinColor = (color) => {
  switch (color) {
    case "spanish-gray":
      return require("../../assets/img/markers/marker-spanish-gray.png");
    case "danger":
      return require("../../assets/img/markers/marker-danger.png");
    case "success":
      return require("../../assets/img/markers/marker-success.png");
    case "warning":
      return require("../../assets/img/markers/marker-warning.png");
    case "photo":
      return require("../../assets/img/markers/marker-photo.png");
    case "transparent":
      return require("../../assets/img/markers/transparent.png");
    case "ativo":
      return require("../../assets/img/markers/ativo.png");
    case "ativo-success":
      return require("../../assets/img/markers/ativo-success.png");
    case "ativo-danger":
      return require("../../assets/img/markers/ativo-danger.png");

    default:
      return require("../../assets/img/markers/marker-gray.png");
  }
};

const createMarkerOptions = (object, config) => {
  const size = config.size || [22, 30.55];
  const icon = new MarkerIcon(pinColor(config?.pinColor), size);

  return {
    ...new MarkerOptions(),
    latlng: [object.latitude, object.longitude],
    object: { rowId: object.rowId, groupId: object?.groupId },
    icon,
    draggable: true,
    addToMap: true,
    ...config,
  };
};

export const removePin = (map, rowId) => {
  map.removeMarkers("marker", (obj) => obj.rowId === rowId);
};

export const removePinGroup = (map, groupId, type = "marker") => {
  map.removeMarkers(type, (obj) => obj.groupId === groupId);
};

export const removeAllPins = (map) => {
  map.removeAllMarkers();
};

export const checkIfMarkerExists = (map, type, uuid) => {
  return map.markerExists(type || "marker", (obj) => obj.uuid === uuid);
};

export const removeMarker = (map, type, uuid) => {
  map.removeMarkers(type || "marker", (obj) => obj.uuid === uuid);
};

export const renderPointWithRadio = ({
  map,
  object,
  config,
  event,
  rightClick,
  size = 8,
  color = "#1da023",
  borderColor = "#ffffff",
  borderWidth = 3,
  type = "circleMarker",
}) => {
  const style = new CircleMarkerStyle(size, borderWidth, borderColor, color, 1);
  let options = new CircleMarkerOptions(
    [Number(object.lat), Number(object.lng)],
    style
  );
  options.object = {
    ...object,
    uuid: object.uuid,
    groupUuid: object?.groupUuid,
  };
  options.addToMap = true;
  options.fitBounds = false;
  options = {
    ...options,
    ...config,
  };

  map?.drawCircleMarker(type, options, event);

  if (rightClick) {
    map?.addMarkerEvent(
      type,
      MarkerEventType.RightClick,
      rightClick,
      (obj) => obj.uuid === object.uuid
    );
  }
};

export const alterPoint = (
  map,
  {
    options = {
      color: "#1da023",
      borderColor: "#ffffff",
      borderWidth: 3,
      size: 8,
    },
    latitude,
    longitude,
    type,
    uuid,
  }
) => {
  const style = new CircleMarkerStyle(
    options.size,
    options.borderWidth,
    options.borderColor,
    options.color,
    1
  );
  const newOptions = new MarkerAlterOptions(
    latitude && longitude ? [Number(latitude), Number(longitude)] : null,
    undefined,
    style
  );

  map?.alterMarkerOptions(type || "circleMarker", newOptions, (obj) => {
    return obj.uuid === uuid;
  });
};

export const renderMarker = (
  map,
  { object, config, event, rightClickEvent, markerType = "marker" }
) => {
  let options = new MarkerOptions();
  let icon = new MarkerIcon(pinColor(config?.pinColor), [25, 33]);
  options.latlng = [object.latitude, object.longitude];
  options.object = { rowId: object.rowId, groupId: object?.groupId };
  options.icon = icon;
  options.addToMap = true;
  options = {
    ...options,
    ...config,
  };
  map && map.drawMarker(markerType, options, event);
  if (rightClickEvent) {
    map?.addMarkerEvent(
      markerType,
      MarkerEventType.RightClick,
      rightClickEvent,
      (obj) => obj.rowId === object.rowId
    );
  }
};

export const renderDraggableMarker = (
  map,
  { object, config, event, rightClickEvent, markerType = "marker" }
) => {
  let options = new MarkerOptions();
  let icon = new MarkerIcon(pinColor(config?.pinColor), [25, 33]);
  options.latlng = [object.latitude, object.longitude];
  options.object = { rowId: object.rowId, groupId: object?.groupId };
  options.icon = icon;
  options.draggable = true;
  options.addToMap = true;
  options = {
    ...options,
    ...config,
  };
  map && map.drawMarker(markerType, options, event);
  if (rightClickEvent) {
    map?.addMarkerEvent(
      markerType,
      MarkerEventType.RightClick,
      rightClickEvent,
      (obj) => obj.rowId === object.rowId
    );
  }
};

export const renderPin = (
  map,
  object,
  config,
  event,
  markerType = "marker"
) => {
  let options = new MarkerOptions();
  let icon = new MarkerIcon(pinColor(config?.pinColor), [25, 33]);
  options.latlng = [object.latitude, object.longitude];
  options.object = { rowId: object.rowId, groupId: object?.groupId };
  options.icon = icon;
  options.addToMap = true;
  options = {
    ...options,
    ...config,
  };
  map && map.drawMarker(object?.type || markerType, options, event);
};

const drawDraggableMarker = (map, options, event, eventDrag) => {
  map.drawMarker(options?.markerType || "marker", options, event);
  map.addMarkerEvent(
    options?.markerType || "marker",
    MarkerEventType.AfterDrag,
    (e) => eventDrag({ latitude: e.latlng[0], longitude: e.latlng[1] })
  );
};

export const renderPinDraggable = (map, object, config, event, eventDrag) => {
  const options = createMarkerOptions(object, config);
  drawDraggableMarker(map, options, event, eventDrag);
};

export const removePolygon = (map, rowId) => {
  map.removePolygons("polygon", (object) => object.rowId === rowId);
};

export const removePolygonGroup = (map, groupId) => {
  map.removePolygons("polygon", (object) => object.groupId === groupId);
};

export const removeAllPolygons = (map) => {
  map.removeAllPolygons();
};

export const renderPolygon = (map, object, config, event) => {
  let options = new PolylineOptions();
  options.path = object.shape
    .sort((a, b) => a.sequencia - b.sequencia)
    .map((latlng) => {
      const { latitude, longitude } = latlng;
      return [latitude, longitude];
    });
  options.object = { rowId: object.rowId };
  options.color = "#0000005f";
  options.fillColor = "#0000005f";
  options.addToMap = true;
  options.fitBounds = false;
  options = {
    ...options,
    ...config,
  };

  map.drawPolygon("polygon", options, event);
};

export const renderPolygonNew = (map, object, config, event) => {
  let options = new PolylineOptions();
  if (object?.shape) {
    options.path = object?.shape;
    options.object = { rowId: object.rowId };
    options.color = "#0000005f";
    options.fillColor = "#0000005f";
    options.addToMap = true;
    options.fitBounds = false;
    options = {
      ...options,
      ...config,
    };
    map.drawPolygon(object.type || "polygon", options, event);
  }
};

export const removePolyline = (map, rowId) => {
  map.removePolylines("polyline", (object) => object.rowId === rowId);
};

export const removeAllPolylines = (map) => {
  map.removeAllPolylines();
};

export const renderPolyline = (map, object, config, event) => {
  let options = new PolylineOptions();
  options.path = object.shape
    .sort((a, b) => a.sequencia - b.sequencia)
    .map((latlng) => {
      const { latitude, longitude } = latlng;
      return [latitude, longitude];
    });
  options.object = { rowId: object.rowId };
  options.color = "#000000";
  options.fillColor = "#000000";
  options.addToMap = true;
  options.fitBounds = false;
  options = {
    ...options,
    ...config,
  };

  map.drawPolyline(config.type || "polyline", options, event);
};

export const renderPolylineNew = (map, object, config, event) => {
  let options = new PolylineOptions();
  options.path = object.shape;
  options.object = { rowId: object.rowId };
  options.color = "#000000";
  options.fillColor = "#000000";
  options.addToMap = true;
  options.fitBounds = false;
  options = {
    ...options,
    ...config,
  };

  map.drawPolyline(config.type || "polyline", options, event);
};

export const removePoint = (map, rowId, type) => {
  map.removeMarkers(type || "circleMarker", (object) => object.rowId === rowId);
};

export const removeAllPoints = (map) => {
  map.removeMarkers();
};

export const renderOverlay = (
  map,
  html,
  latlng,
  type = "overlay",
  object = { uuid: null },
  event = null,
  rightClickEvent = null
) => {
  const options = new OverlayOptions(html, true, latlng);
  if (event) {
    map?.addMarkerEvent(
      type,
      MarkerEventType.Click,
      event,
      (obj) => obj.uuid === object.uuid
    );
  }

  if (rightClickEvent) {
    map?.addMarkerEvent(
      type,
      MarkerEventType.RightClick,
      rightClickEvent,
      (obj) => obj.uuid === object.uuid
    );
  }
  return map?.drawOverlay(type, options);
};
export const RemoveAllOverlay = (map, type) => {
  map.removeOverlays(type ?? "overlay");
};

export const renderPoint = (map, object, config, event, style = null) => {
  let newStyle =
    style ||
    new CircleMarkerStyle(
      object.ray ? object.ray : 7,
      4,
      object.color ?? "#1da023",
      "#ffffff",
      0.9
    );
  let options = new CircleMarkerOptions();
  options.latlng = [object.latitude, object.longitude];
  options.object = { rowId: object.rowId };
  options.style = newStyle;
  options.addToMap = true;
  options.fitBounds = false;
  options = {
    ...options,
    ...config,
  };

  map.drawCircleMarker(config?.type || "circleMarker", options, event);
};

export const changeCircle = (map, options, uuid) => {
  map?.alterCircleOptions(
    options.type || "circleMarker",
    options,
    (obj) => obj.uuid === uuid
  );
};

export const renderPopup = (map, popup, latlng) => {
  const options = new PopupOptions(latlng, popup, "simple");
  map.drawPopup("marker", options);
};
export const removePopup = (map) => {
  map.closePopup("marker");
};

export const fitBounds = (map, { type = "" }) => {
  map.fitBoundsElements(type);
};
