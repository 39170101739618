import React, { useEffect, useCallback } from "react";
import { X } from "react-feather";
import classnames from "classnames";
import BodyCard from "../bodyCard/BodyCard";
import Overlay from "../overlay/Overlay";

import "./ModalWindow.scss";

const ModalWindow = ({
  fullScreen = false,
  show,
  position,
  title,
  size,
  className,
  style,
  color,
  toggle,
  onPressEnter,
  footer,
  children,
}) => {
  const pressedKeyboardKey = useCallback(
    (key) => {
      if (String(key.code).toLowerCase() === "escape" || key.keyCode === 27) {
        toggle();
      }

      if (String(key.code).toLowerCase() === "enter" || key.keyCode === 13) {
        onPressEnter();
      }
    },
    [toggle, onPressEnter]
  );

  useEffect(() => {
    if (show) {
      window.addEventListener("keydown", pressedKeyboardKey);
    }

    return () => {
      window.removeEventListener("keydown", pressedKeyboardKey);
    };
  }, [show, pressedKeyboardKey]);

  return (
    <div
      className={`modal-window-container ${show ? "show" : ""}  ${position}`}
      style={style ? style : {}}
    >
      <Overlay show={show} onClick={toggle} />
      <div
        className={classnames(
          `modal-window ${color} ${show ? "show" : ""} ${
            className ? className : ""} ${fullScreen ? 'fullscreen' : ''}`,
          {
            top: position === "top",
            center: position === "center",
            bottom: position === "bottom",
          },
          {
            "window-mn": size === "mn",
            "window-xs": size === "xs",
            "window-sm": size === "sm",
            "window-md": size === "md",
            "window-lg": size === "lg",
            "window-xl": size === "xl",
            "window-mx": size === "mx",
          }
        )}
      >
        <BodyCard title={title} footer={footer}>
          {children}
        </BodyCard>
        <div className="ct-close" onClick={toggle}>
          <X />
        </div>
      </div>
    </div>
  );
};

export default ModalWindow;
