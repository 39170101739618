import axios from "axios";
import loadDash from "lodash";
let CancelToken = axios.CancelToken;

const withCancel = (cancelFn) => {
  return {
    cancelToken: new CancelToken(function executor(c) {
      cancelFn && cancelFn(c);
    }),
  };
};

const axiosInterceptor = () => {
  return axios.interceptors.response.use(
    async (response) => {
      return response;
    },
    async (error) => {
      if (401 === loadDash.get(error, "response.status")) {
        localStorage.removeItem("userInfo");
        window.location = "/login";
      } else if (403 === loadDash.get(error, "response.status")) {
        return await Promise.reject(error || {});
      } else {
        return await Promise.reject(error || {});
      }
    }
  );
};

const client = (receivedToken) => {
  const userData = JSON.parse(localStorage.getItem("userInfo"));
  const token = userData && userData.token && JSON.parse(userData.token).token;

  const defaultOptions = {
    headers: {
      Authorization: receivedToken
        ? `${receivedToken}`
        : token
        ? `${token}`
        : "",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, X-Auth-Token, Content-Type, Accept",
    },
  };

  return {
    get: (url, options = {}) =>
      axios.get(url, { ...defaultOptions, ...options }),
    post: (url, data, options = {}) =>
      axios.post(url, data, { ...defaultOptions, ...options }),
    put: (url, data, options = {}) =>
      axios.put(url, data, { ...defaultOptions, ...options }),
    patch: (url, data, options = {}) =>
      axios.patch(url, data, { ...defaultOptions, ...options }),
    delete: (url, data) => axios.delete(url, { data, ...defaultOptions }),
  };
};

var getFormData = function (obj, form, namespace) {
  var fd = form || new FormData(),
    formKey;

  for (let property in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, property)) {
      let val = obj[property];
      formKey = namespace ? `${namespace}[${property}]` : property;

      if (
        loadDash.isArray(val) ||
        (loadDash.isObject(val) && !(val instanceof File))
      ) {
        getFormData(val, fd, formKey);
      } else {
        fd.append(formKey, val);
      }
    }
  }
  return fd;
};

export { client, axiosInterceptor, getFormData, withCancel };
