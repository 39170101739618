import React from "react";
import * as Icon from "react-feather";
import "./contest.scss";

const Contest = ({ contest, onClick }) => {
  return (
    <div className="ui-contest" onClick={onClick}>
      <Icon.HelpCircle className="white" size={20} />
      {typeof contest === "number" && (
        <div className={`count ${contest === 0 ? "sucess" : ""}`}>
          {contest}
        </div>
      )}
    </div>
  );
};

export default Contest;
