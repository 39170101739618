import React from "react";
import "./Card.scss";

const Card = ({ items, userPermission, className, onClick }) => {
  const location = window.location;

  return (
    <div className="ui-cards-container">
      {items &&
        items.map((item, i) => (
          <React.Fragment key={`ui_card_2_${i}`}>
            {(!item.permission || item.permission.includes(userPermission)) && (
              <div
                className={`ui-cards-item ${className ? className : ""} ${
                  location.pathname.replace("/", "") === item.attributes.path
                    ? "active"
                    : ""
                }`}
                onClick={() => (onClick ? onClick(item) : null)}
              >
                <div className="ui-card">
                  <div className="ui-card-title">
                    <figure>
                      <img
                        src={item.attributes.icon}
                        alt={item.attributes.title}
                      />
                    </figure>
                    <h3>{item.attributes.title}</h3>
                  </div>
                  <p>{item.attributes.description}</p>
                </div>
              </div>
            )}
          </React.Fragment>
        ))}
    </div>
  );
};

export default Card;
