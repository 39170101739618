/* eslint-disable react/display-name */
import React, { forwardRef } from "react";
import { useIntl } from "react-intl";

import CloseButton from "../closeButton/CloseButton";
import { Checkbox } from "..";

const ShowColumns = forwardRef(
  ({ show, toggleShow, list, changeList, columnsType }, ref) => {
    const intl = useIntl();

    const handleColumns = (e) => {
      const { key } = e;
      const selectedColumns = list.map((item) => {
        if (item.key === key) {
          item = {
            ...item,
            selected: !item.selected,
            hidden: !item.hidden,
          };
        }
        return item;
      });
      localStorage.setItem(
        columnsType,
        JSON.stringify(
          selectedColumns.map((item) => {
            return {
              key: item.key,
              selected: item.selected,
            };
          })
        )
      );
      changeList(selectedColumns);
    };
    return (
      <div ref={ref} className={`select-columns ${show ? "show" : "hide"}`}>
        <div className="select-columns__header">
          <CloseButton size="sm" onClick={() => toggleShow(false)} />
          <div className="select-columns__title">
            {intl.formatMessage({ id: "Mostrar Colunas" })}
          </div>
        </div>
        <div className="select-columns__body">
          {list?.map((item) => {
            return (
              !item?.occult && (
                <div
                  key={JSON.stringify(item)}
                  className={`select-columns__item ${
                    item?.selected ? "selected" : ""
                  } ${item?.disabled ? "disabled" : ""}`}
                  onClick={() => handleColumns(item)}
                >
                  <Checkbox
                    value={item?.selected}
                    checked={item?.selected}
                    disabled={item?.disabled}
                    onChange={() => handleColumns(item)}
                  />
                  {item.label}
                </div>
              )
            );
          })}
        </div>
      </div>
    );
  }
);

export default ShowColumns;
