import React, { useState, useEffect } from 'react'
import { Search } from "react-feather"
import { TextInput } from "../../components"
import { useIntl } from "react-intl"
import "./SearchBar.scss"


const SearchBar = ({ id, className, value, placeholder, dataCy, size, onKeyUp, dynamic, handleTermFilter }) => {
    const intl = useIntl()
    const [search, setSearch] = useState('');

    const handleValue = (value) => {
        setSearch(value)
    }

    const handleClearInput = () => {
        setSearch('')
    }

    const handleOnKeyUp = (e) => {
        onKeyUp && onKeyUp(e)
    }

    useEffect(() => {
        if (dynamic) {
            const delayDebounceFn = setTimeout(() => {
                handleTermFilter(search)
            }, 500)
            return () => clearTimeout(delayDebounceFn)
        } else {
            handleTermFilter(search)
        }
    }, [search])
    
    return (
        <div className={`search-bar-form ${className}`}>
            <TextInput
                id={`search-${id}`}
                autoComplete="off"
                name="search"
                placeholder={intl.formatMessage({ id: placeholder ? placeholder : '' })}
                //value={value}
                value={value ? value : search}
                dataCy={dataCy}
                size={size}
                handleClearInput={() => handleClearInput()}
                placeholderStyle='hide'
                fieldIcon={{
                    icon: <Search size={15} />,
                    position: 'left'
                }}
                onKeyUp={handleOnKeyUp}
                clear
                onChange={e => handleValue(e.target.value)} />
                {/* // onChange={e => console.log("1234 e", e.target.value)} /> */}
        </div>
    )
}

export default SearchBar