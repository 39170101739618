/* eslint-disable react/display-name */
import React from "react";
import "../inputs.scss";
const { forwardRef, useRef, useState, useImperativeHandle } = React;

const TextInput = forwardRef(
  (
    {
      id,
      type,
      className,
      autoComplete,
      name,
      placeholder,
      placeholderTop,
      value,
      onChange,
      errorMessage,
      fieldDescription,
      fieldIcon,
      placeholderStyle,
      clear,
      handleClearInput,
      hint,
      maxLength,
      hiddenMaxLength,
      dataCy,
      disabled,
      size,
      onClick,
      onKeyDown,
      onKeyUp,
      onBlur,
      ...rest
    },
    ref
  ) => {
    const [showPassword, setShowPassword] = useState(false);
    const inputRef = useRef();

    const setFocus = () => {
      inputRef.current.focus();
    };

    // The component instance will be extended
    // with whatever you return from the callback passed
    // as the second argument
    useImperativeHandle(ref, () => ({
      setFocus,
      inputRef,
    }));

    function toggleShowPassword() {
      setShowPassword(!showPassword);
    }

    return (
      <div
        className={`input-form-group ${className ? className : ""} ${
          errorMessage && errorMessage.error
            ? `is-invalid ${errorMessage.color ? errorMessage.color : ""}`
            : ""
        } ${disabled ? "input-disabled" : ""}`}
      >
        <div
          className={`input-form ${
            fieldIcon ? `has-icon ${fieldIcon.position}` : ""
          }`}
        >
          <input
            {...rest}
            data-cy={dataCy ? dataCy : null}
            className={size ? size : ""}
            maxLength={maxLength ? maxLength : 256}
            ref={inputRef}
            type={
              type === "password" && showPassword
                ? "text"
                : type
                ? type
                : "text"
            }
            id={id}
            autoComplete={autoComplete === "off" ? "new-password" : ""}
            name={name}
            placeholder=" "
            value={value ? value : ""}
            onChange={(e) => (onChange ? onChange(e) : {})}
            onKeyUp={(e) => (onKeyUp ? onKeyUp(e) : {})}
            onKeyDown={(e) => (onKeyDown ? onKeyDown(e) : {})}
            onClick={(e) => (onClick ? onClick(e) : {})}
            onBlur={onBlur ? onBlur : () => {}}
            disabled={disabled}
          />
          {fieldIcon && (
            <div className={`input-field-icon ${fieldIcon.position}`}>
              {fieldIcon.icon}
            </div>
          )}
          <label
            className={`${placeholderStyle === "hide" ? "hide" : "animated "} ${
              placeholderTop ? "placeholder-top" : ""
            } truncate`}
            data-cy={id}
            htmlFor={id}
          >
            {placeholder}
          </label>
          <div className="input-actions">
            {clear && value && value.trim().length > 0 && (
              <div
                className={`input-form-clear ${hint ? "has-hint" : ""}`}
                onClick={handleClearInput}
              >
                +
              </div>
            )}
            {hint && (
              <span className="input-form-hint">
                <div className="input-hint-icon">i</div>
                <div className="input-hint-text">{hint}</div>
              </span>
            )}
            {type === "password" && (
              <span
                className={`show-hide-characters ${
                  showPassword ? "active" : ""
                }`}
                onClick={toggleShowPassword}
              >
                <div className="gg-eye" />
              </span>
            )}
          </div>
          <div className="input-border" />
        </div>

        {
          /*(maxLength && maxLength > 0) || errorMessage || fieldDescription &&*/
          <div className="fields-legends">
            <div className="fields-legends-descriptions">
              {errorMessage && errorMessage.error ? (
                <small className={errorMessage.color}>
                  {errorMessage.error}
                </small>
              ) : fieldDescription ? (
                <small>{fieldDescription}</small>
              ) : null}
            </div>
            {!hiddenMaxLength && maxLength && maxLength > 0 && (
              <div className="fields-legends-counter">
                {value.length}/{maxLength}
              </div>
            )}
          </div>
        }
      </div>
    );
  }
);

export default TextInput;
