const pagesPath = {
  "sinotico-tarefas": "task-overview",
  "sinotico-demandas": "demand-overview",
  regioes: "regions",
  setores: "sectors",
  "locais-servico": "service-location",
  "tipos-servico": "services-types",
  servicos: "services",
  "tipos-usuario": "access-rules",
  usuarios: "users",
  equipes: "team",
  demandas: "demands",
  filiais: "branches",
  "agenda-tarefa": "task-scheduling",
  "importador-agenda-tarefas": "task-scheduling-import",
  "importador-demandas": "demands-import",
  shapes: "shapes",
  turnos: "shifts",
  "unidades-medidas": "unit-measure",
  relatorios: "reports",
  ativos: "actives",
};

const pagesActions = {
  cadastrar: "create",
  alterar: "update",
  arquivar: "archive",
  excluir: "delete",
  copiar: "copy",
  "filtros_data-atual": "currentDay",
  filtros_agendado: "scheduled",
  filtros_aguardando: "waiting",
  filtros_executando: "running",
  filtros_finalizado: "finished",
  filtros_aprovado: "approved",
  filtros_contestado: "contested",
  filtros_justificado: "justified",
  filtros_reprovado: "disapproved",
  acoes_supervisonar: "supervise",
  acoes_aprovar: "approve",
  acoes_reprovar: "disapprove",
  acoes_contestar: "contest",
  acoes_justificar: "justify",
  "acoes_selecionar-foto": "selectPhoto",
  "acoes_excluir-foto": "deletePhoto",
  importar: "import",
  "filtros_registros-pendentes": "pendingRegister",
  filtros_abertos: "opened",
  filtros_devolvido: "returned",
  listar: "list",
};

export { pagesActions, pagesPath };
