function getArrayDepth(value) {
  if (Array.isArray(value)) {
    if (Array.isArray(value[0]) && Array.isArray(value[0][0][0])) {
      return 3; // Representa um multipolígono
    } else if (Array.isArray(value[0][0])) {
      return 2; // Representa um polígono
    } else {
      return 1; // Representa um conjunto de coordenadas
    }
  } else {
    return 0; // Não é um array
  }
}

function validateAndCleanShape(shape) {
  return shape.filter((coord) => !(coord[0] === 0 && coord[1] === 0));
}

function shapeArrayNormalizer(array) {
  const depth = getArrayDepth(array);
  let index = 0;

  switch (depth) {
    case 3: {
      return array.map((multiPolygon) => {
        return {
          tipoForma: "Poligono",
          raio: 0,
          coordenadas: multiPolygon
            .map((polygon) => {
              return polygon.map((latlng) => {
                return {
                  latitude: latlng[1],
                  longitude: latlng[0],
                  sequencia: index++,
                };
              });
            })
            .flat(),
          formasInternas: [],
        };
      });
    }
    case 2: {
      return [
        {
          tipoForma: "Poligono",
          raio: 0,
          coordenadas: array[0]
            .map((latlng) => {
              return {
                latitude: latlng[1],
                longitude: latlng[0],
                sequencia: index++,
              };
            })
            .flat(),
          formasInternas: array.slice(1, array.length).map((m) => {
            return {
              coordenadas: m.map((latlng) => {
                return {
                  latitude: latlng[1],
                  longitude: latlng[0],
                  sequencia: index++,
                };
              }),
            };
          }),
        },
      ];
    }
    case 1: {
      return [
        {
          tipoForma: "Poligono",
          raio: 0,
          coordenadas: array.map((latlng) => {
            return {
              latitude: latlng?.latitude || latlng[0],
              longitude: latlng?.longitude || latlng[1],
              sequencia: index++,
            };
          }),
          formasInternas: [],
        },
      ];
    }
    default: {
      return array;
    }
  }
}

function shapeRenderNormalizer(array) {
  const formatArray = (arr) => {
    const depth = getArrayDepth(arr);
    console.log(arr);
    console.log(depth);
    switch (depth) {
      case 2: {
        return arr[0];
      }
      case 3: {
        return arr;
      }
      default: {
        return arr;
      }
    }
  };
  return array.map((m) => {
    return formatArray(m);
  });
}

export const deepClone = (obj) => {
  if (obj === null || typeof obj !== "object") {
    return obj;
  }

  let clone = Object.assign({}, obj);

  Object.keys(clone).forEach((key) => (clone[key] = deepClone(clone[key])));

  return Array.isArray(obj) && obj.length
    ? (clone.length = obj.length) && Array.from(clone)
    : Array.isArray(obj)
    ? Array.from(obj)
    : clone;
};

export const deepCloneArray = (array) => {
  return array.map((m) => {
    return deepClone(m);
  });
};

export {
  getArrayDepth,
  shapeArrayNormalizer,
  shapeRenderNormalizer,
  validateAndCleanShape,
};
