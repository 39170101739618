import account from "../services/Account";
import conta from "../services/Conta";

/**
 * Esse utilitário tem como objetivo unificar algumas funções necessárias para
 * o login do usuário e para o modo impersonate.
 */
const impersonate = {
  /** Lista todas as empresas cadastradas e requer um token de usuário impersoonate */
  fetchCompanies: (tokenImpersonate) => {
    return new Promise((resolve, reject) => {
      return conta
        .listarTodas(tokenImpersonate)
        .then((res) =>
          resolve(
            res.data.map((item) => {
              return {
                value: item.codigo,
                label: item.nome,
                option: item.codigo,
              };
            })
          )
        )
        .catch((error) => reject(error));
    });
  },
  /** Lista todos os usuários cadastrados em uma determinada empresa
   *  Requer o token de usuário impersonate
   */
  fetchUsers: (tokenImpersonate, codigoContaSelecionada) => {
    return new Promise((resolve, reject) => {
      return account
        .buscarUsuariosImpersonate(tokenImpersonate, {
          codigoContaSelecionada: codigoContaSelecionada,
        })
        .then((res) =>
          resolve(
            res.data.map((item) => {
              return {
                value: item.codigo,
                label: `${item?.nome} - ${item?.email}`,
                option: item.codigo,
              };
            })
          )
        )
        .catch(() => reject(false));
    });
  },
  /** Monta o opbjeto responsável por renderizar as listas de filiais
   *  caso o usuário tenha acesso a mais de uma filial, a aplicação
   *  adiciona o item "Todas as filiais" para que o usuário possa visualizar
   *  nas grids, todos os dados do sistema, de acordo com seu nível de
   *  acesso
   */
  fetchBranches: (branches) => {
    const length = !branches ? 0 : branches.length;
    switch (length) {
      case 0: {
        return [];
      }
      case 1: {
        return branches;
      }
      default: {
        return [
          {
            cnpj: "Remover filtro por conta",
            codigo: null,
            codigoEmpresa: branches[0].codigoEmpresa,
            nome: "Todos os contratos",
          },
          ...branches,
        ];
      }
    }
  },
};

export default impersonate;
