import React, { useState, useRef, useEffect } from 'react'
import './dropdown.scss'

const Dropdown = ({
    id,
    closeOnSelect,
    label,
    content,
    mw
}) => {
    const dropdownRef = useRef(null)
    const selectRef = useRef(null)
    const [showDropdown, setShowDropdown] = useState(false)
    const [positions, setPositions] = useState({
        width: null,
        height: null,
        x: null,
        y: null,
        top: 0
    })

    const onClick = (e) => {
        const activeElement = e.target.id === `dropdown-action-${id}`
        if (activeElement && !showDropdown) {
            showDrop(e, activeElement)
        } else {
            setShowDropdown(false)
        }
    };

    const classListToArray = (classList) => {
        let classes = []
        for (let cssClass of classList) {
            classes.push(cssClass);
        }
        return classes
    }

    const clickOutDropdown = e => {
        const clickedElement = classListToArray(e.target.classList)
        const classNames = [closeOnSelect ? '' : 'filter-header__item']
        const clkInside = typeof clickedElement.find(f => classNames.includes(f)) !== 'undefined'
        const activeElement = e.target.id === `dropdown-action-${id}`
        if (showDropdown && !clkInside && !activeElement) {
            setShowDropdown(false)
        }
    };

    useEffect(() => {
        document.addEventListener("click", clickOutDropdown);
        return () => {
            document.removeEventListener("click", clickOutDropdown);
        };
    })

    useEffect(() => {
        document.addEventListener("scroll", handleScroll, true);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    function handleScroll(e) {
        const scrolllingOptons = e?.target?.classList?.contains('ui-scroll-bar')
        if (!scrolllingOptons) {
            setShowDropdown(false)
        }
    }

    function showDrop(e) {
        const windowHeight = window.innerHeight - 60
        var getPositions = e.target.getBoundingClientRect();
        // Correct, but will not work with touch.
        const { top, height, width } = dropdownRef.current.getBoundingClientRect()
        const orientation = getPositions.top + height > windowHeight + 10 ? 'top' : 'bottom'
        console.log(width)
        console.log(getPositions.x)
        setPositions({
            width: getPositions.width,
            height: getPositions.height,
            x: getPositions.x + getPositions.width - width,
            y: getPositions.y,
            top: orientation === 'bottom' ? (getPositions.top - top) + getPositions.height + 10 : (getPositions.top - top) + getPositions.height - height - getPositions.height - 10
        })
        setShowDropdown(showDropdown ? false : id)
    }

    useEffect(() => {
        if (!showDropdown) {
            setPositions({
                width: null,
                height: null,
                x: null,
                y: null,
                top: 0
            })
        }
    }, [showDropdown])

    return (
        <div className={`ui-dropdown`}>
            <div ref={selectRef} id={`dropdown-action-${id}`} data-cy={`dropdown-action-${id}`} className="ui-dropdown__label" onClick={ e => onClick(e)}>
                {label}
            </div>
            <div
                id={`dropdown-${id}`}
                ref={dropdownRef}
                className={`ui-dropdown__items ${showDropdown === id ? 'show' : ''}`}
                style={{
                    minWidth: mw ? mw : `${selectRef.current?.offsetWidth}px`,
                    top: positions.top,
                    left: positions.x
                }}>
                    <div className="ui-dropdown-items__container">
                        {content}
                    </div>
            </div>
        </div>
    )
}
export default Dropdown
