import React from 'react'
import "./Overlay.scss"

function Overlay({ show, onClick, zIndex }) {
return (
      <span className={`rc-ui-overlay ${show ? 'show' : ''}`} style={{zIndex: zIndex ? zIndex : -1}} onClick={onClick}>
         
      </span>
  )
}
export default Overlay