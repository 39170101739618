import React from 'react'
import './label.scss'

const Label = ({classname, children}) => {
    return (
        <div className={`ui-label ${classname}`}>
            {children}
        </div>
    )
}
export default Label
