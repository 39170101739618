import { combineReducers } from "redux"
import authReducer from './authentication/authReducer';


//REDUX CORRETOS

const rootReducer = combineReducers({
  auth: authReducer,
})

export default rootReducer
