import React from 'react'
import {  Check } from "react-feather"
import { useIntl } from 'react-intl'

import "./toggleArchived.scss"

function ToggleArchived({archived, handleToggleArchived  }) {
  const intl = useIntl()
  return (
      <div className='d-flex justify-content-center align-items-center' data-cy="toggle-archive" onClick={handleToggleArchived}>
        <div className={`toggle-archived ${archived ? 'active' : ''}`}>
            <div className="archive-check">
                <Check size={10} />
            </div>
            <small>{intl.formatMessage({ id: 'Arquivados' })}</small>
        </div>
      </div>
    
  )
}

export default ToggleArchived
