import React from 'react'
import * as Icon from "react-feather"
import './toggle-full-screen.scss'


const ToggleFullScreen = ({ fullScreen, className, changeFullScreen }) => {
    return (
        <div
            className={`ui-toggle-full-screen ${className}`}
            onClick={() => changeFullScreen()}>
            {fullScreen ? <Icon.Minimize size={16} color="#000" /> : <Icon.Maximize size={16} color="#000" />}
        </div>
    )
}

export default ToggleFullScreen