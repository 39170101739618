import React, { useRef } from "react";
import "./ScrollBar.scss";

const ScrollBar = ({
  maxHeight,
  children,
  onBottomPercent,
  loading,
  horizontal,
}) => {
  const onScrollRef = useRef(null);
  const onScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    const bottomThreshold = 10; // Ajuste este valor conforme necessário

    if (scrollTop + clientHeight >= scrollHeight - bottomThreshold) {
      onBottomPercent && onBottomPercent();
    }
  };

  return (
    <div
      ref={onScrollRef}
      onScroll={onScroll}
      className={`ui-scroll-bar ${horizontal ? "horizontal" : ""}`}
      style={{ maxHeight: maxHeight ? maxHeight : "initial" }}
    >
      <div>
        {children}
        <div className={`loading-scroll-bar ${loading ? "show" : ""}`}>
          <div className="loading-scroll-bar__content">
            <div className="fallback-spinner">
              <div className="loading component-loader">
                <div className="effect-1 effects"></div>
                <div className="effect-2 effects"></div>
                <div className="effect-3 effects"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScrollBar;
