import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import ToggleArchived from "../toggleArchived/toggleArchived";
import { useIntl } from "react-intl";
import { ReactComponent as FilterIcon } from "../../assets/img/svg/filter-icon.svg";
import { Spinner } from "reactstrap";

import { uuidv4 } from "../../utils/uuidGenerator";

import "./Table.scss";
import Head from "./components/Head";
import Footer from "./components/Footer";
import Body from "./components/Body";

const UID = uuidv4();

const calculatePages = (totalRows, pageSize) => {
  const totalPages = Math.ceil(totalRows / pageSize) || 1;
  const pages = [...Array(totalPages).keys()].map((i) => i + 1);
  return { totalPages, pages };
};

const updatePagination = ({ page, pageSize, totalRows }) => {
  const { totalPages, pages } = calculatePages(totalRows, pageSize);
  const checkedPage = Math.max(1, Math.min(page, totalPages));
  const toRow = pageSize * checkedPage - pageSize + 1;
  const fromRow = pageSize * checkedPage;

  return {
    toRow,
    fromRow,
    totalRows,
    totalPages,
    pages,
    page: checkedPage,
    page_size: pageSize,
    loading: false,
    select: false,
  };
};

const Table = ({
  columns,
  rows,
  views,
  pageSize,
  administrator,
  customHeader,
  customBody,
  loading,
  selectable,
  selectableDisabled,
  serverPagination,
  paginationDisabled,
  onChangeServerPagination,
  onChangeLocalPagination,
  onChangeSelect,
  serverTotalRows,
  handleRowAction,
  handleToggleArchived,
  archived,
  emptyState,
  highlightRow,
  ordering,
  changeColumnOrdering,
  editable,
  features,
  fullHeight,
  hideAllSelect,
  ...rest
}) => {
  const intl = useIntl();
  const [select] = useState([]);
  const [controls, setControls] = useState({
    controlEdit: [],
    controlDelete: [],
    controlContent: [],
    controlSeleted: [],
  });
  const [data, setData] = useState({
    rows: [],
    columns: [],
  });
  const [pagination, setPagination] = useState({
    toRow: 0,
    fromRow: 0,
    page: 1,
    pages: [],
    page_size: pageSize,
    totalRows: 0,
    totalPages: 0,
    loading: false,
    select: false,
  });

  const visibility = useMemo(() => rows.length > 0, [rows]);

  const newRows = useMemo(() => {
    if (loading || serverPagination) {
      return rows;
    }

    return rows.slice(
      (pagination.page - 1) * pagination.page_size,
      (pagination.page) * pagination.page_size
    );
  }, [loading, serverPagination, rows, pagination.page, pagination.page_size]);

  const handleServerPagination = useCallback(
    (updatedPagination) => {
      if (onChangeServerPagination) {
        onChangeServerPagination(updatedPagination);
      }
    },
    [onChangeServerPagination]
  );

  const handleLocalPagination = useCallback(
    (updatedPagination) => {
      if (onChangeLocalPagination) {
        onChangeLocalPagination(updatedPagination);
      }
    },
    [onChangeLocalPagination]
  );

  useEffect(() => {
    setData({
      rows: newRows,
      columns: columns,
    });
  }, [newRows, columns]);

  useEffect(() => {
    if (!loading) {
      const updatedPagination = updatePagination({
        page: pagination.page,
        pageSize: pagination.page_size,
        totalRows: serverPagination ? serverTotalRows : rows.length,
      });
      setPagination(updatedPagination);
      if (serverPagination) {
        handleServerPagination(updatedPagination);
      } else {
        handleLocalPagination(updatedPagination);
      }
    }
  }, [
    pagination.page,
    pagination.page_size,
    loading,
    rows.length,
    serverPagination,
  ]);

  const handleOnChangeSelect = useCallback(() => {
    if (onChangeSelect) {
      onChangeSelect(select);
    }
  }, [onChangeSelect, select]);

  useEffect(() => {
    handleOnChangeSelect();
  }, [handleOnChangeSelect]);

  const [selectViews, setSelectViews] = useState(false);
  const [selectPagination, setSelectPagination] = useState(false);
  const [toggleArchive, setToggleArchive] = useState(false);

  const paginationRef = useRef();
  const handleClickPagination = (e) => {
    if (paginationRef.current && !paginationRef.current.contains(e.target))
      setSelectPagination(false);
  };
  useEffect(() => {
    document.addEventListener("click", handleClickPagination);
    return () => {
      document.removeEventListener("click", handleClickPagination);
    };
  });

  const viewsRef = useRef();
  const handleClickViews = (e) => {
    if (viewsRef.current && !viewsRef.current.contains(e.target))
      setSelectViews(false);
  };
  useEffect(() => {
    document.addEventListener("click", handleClickViews);
    return () => {
      document.removeEventListener("click", handleClickViews);
    };
  });

  const handleRowClick = (e, row) => {
    if (e.target.tagName === "TD") {
      handleRowAction("row", row);
    }
  };

  function changeArchived() {
    handleToggleArchived();
  }

  return (
    <div
      {...rest}
      className={`table-wrapper ${fullHeight ? "full-height" : "auto-height"}`}
    >
      {
        /*!loading ?*/
        <React.Fragment>
          <div className="table-body-wrapper">
            <div
              data-cy="table-loading"
              className={`datatable-loading ${loading ? "show" : ""}`}
            >
              <Spinner size="mg" color="primary" />
            </div>
            <table
              /*{...rest}*/ id={UID}
              className={rows.length === 0 ? "h-100" : ""}
            >
              <Head
                administrator={administrator}
                visibility
                rows={rows}
                selectable={selectable}
                hideAllSelect={hideAllSelect}
                select={select}
                selectableDisabled={selectableDisabled}
                handleRowAction={handleRowAction}
                columns={columns}
                loading={loading}
                changeColumnOrdering={changeColumnOrdering}
                ordering={ordering}
                customHeader={customHeader}
                intl={intl}
                features={features}
                changeArchived={changeArchived}
                archived={archived}
                setToggleArchive={setToggleArchive}
                toggleArchive={toggleArchive}
                ToggleArchived={ToggleArchived}
                FilterIcon={FilterIcon}
                data={data}
              />
              <Body
                rows={rows}
                loading={loading}
                emptyState={emptyState}
                highlightRow={highlightRow}
                handleRowClick={handleRowClick}
                selectable={selectable}
                handleRowAction={handleRowAction}
                selectableDisabled={selectableDisabled}
                select={select}
                columns={columns}
                customBody={customBody}
                administrator={administrator}
                editable={editable}
                controls={controls}
                setControls={setControls}
                features={features}
                visibility={visibility}
              />
            </table>
          </div>

          <Footer
            intl={intl}
            pagination={pagination}
            paginationDisabled={paginationDisabled}
            viewsRef={viewsRef}
            setSelectViews={setSelectViews}
            selectViews={selectViews}
            views={views}
            setPagination={setPagination}
            paginationRef={paginationRef}
            setSelectPagination={setSelectPagination}
            selectPagination={selectPagination}
          />
        </React.Fragment>
      }
    </div>
  );
};

export default Table;
