/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { Suspense, lazy } from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { IntlProviderWrapper } from "./utility/context/Internationalization"
import { Layout } from "./utility/context/Layout"
import * as serviceWorker from "./serviceWorker"
import { store } from "./redux/storeConfig/store"
import "./index.scss"
import "@inlog/react-components/src/assets/styles/icons.css"
import "@inlog/react-components/src/global.css"
import { loadUserFromStorage } from './utils/loadUserFromStorage'
import {
  Spinner
} from './components'
loadUserFromStorage(store) //Using localStorage
const LazyApp = lazy(() => import("./App"))
const domain = window.location.hostname

if(domain !== 'localhost'){
  console.log = () => { };
  console.warn = () => { };
  console.error = () => { };
  // eslint-disable-next-line no-global-assign
  alert = () => { }
}
// configureDatabase()
const rootNode = document.getElementById('root');

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
    <Provider store={store}>
      <Suspense fallback={<Spinner />}>
        <Layout>
          <IntlProviderWrapper>
            <LazyApp />
          </IntlProviderWrapper>
        </Layout>
      </Suspense>
    </Provider>
  ,
  rootNode
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
