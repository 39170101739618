import React from 'react'
import { X } from 'react-feather'
import "./close-button.scss"

function CloseButton({ onClick, size, color }) {
return (
    <div className={`ct-close ${size ? size : ''} ${color ? color : ''}`} onClick={onClick}>
        <X />
    </div>
  )
}
export default CloseButton
