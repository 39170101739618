import { client, axiosInterceptor } from "../../utils/axios-utils";

axiosInterceptor();
const api = {
  cadastrar: (params) => {
    return new Promise((resolve, reject) => {
      return client()
        .post(
          `${process.env.REACT_APP_FISCOLFRONT_API_URL}/${process.env.REACT_APP_FISCOLFRONT_API_URI}/Usuario/Cadastrar`,
          { ...params }
        )
        .then((res) => resolve(res.data))
        .catch((error) => reject(error));
    });
  },
  listar: (params) => {
    console.log(params);
    return new Promise((resolve, reject) => {
      return client()
        .get(
          `${process.env.REACT_APP_FISCOLFRONT_API_URL}/${process.env.REACT_APP_FISCOLFRONT_API_URI}/Usuario/Listar`,
          { params }
        )
        .then((res) => resolve(res.data))
        .catch((error) => reject(error));
    });
  },
  alterar: (params) => {
    return new Promise((resolve, reject) => {
      return client()
        .put(
          `${process.env.REACT_APP_FISCOLFRONT_API_URL}/${process.env.REACT_APP_FISCOLFRONT_API_URI}/Usuario/Alterar`,
          { ...params }
        )
        .then((res) => resolve(res.data))
        .catch((error) => reject(error));
    });
  },
  delete: (codigo) => {
    return new Promise((resolve, reject) => {
      return client()
        .delete(
          `${process.env.REACT_APP_FISCOLFRONT_API_URL}/${process.env.REACT_APP_FISCOLFRONT_API_URI}/Usuario/Delete?Codigo=${codigo}`
        )
        .then((res) => resolve(res.data))
        .catch((error) => reject(error));
    });
  },
  arquivar: (codigo) => {
    return new Promise((resolve, reject) => {
      return client()
        .put(
          `${process.env.REACT_APP_FISCOLFRONT_API_URL}/${process.env.REACT_APP_FISCOLFRONT_API_URI}/Usuario/Arquivar`,
          { Codigo: codigo }
        )
        .then((res) => resolve(res.data))
        .catch((error) => reject(error));
    });
  },
  desarquivar: (codigo) => {
    return new Promise((resolve, reject) => {
      return client()
        .put(
          `${process.env.REACT_APP_FISCOLFRONT_API_URL}/${process.env.REACT_APP_FISCOLFRONT_API_URI}/Usuario/Desarquivar`,
          { Codigo: codigo }
        )
        .then((res) => resolve(res.data))
        .catch((error) => reject(error));
    });
  },
  alterarSenha: (params) => {
    return new Promise((resolve, reject) => {
      return client()
        .put(
          `${process.env.REACT_APP_FISCOLFRONT_API_URL}/${process.env.REACT_APP_FISCOLFRONT_API_URI}/Usuario/AlterarSenha`,
          { ...params }
        )
        .then((res) => resolve(res.data))
        .catch((error) => reject(error));
    });
  },
  salvarConfiguracaoImportacao: (params) => {
    return new Promise((resolve, reject) => {
      return client()
        .post(
          `${process.env.REACT_APP_FISCOLFRONT_API_URL}/${process.env.REACT_APP_FISCOLFRONT_API_URI}/Usuario/SalvarConfiguracaoImportacao`,
          { ...params }
        )
        .then((res) => resolve(res.data))
        .catch((error) => reject(error));
    });
  },
  buscarConfiguracaoImportacao: (params) => {
    console.log(params);
    return new Promise((resolve, reject) => {
      return client()
        .get(
          `${process.env.REACT_APP_FISCOLFRONT_API_URL}/${process.env.REACT_APP_FISCOLFRONT_API_URI}/Usuario/BuscarConfiguracaoImportacao`,
          { params }
        )
        .then((res) => resolve(res.data))
        .catch((error) => reject(error));
    });
  },
};

export default api;
