import React, { useState, useRef, useEffect } from 'react';
import {
    TextInput
} from '../../';
import Calendar from './components/Calendar'
import { ReactComponent as CalendarIcon } from './assets/images/calendar.svg';
import moment from 'moment';
import '../../selectBox/select-box.scss'

const Datetime = ({
    id,
    placeholder,
    className,
    name,
    value,
    errorMessage,
    fieldDescription,
    fieldIcon,
    onChange,
    onSearch,
    disabled,
    size,
    closeOnSelect,
    handleClearInput,
    range,
    autoComplete,
    time,
    minDate,
    maxDate
}) => {
    const searchRef = useRef(null)
    const dropdownRef = useRef(null)
    const selectRef = useRef(null)
    const selectedRef = useRef(null)

    const [search, setSearch] = useState(null)
    const [showDropdown, setShowDropdown] = useState(false)
    const [positions, setPositions] = useState({
        width: null,
        height: null,
        x: null,
        y: null,
        top: 0
    })

    const onFocus = (e) => {
      if (e.target.id === `select-input-field-${id}`) {
        showDrop(e)
      }
    };

    const onClick = (e) => {
        const activeElement = document.activeElement.id === `select-input-field-${id}`
        if (activeElement && !showDropdown) {
            showDrop(e)
        }
    };

    const classListToArray = (classList) => {
        let classes = []
        for (let cssClass of classList) {
            classes.push(cssClass);
        }
        return classes
    }

    const clickOutDropdown = e => {
        const clickedElement = classListToArray(e.target.classList)
        const classNames = closeOnSelect ? ['week-name', 'calendar-buttons', 'range-separator', 'calendar-range-date', 'calendar-error', 'time-item', 'calendar-footer', 'calendar-month', 'datetime-container', 'time-selector', 'hour-selector', 'minute-selector', 'hour-input', 'minute-input', 'nav-arrow', 'week-day', 'ui-select-box-dropdown', 'calendar-header', 'week-column', 'calendar-today', 'calendar-prev', 'calendar-next', 'checkbox-input-field', 'rc-checkbox-ui', 'ui-select-box-dropdown__item', 'dropdown-option', 'ui-select-box-dropdown__collapse', 'ui-select-box-dropdown__action'] : []
        const clkInside = typeof clickedElement.find(f => classNames.includes(f)) !== 'undefined'
        const activeElement = document.activeElement.id === `select-input-field-${id}`
        if (showDropdown && !clkInside && !activeElement) {
            setShowDropdown(false)
        }
    };

    useEffect(() => {
        document.addEventListener("click", clickOutDropdown);
        //document.addEventListener("focusout", onFocusOut);
        return () => {
            document.removeEventListener("click", clickOutDropdown);
            //document.removeEventListener("focusout", onFocusOut);
        };
    })


    const handleOnKeyPress = (e) => {
        switch (e.keyCode) {
            case 40:
                return
            case 38:
                return
            case 13:
                setShowDropdown(false)
                return
            case 9:
                handleHideInactiveFieldDropDown(e)
                return
        }
    }

    useEffect(() => {
        selectedRef.current && selectedRef.current.scrollIntoView({ block: 'center', behavior: 'smooth' })
    }, [value])

    useEffect(() => {
        if (search !== null) {
            const delaySearch = setTimeout(() => {
                onSearch && onSearch(search)
            }, 500)
            return () => clearTimeout(delaySearch)
        }
    }, [search])

    useEffect(() => {
        document.addEventListener("scroll", handleScroll, true);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    function handleScroll(e) {
        const scrolllingOptons = e?.target?.classList?.contains('ui-scroll-bar')
        if (!scrolllingOptons) {
            setShowDropdown(false)
        }
    }

    function handleHideInactiveFieldDropDown() {
        const activeElement = document.activeElement
        if (id === showDropdown) {
            setShowDropdown(false)
        } else {
            setShowDropdown(activeElement.id)
        }
    }

    function showDrop(e) {
        const windowHeight = window.innerHeight - 60
        const getPositions = e.target.getBoundingClientRect();
        // Correct, but will not work with touch.
        const { top, height } = dropdownRef.current.getBoundingClientRect()
        const orientation = getPositions.top + height > windowHeight + 10 ? 'top' : 'bottom'
        setPositions({
            width: getPositions.width,
            height: getPositions.height,
            x: getPositions.x,
            y: getPositions.y,
            //top: orientation === 'bottom' ? getPositions.top + 10 : getPositions.top - height - (getPositions.height + 14),
            top: orientation === 'bottom' ? (getPositions.top - top) + getPositions.height + 10 : (getPositions.top - top) + getPositions.height - height - getPositions.height - 10
        })
        setShowDropdown(showDropdown ? false : id)
    }

    useEffect(() => {
        if (!showDropdown) {
            setPositions({
                width: null,
                height: null,
                x: null,
                y: null,
                top: 0
            })
        }
    }, [showDropdown])

    function selectedDate(date) {
        onChange({
            name: name,
            value: date
        })
        if (closeOnSelect) {
            setShowDropdown(false)
        }
    }

    return (
        <div className={`ui-select-box ${className}`}>
            <div ref={selectRef} className="ui-select-box__container date-time">
                <TextInput
                    //type={range ? 'text' : time ? 'datetime-local' : 'date'}
                    id={`select-input-field-${id}`}
                    className="ui-select-box__input"
                    ref={searchRef}
                    value={search}
                    placeholderTop={value}
                    placeholder={placeholder}
                    errorMessage={errorMessage}
                    fieldDescription={fieldDescription}
                    fieldIcon={fieldIcon}
                    disabled={disabled}
                    autoComplete={autoComplete}
                    onChange={(e) => onSearch && setSearch(e.target.value)}
                    onFocus={(e) => onFocus(e)}
                    onClick={(e) => onClick(e)}
                    //onBlur={(e) => onBlur(e)}
                    onKeyDown={(e) => handleOnKeyPress(e)}
                    size={size}
                //onKeyUp={(e) => handleOnKeyPress(e)}
                />
                {value && !search &&
                    <div className="ui-select-box__selected-item" id={`selected-item-${id}`} onClick={() => searchRef.current.setFocus()}>
                        <div className={`selected-item-info ${size}`}>
                            <div className="selected-item-name truncate">
                                {time ? moment(value).format('DD/MM/YYYY HH:mm') :
                                    range ? `${Array.isArray(value) && value[0] ? moment(value[0]).format('DD/MM/YYYY') : !Array.isArray(value) && value ? moment(value).format('DD/MM/YYYY') : ' --/--/---- '} -
                                            ${Array.isArray(value) && value[1] ? moment(value[1]).format('DD/MM/YYYY') : '--/--/----'}`
                                    :
                                    !range && !time &&
                                        moment(value).format('DD/MM/YYYY')
                                }
                            </div>
                        </div>
                        {handleClearInput && (
                            <div className={`remove-selected ${size}`}>
                                <div className="input-form-clear danger" onClick={() => handleClearInput(name)}>+</div>
                            </div>
                        )}
                    </div>
                }
                <div
                    id={`input-field-chevron-${id}`}
                    className={`ui-select-box__chevron ${size}`}
                    onClick={() => searchRef.current.setFocus()}>
                    <CalendarIcon size={12} />
                </div>
            </div>
            <div
                id={`dropdown-${id}`}
                ref={dropdownRef}
                className={`ui-select-box-dropdown overflow-show ${showDropdown === id ? 'show' : ''}`}
                style={{
                    minWidth: `${selectRef.current?.offsetWidth}px`,
                    top: positions.top
                }}>
                <div className="ui-select-box-dropdown__container">
                    <Calendar
                        closeOnSelect={closeOnSelect}
                        selectedDate={selectedDate}
                        setShowDropdown={setShowDropdown}
                        defaultRange={{
                            start: value && Array.isArray(value) ? value[0] : value ? value : null,
                            end: value && Array.isArray(value) && value[1] ? value[1] : null
                        }}
                        minDate={minDate} // 2022-07-31
                        maxDate={maxDate} // 2022-07-01
                        range={range}
                        time={time}
                        isVisible={showDropdown}
                    />
                </div>
            </div>
        </div>
    )
}
export default Datetime
