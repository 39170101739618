import React, { Suspense } from "react";
import { Route } from "react-router-dom";
import { ContextLayout } from "../../utility/context/Layout";
import { Spinner } from "../../components";

const pageTitle = {
  login: "Fazer login",
  impersonate: "Fazer login como impersonate",
  terms: "Termos de uso",
  "create-company": "Criação de conta",
  "not-allowed": "Acesso não permitido",
  "recover-password": "Recuperar senha",
};

class PublicRoute extends React.Component {
  componentDidMount() {
    const user = localStorage.getItem("user-login");
    console.log(JSON.stringify(user));
    this.setPageTitle();
  }

  componentDidUpdate() {
    this.setPageTitle();
  }

  setPageTitle = () => {
    const { path } = this.props;
    const pathName = path.replace("/", "").split("/")[0];
    document.title = `Fiscol - ${pageTitle[pathName]}`;
  };

  render() {
    const Component = this.props.component;
    const { layout, path, url = "" } = this.props;

    //SERÁ PRECISO PEGAR O USERLEVEL PARA BLOQUEAR ESTA TELA
    return (
      <Route
        path={path}
        render={(props) => {
          return (
            <ContextLayout.Consumer>
              {(context) => {
                let LayoutTag =
                  layout === "full"
                    ? context.fullLayout
                    : layout === "horizontal"
                    ? context.horizontalLayout
                    : context.VerticalLayout;
                return (
                  <LayoutTag {...props} permission={props.user}>
                    <Suspense fallback={<Spinner />}>
                      <Component url={url} {...props} />
                    </Suspense>
                  </LayoutTag>
                );
              }}
            </ContextLayout.Consumer>
          );
        }}
      />
    );
  }
}

export default PublicRoute;
