import React, { useState, createContext, useMemo, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import messages_en from "../../assets/data/locales/en.json";
import messages_br from "../../assets/data/locales/pt.json";
import moment from 'moment';
import 'moment/locale/pt-br';
import 'moment/locale/en-ie';
const menu_messages = {
  'pt-BR': messages_br,
  en: messages_en
};

const Context = createContext();

const IntlProviderWrapper = ({ children }) => {
  const [locale, setLocale] = useState('pt-BR');
  const [messages, setMessages] = useState(menu_messages['pt-BR']);

  const switchLanguage = (language) => {
    setLocale(language);
    setMessages(menu_messages[language]);
  };

  const contextValue = useMemo(() => ({
    locale, switchLanguage,
  }), [locale]);

  useEffect(() => {
    moment.locale(locale)
  }, [locale])

  return (
    <Context.Provider value={contextValue}>
      <IntlProvider
        key={locale}
        locale={locale}
        messages={messages}
        defaultLocale="pt-BR"
      >
        {children}
      </IntlProvider>
    </Context.Provider>
  )
};

export { IntlProviderWrapper, Context as IntlContext };
