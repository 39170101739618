import { client, axiosInterceptor } from '../../utils/axios-utils'

axiosInterceptor()
const api =  {
    cadastrar: (params) => {
        return new Promise((resolve, reject) => {
            return  client()
            .post(`${process.env.REACT_APP_FISCOLFRONT_API_URL}/${process.env.REACT_APP_FISCOLFRONT_API_URI}/Empresa/Cadastrar`, {...params})
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    },
    listarTodas: (tokenImpersonate) => {
        return new Promise((resolve, reject) => {
            return  client(tokenImpersonate)
            .get(`${process.env.REACT_APP_FISCOLFRONT_API_URL}/${process.env.REACT_APP_FISCOLFRONT_API_URI}/Conta/ListarTodas`)
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    }
}

export default  api;
