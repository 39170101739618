import React from 'react'
import "./Tooltip.scss"

function Tooltip({ label, show, color, position }) {

    return (
        <div className={`rc-tooltip-ui bg-${color ? color : ''} ${show !== undefined ? show ? 'show' : ' hide' : ''} ${position ? position : ''}`}>
            {label}
        </div>
    )
}
export default Tooltip
