import React, { useRef } from 'react'
import './Accordion.scss'

const Accordion = ({ id, className, onClick, show, label, children }) => {
    const contentRef = useRef(null)
    return (
        <div id={id} className={`ui-accordion ${className}`}>
            <div
                className="ui-accordion__label"
                onClick={onClick}>
                <div className="ui-accordion__icon">
                    {show ? '-' : '+'}
                </div>
                {label}
            </div>
            <div
                className={`ui-accordion__content ${show && 'show'}`}
                ref={contentRef}>
                <div className="ui-accordion__content__body">
                    {children}
                </div>
            </div>
        </div>
    )
}

export default Accordion
