import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  storeUser,
  storeBranch,
} from "../../../redux/actions/authentication/authActions";
import ChangePassword from "../../../pages/authentication/components/changePassword";
import { FormattedMessage } from "react-intl";
import * as Icon from "react-feather";
import MasterMenu from "../masterMenu/MasterMenu";
import {
  pagesActions,
  pagesPath,
} from "../../../configs/dictionaryAccessRules";
import { useIntl } from "react-intl";
import account from "../../../services/Account";
import { Spinner } from "reactstrap";
import { HubConnectionBuilder, HttpTransportType } from "@microsoft/signalr";
import "../../../assets/scss/components/custom-header.scss";
import impersonate from "../../../utils/impersonateUtils";
import MainSocket from "../../../MainSocket";
import MenuItemsHooks from "../../MenuItemsHooks";
import { Header as HeaderComponent } from "@inlog/react-components/dist/components/Header";

import {
  Avatar,
  useToast,
  // SearchBar,
  Selectbox,
  Button,
  ModalWindow,
} from "../../../components";

function CustomHeader() {
  const dispatch = useDispatch();
  const intl = useIntl();
  const toast = useToast();
  const dropdownRef = useRef();
  const dropdownRefCenter = useRef();
  const location = window.location;
  const { selectedBranch, user = {} } = useSelector((state) => state.auth);
  const [showMenu, setShowMenu] = useState(false);
  const [showMenuCenter, setShowMenuCenter] = useState(false);
  const [open, setOpen] = useState(false);
  const [socketConnection] = useState(false);
  const [modals, setModal] = useState({
    logout: false,
    impersonate: false,
  });
  const [loading, setLoading] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [menuIsShowing, setMenuIsShowing] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [defaultFields] = useState({
    codigoContaSelecionada: null,
    codigoUsuarioSelecionado: null,
  });
  const [companies, setCompanies] = useState({
    rows: [],
    search: "",
    loading: false,
  });
  const [users, setUsers] = useState({
    rows: [],
    search: "",
    loading: false,
  });

  const [fields, setFields] = useState(defaultFields);

  const handleFieldsSelect = (e) => {
    const { name, option } = e;
    setFields({ ...fields, [name]: option });
  };

  useEffect(() => {
    if (fields.codigoContaSelecionada && user?.tokenUsuarioImpersonate) {
      const tokenImpersonate = user?.tokenUsuarioImpersonate;
      account
        .buscarUsuariosImpersonate(tokenImpersonate, {
          codigoContaSelecionada: fields.codigoContaSelecionada.value,
        })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (
      !fields.codigoContaSelecionada &&
      fields.codigoUsuarioSelecionado
    ) {
      setFields({
        ...fields,
        codigoUsuarioSelecionado: null,
      });
    }
  }, [fields.codigoContaSelecionada]);

  const handleClickDropdown = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setShowMenu(false);
    }
  };
  const handleClickDropdownCenter = (e) => {
    if (
      dropdownRefCenter.current &&
      !dropdownRefCenter.current.contains(e.target)
    ) {
      setShowMenuCenter(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", (e) => {
      handleClickDropdown(e);
      handleClickDropdownCenter(e);
    });
    return () => {
      document.removeEventListener("click", null);
    };
  });

  useEffect(() => {
    console.log(user);
    const options = {
      transport: HttpTransportType.WebSockets,
      skipNegotiation: true,
      connectionId: user.token,
    };

    const connection = new HubConnectionBuilder()
      .withUrl(
        `${process.env.REACT_APP_FISCOLFRONT_API_URL}/notificacao`,
        options
      )
      // .withAutomaticReconnect()
      // .withHubProtocol(new JsonHubProtocol())
      // .configureLogging(LogLevel.Information)
      .build();
    connection
      .start()
      .then((res) => {
        console.log(res);
        connection
          .invoke("getConnectionId")
          .then((connectionId) => {
            console.log(connectionId);
          })
          .catch((err) => console.log(err));
        connection.on("conclusao-importacao-formas", () => {});
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (socketConnection) {
      console.log(`${process.env.REACT_APP_FISCOLFRONT_API_URL}/notificacao`);
      console.log(socketConnection);
    }
  }, [socketConnection]);

  function changePasswordModal() {
    console.log(user);
    dispatch(storeUser({ ...user, trocarSenha: false }));
    setOpen(!open);
    setShowMenu(false);
  }

  function toggleShowMasterMenu() {
    setMenuIsShowing(!menuIsShowing);
  }

  function handleSelectBranch(branch) {
    dispatch(storeBranch(branch));
    setShowMenu(!showMenu);
  }
  function toggleShowMenu() {
    setShowMenu(!showMenu);
    if (showMenu) {
      setSearchTerm("");
    }
  }
  function toggleShowMenuCenter() {
    setShowMenuCenter(!showMenuCenter);
    if (showMenuCenter) {
      setSearchTerm("");
    }
  }
  function handleLogout() {
    dispatch(storeUser(null));
    window.location.href = process.env.REACT_APP_FISCOL_AUTHENTICATOR_URL; //"https://inlog-autenticador-front.vercel.app/";
  }

  function toggleLogoutModal() {
    setModal({
      ...modals,
      logout: !modals.logout,
    });
    setShowMenu(false);
  }

  function toggleImpersonateModal() {
    setModal({
      ...modals,
      impersonate: !modals.impersonate,
    });
    setShowMenu(false);
  }

  function handleMenuIsShowing(param) {
    setMenuIsShowing(param);
  }
  // function handleSearch(term) {
  //   setSearchTerm(term);
  // }

  useEffect(() => {
    const tokenImpersonate = user.tokenUsuarioImpersonate
    if (tokenImpersonate) {
      impersonate
        .fetchCompanies(tokenImpersonate)
        .then((res) => {
          setCompanies((prevCompanies) => {
            return {
              ...prevCompanies,
              rows: res,
            };
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  useEffect(() => {
    if (fields.codigoContaSelecionada) {
      setLoadingUsers(true);
      const tokenImpersonate = user.tokenUsuarioImpersonate;
      setFields({ ...fields, codigoUsuarioSelecionado: null });
      setUsers({
        rows: [],
        search: "",
        loading: false,
      });
      impersonate
        .fetchUsers(tokenImpersonate, fields.codigoContaSelecionada.value)
        .then((res) => {
          setUsers((prevUsers) => {
            return {
              ...prevUsers,
              rows: res,
            };
          });
          setLoadingUsers(false);
        })
        .catch((err) => {
          setLoadingUsers(false);
          console.log(err);
        });
    } else if (
      !fields.codigoContaSelecionada &&
      fields.codigoUsuarioSelecionado
    ) {
      setFields({
        ...fields,
        codigoUsuarioSelecionado: null,
      });
    }
  }, [fields.codigoContaSelecionada]);

  function changeUserImpersonate() {
    setLoading(true);
    const tokenImpersonate = user.tokenUsuarioImpersonate;
    const { codigoContaSelecionada, codigoUsuarioSelecionado } = fields;
    account
      .gerarTokenUsuarioImpersonate(tokenImpersonate, {
        codigoContaSelecionada: codigoContaSelecionada.value,
        codigoUsuarioSelecionado: codigoUsuarioSelecionado.value,
      })
      .then((res) => {
        if (res.data && res.success) {
          let response = res.data;

          response.contratos = impersonate.fetchBranches(response.contratos);
          response.tokenUsuarioImpersonate = JSON.stringify({
            token: response.tokenUsuarioImpersonate,
          });
          const formatPermissions = () => {
            let screens = {};
            res.data &&
              res.data.acoesSistema &&
              res.data.acoesSistema.length > 0 &&
              res.data.acoesSistema.map((item) => {
                if (!Object.keys(screens).includes(item.tela)) {
                  let actions = {};
                  res.data.acoesSistema.map((action) => {
                    if (item.tela === action.tela) {
                      actions = {
                        ...actions,
                        [pagesActions[action.identificador]]: true,
                      };
                    }
                  });
                  screens = {
                    ...screens,
                    [pagesPath[item.tela]]: actions,
                  };
                }
              });
            return screens;
          };
          dispatch(
            storeUser(
              {
                ...res.data,
                acoesSistema: formatPermissions(),
              },
              toggleImpersonateModal()
            )
          );

          if (res.data.contratos.length > 0) {
            dispatch(storeBranch(selectedBranch ?? response.contratos[0]));
          }
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.add({
          message: err?.response?.data?.message,
          color: "danger",
          autoClose: 7000,
        });
      });
  }

  useEffect(() => {
    const { trocarSenha } = JSON.parse(localStorage.getItem("userInfo"));
    if (trocarSenha) {
      setOpen(true);
    }
  }, []);

  const disableBranch = [
    "/services-types",
    "/services",
    "/user-types",
    "/users",
  ].includes(location.pathname);
  const itemsMenu = MenuItemsHooks();
  const [headerTitle, setHeaderTitle] = useState();
  useEffect(() => {
    const pageTitle = itemsMenu
      .map((m) => m.items)
      .flat()
      .find((item) => location.pathname.includes(item.attributes.path));
    console.log(pageTitle);
    console.log(location.pathname);
    setHeaderTitle({
      title:
        pageTitle && pageTitle.attributes && pageTitle.attributes.pageTitle
          ? pageTitle.attributes.pageTitle
          : null,
    });
    if (pageTitle && pageTitle.attributes && pageTitle.attributes.pageTitle) {
      document.title = `Fiscol - ${pageTitle.attributes.pageTitle}`;
    } else {
      document.title = `Fiscol`;
    }
  }, [location.pathname]);

  useEffect(() => {
    !user?.contratos.find(
      (item) =>
        item?.nome === selectedBranch?.nome &&
        item?.codigo === selectedBranch?.codigo
    ) && dispatch(storeBranch(user.contratos[0]));
  }, []);

  const socketEvent = (event) => {
    console.log(event);
    if (user.codigo === event.CodigoUsuarioSolicitante) {
      const messageImport = () => {
        switch (event.Status) {
          case "FinalizadoComSucesso": {
            return {
              message:
                "Sua solicitação de importação foi finalizada com sucesso",
              color: "success",
            };
          }
          case "FinalizadoComExcessao": {
            return {
              message:
                "Sua solicitação de importação foi finalizada mas alguns itens não puderam ser importados",
              color: "warning",
            };
          }
          case "Processando": {
            return {
              message: `Sua solicitação de importação de ${event.QuantidadeRegistros} registro(s) foi iniciada`,
              color: "success",
            };
          }
          default: {
            return {
              message: "Erro ao importar formas, por favor, tente novamente",
              color: "dange",
            };
          }
        }
      };

      toast.add({
        message: messageImport(event.Status).message,
        color: messageImport(event.Status).color,
        autoClose: 7000,
      });
    }
  };

  return (
    <MainSocket event={socketEvent}>
      <div
        className={`app-header-ui ${
          user && user.tokenUsuarioImpersonate ? "impersonate" : ""
        }`}
      >
        <HeaderComponent
          style={{
            "z-index": 0,
          }}
          logoPros={{ alt: "Fiscol" }}
          onMenuClick={() => handleMenuIsShowing(!menuIsShowing)}
          hideMenuButton={menuIsShowing && location.pathname === "/"}
          pageTitle={
            headerTitle?.title ? `Fiscol - ${headerTitle.title}` : `Fiscol`
          }
          mobileBreakpoint={768}
          isImpersonate={!!user?.tokenUsuarioImpersonate ?? false}
          handleImpersonateClick={toggleImpersonateModal}
          RightActionsComponent={
            // Ajustar aqui para aparecer Sair e a versão.
            <>
              <div
                className="d-flex flex-row align-items-center cursor-pointer"
                ref={dropdownRef}
                onClick={toggleShowMenu}
              >
                <div className="user-nav d-sm-flex d-none flex-column justify-content-center align-items-end">
                  <small className="user-section">
                    {user?.nome?.split(" ")[0]}
                  </small>
                </div>
                <div className="d-flex flex-row align-items-center pr-1">
                  <div className="d-flex flex-row align-items-center cursor-pointer">
                    <div className="">
                      <span className="user-name">
                        {user?.nome?.split(" ")[0]}
                      </span>
                    </div>
                    <Avatar
                      className="ml-50"
                      style={{
                        width: 32,
                        height: 32,
                      }}
                      color="primary"
                      size="sm"
                      content={user?.nome}
                    />
                  </div>
                </div>
                <div className={`user-dropdown-menu ${showMenu ? "show" : ""}`}>
                  <div className="user-dropdown-menu-footer">
                    <div
                      className="user-dropdown-menu-footer-item logout"
                      onClick={toggleLogoutModal}
                      style={{
                        width: "100%",
                      }}
                    >
                      <Icon.LogOut size={13} />
                      Sair
                    </div>
                  </div>
                </div>
              </div>
            </>
          }
          CenterComponent={
            // Ajustar aqui para abrir contratos + Cnpj.
            <>
              <div
                className="d-flex flex-row align-items-center cursor-pointer"
                ref={dropdownRefCenter}
                onClick={toggleShowMenuCenter}
              >
                <div className="user-nav d-sm-flex d-none flex-column justify-content-center align-items-end">
                  <small className="user-section">{selectedBranch?.nome}</small>
                </div>
              </div>
              <div
                className={`user-dropdown-menu-center ${
                  showMenuCenter ? "show" : ""
                }`}
              >
                <div className="user-dropdown-menu-options">
                  {user?.contratos?.map((m, i) => (
                    <div
                      data-cy={`branch_order_${i}`}
                      key={`branch_${m.codigo}`}
                      className={`user-dropdown-menu-item ${
                        selectedBranch && selectedBranch.codigo === m.codigo
                          ? "selected"
                          : ""
                      } ${disableBranch ? "disabled" : ""}`}
                      onClick={() =>
                        disableBranch ? {} : handleSelectBranch(m)
                      }
                    >
                      <div>
                        <b>{m.nome}</b>
                        <br />
                        <small>{m.cnpj}</small>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          }
        />
      </div>

      <MasterMenu
        menuIsShowing={handleMenuIsShowing}
        show={menuIsShowing || location.pathname === "/"}
        toggleShowMenu={toggleShowMasterMenu}
        searchTerm={searchTerm}
      />
      <ChangePassword open={open} togglePasswordModal={changePasswordModal} />
      <ModalWindow
        show={modals.logout}
        title={<FormattedMessage id="Deseja desconectar do sistema?" />}
        position="center"
        toggle={toggleLogoutModal}
        footer={
          <div className="d-flex justify-content-between">
            <Button.Cancel dark onClick={() => toggleLogoutModal()} />
            <Button.Logout dark onClick={() => handleLogout()} />
          </div>
        }
      >
        <p>
          {
            <FormattedMessage id="Ao sair, você será redirecionado de volta para a tela de login." />
          }
        </p>
      </ModalWindow>
      <ModalWindow
        show={modals.impersonate}
        title={<FormattedMessage id="Alterar usuário impersonate" />}
        position="center"
        onPressEnter={() => {}}
        toggle={toggleImpersonateModal}
        footer={
          <div className="d-flex justify-content-between">
            <Button.Cancel dark onClick={() => toggleImpersonateModal()} />
            <Button.Primary
              disabled={
                !fields.codigoContaSelecionada ||
                !fields.codigoUsuarioSelecionado
              }
              loading={loading}
              dark
              onClick={() => changeUserImpersonate()}
            >
              Trocar de usuário
            </Button.Primary>
          </div>
        }
      >
        <p>
          <FormattedMessage id="Selecione uma conta e um usuário:" />
        </p>
        <Selectbox
          className={`mb-1`}
          id="codigoContaSelecionada"
          autoComplete="off"
          placeholder={intl.formatMessage({ id: "Selecione uma conta" })}
          name="codigoContaSelecionada"
          //errorMessage={{ error: errors && errors.fields && errors.fields.codigoContaSelecionada ? errors.fields.codigoContaSelecionada : null, color: errors.color }}
          fieldDescription={null}
          onSearch={(value) => setCompanies({ ...companies, search: value })}
          value={fields.codigoContaSelecionada}
          onChange={(e) => handleFieldsSelect(e)}
          options={companies.rows.filter((item) =>
            item.label
              ?.toLocaleLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .includes(
                companies.search
                  .toLocaleLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
              )
          )}
        />
        <div className="mb-1 d-flex align-items-center">
          <Selectbox
            className={`w-100`}
            id="codigoUsuarioSelecionado"
            autoComplete="off"
            placeholder={intl.formatMessage({ id: "Selecione um usuário" })}
            name="codigoUsuarioSelecionado"
            //errorMessage={{ error: errors && errors.fields && errors.fields.codigoUsuarioSelecionado ? errors.fields.codigoUsuarioSelecionado : null, color: errors.color }}
            fieldDescription={null}
            value={fields.codigoUsuarioSelecionado}
            onSearch={(value) => setUsers({ ...users, search: value })}
            onChange={(e) => handleFieldsSelect(e)}
            disabled={!fields.codigoContaSelecionada || loadingUsers}
            options={users.rows.filter((item) =>
              item.label
                ?.toLocaleLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .includes(
                  users.search
                    .toLocaleLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                )
            )}
          />
          {loadingUsers && (
            <Spinner className="ml-50" size="sm" color="primary" />
          )}
        </div>
      </ModalWindow>
    </MainSocket>
  );
}

export default CustomHeader;
