import React from "react"
import classnames from "classnames"
import { Badge } from "reactstrap"
class Avatar extends React.Component {
  render() {
    const label = this.props.content && this.props.content.length > 2 ? this.props.content && this.props.content.split(" ") : this.props.content
    return (
      <div
      
        className={classnames(
          `avatar m-0 ${this.props.color && !this.props.style.backgroundColor ? `${this.props.color}` : ''} ${
            this.props.className ? this.props.className  : ''
          }`,
          {
            "avatar-xs": this.props.size && this.props.size === "xs",
            "avatar-sm": this.props.size && this.props.size === "sm",
            "avatar-lg": this.props.size && this.props.size === "lg",
            "avatar-xl": this.props.size && this.props.size === "xl"
          }
        )}
        style={this.props.style}
      >
        {this.props.img === false || this.props.img === undefined ? (
          <span
            className={classnames("avatar-content", {
              "position-relative": this.props.badgeUp
            })}
          >
             {this.props.icon ? (
              <div className="avatar-icon">{this.props.icon}</div>
            ) : 
            
            (label && label.length >= 2 ?
              label[0].charAt(0)+label.slice(-1)[0].charAt(0)
              :
              label && label[0] && label[0].substring(0,2))
            }
            {}
           
            {this.props.badgeUp ? (
              <Badge
                color={
                  this.props.badgeColor ? this.props.badgeColor : "primary"
                }
                className="badge-sm badge-up"
                pill
              >
                {this.props.badgeText ? this.props.badgeText : "0"}
              </Badge>
            ) : null}
          </span>
        ) : (
          <img
            src={this.props.img}
            alt="avatarImg"
            height={
              this.props.imgHeight && !this.props.size
                ? this.props.imgHeight
                : 32
            }
            width={
              this.props.imgWidth && !this.props.size ? this.props.imgWidth : 32
            }
          />
        )}
        {this.props.status ? (
          <span className={`avatar-status-${this.props.status}`}></span>
        ) : null}
      </div>
    )
  }
}
export default Avatar
